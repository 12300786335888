import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { getDecisionEngine, getClientProfile } from "../../utils/helper";

const AddFleetInner = ({ children }) => {
  let history = useHistory();
  const [clientId, setClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const [fleetName, setFleetName] = useState("");
  const [hasAccess, setHasAccess] = useState(true);
  const [validated, setValidated] = useState(false);

  const handleClientChange = (e) => {
    setClientId(e.target.value);
  };
  const handleFleetNameChange = (e) => {
    setFleetName(e.target.value);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    console.log("entering submit");
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      await onAddFleetClick();
    }
    setValidated(true);
  };

  const onRefreshClick = () => {
    fetchData();
  };

  async function fetchData() {
    setHasAccess(false);

    const clientQuery = `
    {
      clients {
        id
        client_name
      }
    }`;

    let clientId = "";
    await getDecisionEngine("hub---view-vehicle-data").then(
      async (mainResponse) => {
        if (mainResponse.outputValue === "0") {
          document.getElementById(
            "api-result"
          ).innerHTML = `<div class='red'>User is not allowed to edit/view details fleets.</div>`;
          clientId = "-1";
        } else if (mainResponse.outputValue === "1") {
          await getDecisionEngine("hub---create-a-fleet").then(
            async (mainResponse) => {
              if (mainResponse.outputValue === "0") {
                setHasAccess(false);
              } else {
                setHasAccess(true);
              }
            }
          );
          const clients = await API.graphql(graphqlOperation(clientQuery));
          setClients(clients.data.clients);
          document.getElementById(
            "api-result"
          ).innerHTML = `<div class='green'>User has full privilege to access fleets for any client.</div>`;
        } else if (mainResponse.outputValue === "2") {
          await getDecisionEngine("hub---create-a-fleet").then(
            async (mainResponse) => {
              if (mainResponse.outputValue === "0") {
                setHasAccess(false);
              } else {
                setHasAccess(true);
              }
            }
          );
          await getClientProfile().then(async (res) => {
            clientId = res.client;
            document.getElementById(
              "api-result"
            ).innerHTML = `<div class='yellow'>User has privilege to access fleets for ${clientId} client.</div>`;
          });
        } else {
          document.getElementById(
            "api-result"
          ).innerHTML = `<div class='red'>Error, No User data found.</div>`;
          clientId = "-1";
        }
      }
    );

    if (clientId === "-1") {
      return;
    }

    setClientId(clientId);
  }

  const onAddFleetClick = async () => {
    if (!hasAccess) {
      document.getElementById(
        "api-result"
      ).innerHTML = `<div class='red'>User is not allowed to create a new fleet.</div>`;
      return;
    }
    if (!fleetName) {
      document.getElementById(
        "api-result"
      ).innerHTML = `<div class='red'>Fleet Name is required.</div>`;
      return;
    }
    console.log("Sending api call:\nFleet Name: ", fleetName);

    const addFleet = `
      mutation($fleet_name: String!, $client_id: String!) {
        addFleet(fleet_name: $fleet_name, client_id: $client_id) 
        {
            id
            fleet_name
            is_active
            client_id
            created_at
            updated_at
        }
    }  
    `;
    API.graphql({
      query: addFleet,
      variables: { fleet_name: fleetName, client_id: clientId },
    }).then((e) => {
      fetchData();
      history.push(`/editFleet?fleetId=${e.data.addFleet.id}`);
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div id="api-result"></div>
        </Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Add a new Fleet:</Card.Header>
              <Card.Body>
                {clients.length > 0 && (
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="ClientId">Client</InputGroup.Text>
                    <Form.Select aria-label="" onChange={handleClientChange}>
                      <option defaultValue={true}>[Select a Client]</option>
                      {clients.map((s) => (
                        <option value={s.id} key={s.id}>
                          {s.client_name}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                )}
                <InputGroup className="mb-3">
                  <InputGroup.Text id="fleetName">Fleet Name:</InputGroup.Text>
                  <FormControl
                    required
                    aria-describedby="fleetName"
                    value={fleetName}
                    onChange={handleFleetNameChange}
                  />
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" type="submit">
                  Add New
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const AddFleet = () => (
  <Container>
    <h1>New Fleet</h1>
    <AddFleetInner />
  </Container>
);

export default AddFleet;
