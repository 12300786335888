import MapComponent from "../maps/MapComponent";
import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import {
  getAVModeFromEnum,
  roundDecimal,
  formatDateTime,
  getVehicleStatusFromEnum,
  getWorkstationStatusFromEnum,
  getVehicleTeleopStatusFromEnum
} from "../../lib/Extensions";
import {
  Button,
  Container,
  Col,
  Row,
  Card,
  ListGroup,
  ListGroupItem,
  Tab,
  Tabs,
  Table,
  Alert,
} from "react-bootstrap";
import WorkstationStatus from "../workstations/workstationStatus";
const axios = require("axios").default;

const VehicleDetailsInner = ({ children }) => {
  let history = useHistory();

  let query = new URLSearchParams(useLocation().search);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [sessionMetrices, setSessionMetrices] = useState({});
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [centerPoint, setCenterPoint] = useState({});
  const [lastLocation, setLastLocation] = useState({});
  const [rosbagJobs, setRosbagJobs] = useState([]);
  const [rosbagVideos, setRosbagVideos] = useState([]);
  const [rosbagOriginals, setRosbagOriginals] = useState([]);
  const [latestWorksationSession, setLatestWorksationSession] = useState({});
  const [workstation, setWorkstation] = useState(null);

  const vehiclesQuery = `
    {
      vehicle(id: "VEHICLE_ID") 
      {
        id,
        vin,
        registration_number,
        fleet_id,
        created_at,
        updated_at
      }
      sessionMetricesByVehicle (VehicleId: "VEHICLE_ID") {
        VehicleId
        SessionId
        EventTimestamp
        Velocity
        BatteryLevel
        Longitude
        Latitude
        AVMode
        VehicleStatus
        VehicleTeleopStatus
        LastUpdated
      }
      vehicleInfo (VehicleId: "VEHICLE_ID") {
        VehicleId
        SessionCount
        TotalDistance
        TotalDistanceInAuto
        TotalDistanceInManual
        TotalDistanceInTeleop
      }
      
      rosbagVehicleJobs (VehicleId: "VEHICLE_ID") {
        OperationId
        SessionId
        JobId
        Status
        Reason
        JobCreationTime
      }

      rosbagVehicleVideos (VehicleId: "VEHICLE_ID") {
        Uid
        JobId
        SessionId
        VideoFile
        OriginalFile
        ThumbnailFile
        ThumbnailInfo
        Duration
        ProcessingTime
      }
      latestWorkstationSessionByVehicle (vehicle_id: "VEHICLE_ID") {     
        id
        workstation_id
        vehicle_id
        start
        end
        errors_count
        registration_number
        vin
      }
      workstationForVehicle (vehicle_id: "VEHICLE_ID") {
        id
        workstation_name
        ip_address
        is_active
        updated_at
        created_at
        workstation_status
      }
    }`;

  useEffect(() => {
    fetchVehicleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchVehicleDetails();
  };

  async function fetchVehicleDetails() {
    setVehicleDetails({});
    setSessionMetrices({});
    setVehicleInfo({});
    setRosbagVideos([]);
    setRosbagJobs([]);
    setRosbagOriginals([]);
    // setCenterPoint({ lng: -1.2744001, lat: 51.7475951 });
    setLatestWorksationSession({});
    setWorkstation(null);

    const vehicleId = query.get("vehicleId");
    const apiData = await API.graphql(
      graphqlOperation(vehiclesQuery.replaceAll("VEHICLE_ID", vehicleId))
    );
    const vehicleData = apiData.data.vehicle;
    const sessionMetricesData = apiData.data.sessionMetricesByVehicle;
    const vehicleInfoData = apiData.data.vehicleInfo;
    const rosbagJobsList = apiData.data.rosbagVehicleJobs;
    const rosbagVideosList = apiData.data.rosbagVehicleVideos;
    const workstationSession = apiData.data.latestWorkstationSessionByVehicle;
    const workstationData = apiData.data.workstationForVehicle;

    rosbagJobsList.sort((a, b) => {
      if (new Date(a.JobCreationTime) > new Date(b.JobCreationTime)) {
        return -1;
      }
      return 1;
    });

    rosbagVideosList.sort((a, b) => {
      if (new Date(a.ProcessingTime) > new Date(b.ProcessingTime)) {
        return -1;
      }
      return 1;
    });
    await bindUrls(rosbagVideosList);

    const originals = rosbagJobsList.map((item) => {
      const relatedItem = rosbagVideosList.find((c) => c.JobId === item.JobId);
      return {
        JobId: item.JobId,
        CreationTime: item.JobCreationTime,
        Status: item.Status,
        RosbagFile: relatedItem ? relatedItem.OriginalFile : "",
        RosbagUrl: relatedItem ? relatedItem.OriginalUrl : "",
      };
    });
    setVehicleDetails(vehicleData);
    setRosbagJobs(rosbagJobsList);
    setRosbagVideos(rosbagVideosList);
    setRosbagOriginals(originals);
    setLatestWorksationSession(workstationSession);
    setWorkstation(workstationData);

    if (sessionMetricesData.length > 0) {
      const newSesstionMetrices = sessionMetricesData[0];
      setSessionMetrices(newSesstionMetrices);
      if (newSesstionMetrices.Longitude && newSesstionMetrices.Latitude)
        setCenterPoint({
          lng: newSesstionMetrices.Longitude,
          lat: newSesstionMetrices.Latitude,
        });
      setLastLocation({
        Longitude: newSesstionMetrices.Longitude,
        Latitude: newSesstionMetrices.Latitude,
      });
    }
    if (vehicleInfoData.length > 0) {
      setVehicleInfo(vehicleInfoData[0]);
    }
  }

  async function callGeneratePresigned(urls) {
    const url = `https://${process.env.REACT_APP_API_GATEWAY_URL}/${process.env.REACT_APP_SERVERLESS_STAGE}/rosbag/generateurl`;
    let config = {
      headers: {
        "x-api-key": process.env.REACT_APP_APIKEY,
        "Content-Type": "application/json",
      },
      statusCode: 200,
    };
    return await axios
      .post(url, { fileUrls: urls }, config)
      .then(async (res) => {
        // console.log('POST results: ', res.data)
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function bindUrls(rosbagVideos) {
    try {
      const urls = rosbagVideos
        .map((item) => [item.ThumbnailFile, item.OriginalFile, item.VideoFile])
        .flat();
      const mappedUrls = await callGeneratePresigned(urls);
      const results = rosbagVideos.map((item) => {
        item.ThumbnailUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.ThumbnailFile
        ).presignedUrl;
        item.VideoUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.VideoFile
        ).presignedUrl;
        item.OriginalUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.OriginalFile
        ).presignedUrl;
        return item;
      });
      return results;
    } catch (e) {
      document.getElementById("api-result").value = e.message;
      console.log(e);
    }
  }

  const gotToJourneys = (id) => {
    history.push("/vehicleJourneys?vehicleId=" + id);
  };

  const downloadS3file = (fileUrl) => {
    console.log("Downloading file: ", fileUrl);
    const link = document.createElement("a");
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const drawCardForWorkstation = () => {

    const onActionClick = async (action) => {
      try {
        const url = `https://${process.env.REACT_APP_API_GATEWAY_URL}/${process.env.REACT_APP_SERVERLESS_STAGE}/workstation/${workstation.id}/${action}/${vehicleDetails.id}`;
        let config = {
          headers: {
            "x-api-key": process.env.REACT_APP_APIKEY,
            "Content-Type": "application/json",
          },
          statusCode: 200,
        };
        await axios
          .post(url, {}, config)
          .then(async (res) => {
            fetchVehicleDetails();
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }
    const onConnectClick = async () => {
      await onActionClick('connect')
    }
    const onDisconnectClick = async () => {
      await onActionClick('disconnect')
    }

    const isCurrentVehicleConnected =
      latestWorksationSession !== null &&
      latestWorksationSession.end === null &&
      latestWorksationSession.vehicle_id === vehicleDetails.id;

    let statusToShow = workstation && workstation.workstation_status;
    if (!isCurrentVehicleConnected && statusToShow === 3) {statusToShow = 20}

    return (
      <Card style={{ width: "20rem" }} border="success">
        <Card.Header>
          <h5>Workstation</h5>
        </Card.Header>
        <Card.Body>
          {workstation === null ? (
            <Alert key="not-linked-workstation" variant="warning">
              Not linked to any workstation !
            </Alert>
          ) : (
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Name: <b>{workstation.workstation_name}</b>
              </ListGroupItem>
              <ListGroupItem>
                <WorkstationStatus
                  status={statusToShow}
                  withText={false}
                  size={24}
                ></WorkstationStatus>
                <span>
                  {" | "}
                  {getWorkstationStatusFromEnum(statusToShow)}
                </span>
              </ListGroupItem>
            </ListGroup>
          )}
        </Card.Body>
        {workstation === null ? (
          ""
        ) : (
          <Card.Footer>
            {!isCurrentVehicleConnected && (
            <Button
              disabled={workstation.workstation_status === 1 ? "" : "disabled"}
              variant="primary"
              onClick={() => onConnectClick()}
            >
              Connect
            </Button>
            )}
            {isCurrentVehicleConnected && (
              <Button variant="info"
                disabled={workstation.workstation_status !== 2 ? "" : "disabled"}
                onClick={() => onDisconnectClick()}
              >
                Disconnect
              </Button>
            )}
          </Card.Footer>
        )}
      </Card>
    );
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <h1>Vehicle Details</h1>
      <br />
      <Row>
        <Col>
          <Card style={{ width: "20rem" }} border="primary">
            <Card.Img variant="top" src="/car-type1.jpeg" width="150" />
            <Card.Body>
              <Card.Title>
                VIN: <b>{vehicleDetails.vin}</b>
              </Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Registration# <b>{vehicleDetails.registration_number}</b>
              </ListGroupItem>
              <ListGroupItem>
                Status:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.VehicleStatus >= 0
                    ? getVehicleStatusFromEnum(sessionMetrices.VehicleStatus)
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                AV Mode:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.AVMode >= 0
                    ? getAVModeFromEnum(sessionMetrices.AVMode)
                    : "N/A"}
                </b>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <br />
          {drawCardForWorkstation()}
        </Col>
        <Col>
          <Card style={{ width: "20rem" }} border="info">
            <Card.Header>
              <h5>Current Metrices</h5>
            </Card.Header>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Battery Level:{" "}
                <b>
                  {sessionMetrices.BatteryLevel
                    ? `${sessionMetrices.BatteryLevel}%`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Current Speed:{" "}
                <b>
                  {sessionMetrices.Velocity
                    ? `${roundDecimal(sessionMetrices.Velocity, 2)} Km/h`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                TeleOp Status:{" "}
                <b>
                  {sessionMetrices.VehicleTeleopStatus >=0
                    ? `${getVehicleTeleopStatusFromEnum(sessionMetrices.VehicleTeleopStatus)}`
                    : "N/A"}
                </b>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <br />
          <Card style={{ width: "20rem" }} border="info">
            <Card.Header>
              <h5>Current Location</h5>
            </Card.Header>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Longitude:{" "}
                <b>
                  {sessionMetrices.Longitude
                    ? `${sessionMetrices.Longitude}`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Latitude:{" "}
                <b>
                  {sessionMetrices.Latitude
                    ? `${sessionMetrices.Latitude}`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Last seen:{" "}
                <b>
                  {sessionMetrices.EventTimestamp
                    ? `${formatDateTime(sessionMetrices.LastUpdated)}`
                    : "N/A"}
                </b>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <br />
          <Card style={{ width: "20rem" }} border="info">
            <Card.Header>
              <h5>
                Journeys (
                <b>
                  {vehicleInfo && vehicleInfo.SessionCount
                    ? vehicleInfo.SessionCount
                    : 0}
                </b>
                )
              </h5>
            </Card.Header>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Total Distance:{" "}
                <b>
                  {vehicleInfo && vehicleInfo.TotalDistance
                    ? `${roundDecimal(vehicleInfo.TotalDistance, 2)} Km`
                    : "0 Km"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Total Distance (Manual Mode):{" "}
                <b>
                  {vehicleInfo && vehicleInfo.TotalDistanceInManual
                    ? `${roundDecimal(vehicleInfo.TotalDistanceInManual, 2)} Km`
                    : "0 Km"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Total Distance (Auto Mode):{" "}
                <b>
                  {vehicleInfo && vehicleInfo.TotalDistanceInAuto
                    ? `${roundDecimal(vehicleInfo.TotalDistanceInAuto, 2)} Km`
                    : "0 Km"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Total Distance (Teleop Mode):{" "}
                <b>
                  {vehicleInfo && vehicleInfo.TotalDistanceInTeleop
                    ? `${roundDecimal(vehicleInfo.TotalDistanceInTeleop, 2)} Km`
                    : "0 Km"}
                </b>
              </ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Button
                variant="success"
                onClick={() => gotToJourneys(vehicleDetails.id)}
              >
                Go to Journeys
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <MapComponent
            centerPoint={centerPoint}
            mainPlaces={[sessionMetrices]}
            mainPoints={[
              {
                coords: lastLocation,
                info: sessionMetrices,
                vehicleInfo: vehicleDetails,
              },
            ]}
            vehicle={vehicleDetails}
            locations={[]}
          ></MapComponent>
        </Col>
      </Row>
      <br />
      <Row>
        <Tabs
          defaultActiveKey="operations"
          id="journey-subdata"
          className="mb-3"
        >
          <Tab eventKey="operations" title="Operations">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Creation Time</th>
                  <th>Operation Id</th>
                  <th>Job Id</th>
                  <th>Status</th>
                  <th>Reason</th>
                  {/* <th style={{ 'minWidth': '185px' }}>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {rosbagJobs.map((rosbagJob, index) => {
                  return (
                    <tr key={"rosbagJob-" + rosbagJob.OperationId}>
                      <td>{formatDateTime(rosbagJob.JobCreationTime)}</td>
                      <td>{rosbagJob.OperationId}</td>
                      <td>{rosbagJob.JobId}</td>
                      <td>{rosbagJob.Status}</td>
                      <td>{rosbagJob.Reason}</td>
                      {/* <td>
                        <Button
                          variant="info"
                          onClick={() =>
                            gotToJourneyDetails(session.id, session.vehicle_id)
                          }
                        >
                          Details
                        </Button>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="originals" title="Rosbags (Original)">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Creation Time</th>
                  <th>Job Id</th>
                  <th>Rosbag File</th>
                  <th>Status</th>
                  <th style={{ minWidth: "185px" }}>Download</th>
                </tr>
              </thead>
              <tbody>
                {rosbagOriginals.map((rosbagOriginal, index) => {
                  return (
                    <tr key={"rosbagOriginal-" + rosbagOriginal.JobId}>
                      <td>{formatDateTime(rosbagOriginal.CreationTime)}</td>
                      <td>{rosbagOriginal.JobId}</td>
                      <td>{rosbagOriginal.RosbagFile}</td>
                      <td>{rosbagOriginal.Status}</td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() =>
                            downloadS3file(rosbagOriginal.RosbagUrl)
                          }
                          disabled={!rosbagOriginal.RosbagUrl}
                        >
                          Rosbag
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="videos" title="Videos">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Processing Time</th>
                  <th>Thumnbail</th>
                  {/* <th>Thumnbail Info</th> */}
                  {/* <th>Video File</th>
                  <th>Original File</th> */}
                  <th>Job Id</th>
                  <th>Duration</th>
                  <th style={{ minWidth: "185px" }}>Download</th>
                </tr>
              </thead>
              <tbody>
                {rosbagVideos.map((rosbagVideo, index) => {
                  return (
                    <tr key={"rosbagVideo-" + rosbagVideo.Uid}>
                      <td>{formatDateTime(rosbagVideo.ProcessingTime)}</td>
                      {/* <td>{rosbagVideo.ThumbnailFile}</td> */}
                      <td>
                        <img
                          src={rosbagVideo.ThumbnailUrl}
                          alt={rosbagVideo.ThumbnailFile}
                          width={150}
                        ></img>
                      </td>
                      {/* <td>{rosbagVideo.ThumbnailInfo}</td> */}
                      {/* <td>{rosbagVideo.VideoFile}</td>
                      <td>{rosbagVideo.OriginalFile}</td> */}
                      <td>{rosbagVideo.JobId}</td>
                      <td>{rosbagVideo.Duration}</td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() => downloadS3file(rosbagVideo.VideoUrl)}
                        >
                          Video
                        </Button>
                        <span> </span>
                        <Button
                          variant="info"
                          onClick={() =>
                            downloadS3file(rosbagVideo.OriginalUrl)
                          }
                        >
                          Original
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};
const VehicleDetails = () => (
  <Container>
    <VehicleDetailsInner />
  </Container>
);

export default VehicleDetails;
