import React from "react";
import { getWorkstationStatusEnum, getWorkstationStatusFromEnum } from "../../lib/Extensions";
import WorkstationStatusIcon from "./workstationStatusIcon";

const WorkstationStatus = (props) => {
  const workstationStatus = props.status;
  const size = props.size ?? 48;

  let currentStatus = getWorkstationStatusEnum(workstationStatus);
  let currentStatusDesc = getWorkstationStatusFromEnum(workstationStatus);

  if (currentStatus === 'N/A') {
    currentStatus = "LOADING"
    currentStatusDesc = "Loading..."
  }

  if (!props.withText) {
    return (
      <WorkstationStatusIcon
        status={currentStatus}
        size={size}
      ></WorkstationStatusIcon>
    );
  }
  let color;
  switch (currentStatus.toLocaleUpperCase()) {
    case "OFFLINE":
      color = "grey";
      break;
    case "AVAILABLE":
      color = "green";
      break;
    case "NOT_AVAILABLE":
      color = "#FF5959";
      break;
    case "CONNECTED":
      color = "#8946A6";
      break;
    case "DISABLED":
      color = "grey";
      break;

    case "ENDED":
      color = "grey";
      break;
    case "STARTED":
      color = "green";
      break;
    case "BUSY":
      color = "crimson";
      break;

    default:
      color= "grey";
      break;
  }
  
  return (
    <>
      <div
        style={{
          color: color,
          fontWeight: "bold",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <WorkstationStatusIcon
          status={currentStatus}
          size={size}
          withText={props.withText}
        ></WorkstationStatusIcon>{" "}
        {currentStatusDesc}
      </div>
    </>
  );
};

export default WorkstationStatus;
