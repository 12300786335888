const axios = require('axios').default;
export function getBlaizeSession() {
  const cname = 'blaize_session'
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export const getClientProfile = async () => {
  const url = `${process.env.REACT_APP_ZEPHR_URL}/blaize/profile`;
  return await axios.get(
    url,
    {},
    {
      headers: {
        "cookie": `blaize_session=${getBlaizeSession()}`,
        'Content-Type': 'application/json',
      }
    }
  )
    .then(async res => {
      return res.data
    })
    .catch(e => {
      console.log(e)
      return {
        "error": e.message
      }
    })
}
export const getDecisionEngine = async (sdkSlug) => {
  const blaizeSession = getBlaizeSession();
  const url = `${process.env.REACT_APP_ZEPHR_URL}/zephr/decision-engine`;
  return await axios.post(
    url,
    {
      "sdkFeatureSlug": sdkSlug, //"hub---startend-journey",
      "session": blaizeSession
    },
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
    .then(async res => {
      return res.data
    })
    .catch(e => {
      if ((document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1")) {
        return {
          "sdkFeatureSlug": "hub---startend-journey",
          "outputType": "ENUM",
          "outputValue": "1"
        }
      }
      console.log(e.message)
      return {
        "error": e.message,
      }
    })
}
