import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Container,
  Row,
  Col,
  Alert
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { formatDateTime, getWorkstationStatusEnum } from "../../lib/Extensions";
import axios from "@aws-amplify/storage/node_modules/axios";
import { API } from "aws-amplify";
import { getDecisionEngine } from '../../utils/helper'
import WorkstationStatusIcon from "./workstationStatusIcon"

const WorkstationsInner = ({ children }) => {

  let history = useHistory();
  const [workstations, setWorkstationsValues] = useState([]);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewClick = () => {
    history.push('/addWorkstation');
  };

  const onRefreshClick = () => {
    fetchData();
  };

  async function fetchData() {
    setWorkstationsValues([]);
    let hasAccess = false
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access the list of workstations.</div>`
      } else if (mainResponse.outputValue === '1') {
        hasAccess = true
        setHasAccessToEdit(hasAccess)
      } else if (mainResponse.outputValue === '2') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access the list of workstations.</div>`
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        
      }
    });

    if (!hasAccess) {
      return
    }

    const workstationsQuery = `
    {
        workstations {
            hubWorkstationData
            data {      
                id
                workstation_name
                ip_address
                is_active
                updated_at
                created_at
                nb_vehicles
                nb_fleets
                workstation_status
                workstation_error
            }  
        }
    }`;

    const axiosOptions = {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`,
      },
      withCredentials: (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") ? false : true
    }

    const apiData = await axios.post(`${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`, { query: workstationsQuery, variables: {} }, axiosOptions);
    const workstations = apiData.data.data.workstations.data;
    if (workstations === 'none') {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to view workstations List.</div>`
      return;
    }
    setWorkstationsValues(workstations);
  }

  const getAlertMessage = () => {
    const getVariant = () => {
      if (hasAccessToEdit === null) return "secondary";
      if (hasAccessToEdit === true) return "success";
      return "danger";
    };
    return (
      <Alert key="alert-message" variant={getVariant()}>
        {hasAccessToEdit === null && <>Checking permissions...</>}
        {hasAccessToEdit !== null && (
          <>
            This user {hasAccessToEdit ? "have" : "don't have"} access to view\edit worksations
          </>
        )}
      </Alert>
    );
  };
  const gotToDetails = (id) => {
    history.push("/workstationDetails?workstationId=" + id);
  };

  const goToEdit = (id) => {
    history.push("/editWorkstation?workstationId=" + id);
  };

  const disableWorkstation = async (workstation_id, name) => {
  
    if (window.confirm('Are you sure you want to disable this workstation ?\n' + name)) {

      console.log('disable workstation...');

      const disableWorkstation = `
        mutation {
            disableWorkstation(workstation_id: "${workstation_id}") {
                id
                workstation_name
                ip_address
                is_active
          }
        }
      `;

      API.graphql({ query: disableWorkstation })
        .then((e) => {
          //console.log(e);
          onRefreshClick();
        });

    }
  };

  return (
    <>
      <Row>
        <Col>{getAlertMessage()}</Col>
        <Col></Col>
        <Col md="auto"></Col>
        {hasAccessToEdit && <Col xs lg="2">
          <Button variant="primary" onClick={onAddNewClick}>
            Add New
          </Button>
          {' '}
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col> }
      </Row>
      <br />
      {hasAccessToEdit && (<Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Workstation Id</th>
            <th>Name</th>
            <th>IP Address</th>
            <th>No. of Fleets</th>
            <th>No. of Vehicles</th>
            <th>Status</th>
            <th>Created At</th>
            <th style={{ 'minWidth': '260px', 'maxWidth': '280px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {workstations.map((workstation, index) => {
            return (
              <tr key={"workstation-" + workstation.id} style={{ textDecoration: workstation.is_active ? "" : "line-through" }}>
                <td>{workstation.id}</td>
                <td>{workstation.workstation_name}</td>
                <td>{workstation.ip_address}</td>
                <td align="center">{workstation.nb_fleets}</td>
                <td align="center">{workstation.nb_vehicles}</td>
                <td align="center" valign="middle" >
                  <WorkstationStatusIcon
                    status={getWorkstationStatusEnum(workstation.workstation_status)}
                    size="24"
                    ></WorkstationStatusIcon>
                  </td>
                <td>{formatDateTime(workstation.created_at)}</td>
                <td >
                  <Button
                    variant="info"
                    onClick={() => gotToDetails(workstation.id)}
                    style={{ marginRight: '4px' }}
                  >
                    Details
                  </Button>
                  <Button
                    variant="success"
                    disabled={workstation.is_active ? '' : 'disabled'}
                    onClick={() => goToEdit(workstation.id)}
                    style={{ marginRight: '4px' }}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="danger"
                    disabled={(workstation.is_active && workstation.workstation_status !== 3) ? '' : 'disabled'}
                    onClick={() => disableWorkstation(workstation.id, workstation.workstation_name)}
                  >
                    Disable
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>)}
    </>
  );
};

const Workstations = () => (
  <Container>
    <h1>All Workstations</h1>
    <WorkstationsInner />
  </Container>
);

export default Workstations;
