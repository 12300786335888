import React from 'react';


import '../home.css';

const Home = () => (
    <div>
        <br/>
        <h1>Welcome to StreetDrone Cloud Console !</h1>
        <br/>
        <h4 style={{color: 'crimson'}}>This is the testing environment [{process.env.REACT_APP_ENVNAME}]</h4>
        <br/>
        <br/>
        <div data-elementor-type="footer" data-elementor-id="7069" className="elementor elementor-7069 elementor-location-footer" data-elementor-settings="[]">
            <div className="elementor-section-wrap">
                <section className="elementor-section elementor-top-section elementor-element elementor-element-1c91f759 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1c91f759" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4a17f40b" data-id="4a17f40b" data-element_type="column">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-fdbb687 elementor-widget elementor-widget-image" data-id="fdbb687" data-element_type="widget" data-widget_type="image.default">
                                    <div className="elementor-widget-container">
                                        <img width="400" src="Pix-E_side_transparent-900x477-1.png" className="attachment-full size-full" alt=""/>															
                                        </div>
                                </div>
                                <div className="elementor-element elementor-element-52b3f87b elementor-widget elementor-widget-theme-site-logo elementor-widget-image" data-id="52b3f87b" data-element_type="widget" data-widget_type="theme-site-logo.default">
                                    <div className="elementor-widget-container">
                                        <a href="//www.streetdrone.com">
                                            <img width="400" src="/SDR016-SD-delivering-autonomy-white-green-300.png" className="attachment-full size-full" alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5226f482" data-id="5226f482" data-element_type="column">

                        </div>
                    </div>
                </section>
            </div>
        </div>
        <p>Copyright © 2021 <a href="http://www.streetdrone.com" style={{color: '#78be20'}} target="_blank" rel="noreferrer">StreetDrone</a>, Inc</p>
    </div>
);

export default Home;
