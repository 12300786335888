import MapComponent from "../maps/MapComponent";
import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import {
  getDuration,
  formatDateTime,
  roundDecimal,
  getAVModeFromEnum,
  getVehicleStatusFromEnum,
  getVehicleTeleopStatusFromEnum,
} from "../../lib/Extensions";
import {
  Button,
  Container,
  Col,
  Row,
  Card,
  Tab,
  Tabs,
  Table,
  ListGroup,
  ListGroupItem,
  InputGroup,
  FormControl
} from "react-bootstrap";

const axios = require("axios").default;

const JourneyDetailsInner = ({ children }) => {
  //#region Definitions

  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);

  const [journeyDetails, setJourneyDetails] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [sessionMetrices, setSessionMetrices] = useState({});
  const [allLocations, setAllLocations] = useState([]);
  const [errorsCount, setErrorsCount] = useState([]);
  const [centerPoint, setCenterPoint] = useState({});
  const [lastLocation, setLastLocation] = useState({});
  const [rosbagJobs, setRosbagJobs] = useState([]);
  const [rosbagVideos, setRosbagVideos] = useState([]);
  const [rosbagOriginals, setRosbagOriginals] = useState([]);
  const [rosbagLidars, setRosbagLidars] = useState([]);
  const [rosbagRadars, setRosbagRadars] = useState([]);
  const [coordinateMarginTimeUser, setCoordinateMarginTimeUser] = useState(query.get("refreshMap") ? query.get("refreshMap") : 10);

  const [duration, setDuration] = useState(null);
  const [journeyStatus, setJourneyStatus] = useState("");
  const [LastTimestamp, setLastTimestamp] = useState({LastTimestamp : new Date().toISOString() });
  const onCoordinateMarginTimeUser = (event) => { setCoordinateMarginTimeUser(event.target.value)}
  const onSubmitCoordinateMarginTime = () => { 
    window.location.href = replaceQueryParam('refreshMap', coordinateMarginTimeUser, window.location.href);// url.searchParams.set('refreshMap', coordinateMarginTimeUser);
  }

  function replaceQueryParam(param, newval, search) {
    var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
    var query = search.replace(regex, "$1").replace(/&$/, '');

    return (query.length > 2 ? query + "&" : "?") + (newval ? param + "=" + newval : '');
}
  
  let socket 
  //#endregion

  //#region UseEffect

  useEffect(() => {
    fetchJourneyDetails(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  //#region Actions

  const onRefreshClick = () => {
    clearInterval(window.refreshIntervalId);
    fetchJourneyDetails(false);
  };

  const gotToErrors = (sessionId, vehicleId) => {
    history.push(
      `/journeyErrors?journeyId=${sessionId}&vehicleId=${vehicleId}`
    );
  };

  const downloadS3file = (fileUrl) => {
    console.log("Downloading file: ", fileUrl);
    const link = document.createElement("a");
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //#endregion

  //#region Queries

  const appsyncQuery = `
    {
        session (id: "SESSIONIDVALUE") 
        {
            id,
            vehicle_id,
            run_config_id,
            start,
            end,
            errors_count
        }
        vehicle (id: "VEHICLEID") {
          id
          fleet_id
          vin
          registration_number
        }
        
        telemetryLocationLatitude : sessionTelemetryByLocation (SessionId: "SESSIONIDVALUE", GPSMeaureUnit : "GPSIMU_PosLatitude_D") {
          MeasureValue
          EventTimestamp
        }
        telemetryLocationLongitude : sessionTelemetryByLocation (SessionId: "SESSIONIDVALUE", GPSMeaureUnit : "GPSIMU_PosLongitude_D") {
          MeasureValue
          EventTimestamp
        }

        rosbagJobs (SessionId: "SESSIONIDVALUE") {
          OperationId
          SessionId
          JobId
          Status
          Reason
          JobCreationTime
        }

        rosbagVideos (SessionId: "SESSIONIDVALUE") {
          Uid
          JobId
          SessionId
          VideoFile
          OriginalFile
          ThumbnailFile
          ThumbnailInfo
          Duration
          ProcessingTime
          }

          rosbagLidars (SessionId: "SESSIONIDVALUE") {
            Uid
            JobId
            SessionId
            LidarFile
            OriginalFile
            Duration
            ProcessingTime
          }

          rosbagRadars (SessionId: "SESSIONIDVALUE") {
            Uid
            JobId
            SessionId
            RadarFile
            OriginalFile
            Duration
            ProcessingTime
          }
    }
  `;

  const sessionMerticesQuery = `
    {
      sessionMetricesBySessionIds (SessionIds: ["SESSIONIDVALUE"]) {
        hubJourneyData
        data {
          VehicleId
          SessionId
          EventTimestamp
          Velocity
          BatteryLevel
          Longitude
          Latitude
          AVMode
          VehicleStatus
          WeatherCondition
          RoadCondition
          IsDay
          Visibility
          Temperature
          LastUpdated
          TotalDistance
          TotalDistanceInAuto
          TotalDistanceInManual
          TotalDistanceInTeleop
          AverageSpeed
          VehicleTeleopStatus
        }
      }
    }
  `;

  async function fetchJourneyDetails(initSocket) {
    setJourneyDetails({});
    setVehicleDetails({});
    setSessionMetrices({});
    setRosbagJobs([]);
    setRosbagVideos([]);
    setRosbagOriginals([]);
    setErrorsCount("");
    setCenterPoint({ lng: -1.2744001, lat: 51.7475951 });

    const journeyId = query.get("journeyId");
    const vehicleId = query.get("vehicleId");
    const queryText = appsyncQuery
      .replaceAll("SESSIONIDVALUE", journeyId)
      .replace("VEHICLEID", vehicleId);
    //console.log(queryText);

    const apiData = await API.graphql(graphqlOperation(queryText));
    const axiosOptions = {
      headers: {
        "x-api-key": `${process.env.REACT_APP_APIKEY}`,
      },
      withCredentials:
        document.location.hostname === "localhost" ||
        document.location.hostname === "127.0.0.1"
          ? false
          : true,
    };
    const apiData2 = await axios.post(
      `${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`,
      {
        query: sessionMerticesQuery.replace("SESSIONIDVALUE", journeyId),
        variables: {},
      },
      axiosOptions
    );
    //console.log(apiData);
    const sessionData = apiData.data.session;
    const vehicleData = apiData.data.vehicle;
    let sessionMetricesData =
      apiData2.data.data.sessionMetricesBySessionIds.data;
    if (sessionMetricesData.sessionMetricesBySessionIds) {
      sessionMetricesData = sessionMetricesData.sessionMetricesBySessionIds;
    }

    const errorsCountValue = sessionData.errors_count;
    const telemetryLatitudes = apiData.data.telemetryLocationLatitude;
    const telemetryLongitudes = apiData.data.telemetryLocationLongitude;
    const locationsList = mapLocationData(
      telemetryLatitudes,
      telemetryLongitudes
    );

    // console.log('Found location data: ', telemetryLatitudes, '\n', telemetryLongitudes);
    // console.log('Found locations: ', locationsList);

    const rosbagJobsList = apiData.data.rosbagJobs;
    const rosbagVideosList = apiData.data.rosbagVideos;
    const rosbagLidarList = apiData.data.rosbagLidars;
    const rosbagRadarList = apiData.data.rosbagRadars;

    rosbagJobsList.sort((a, b) => {
      if (new Date(a.JobCreationTime) > new Date(b.JobCreationTime)) {
        return -1;
      }
      return 1;
    });

    rosbagVideosList.sort((a, b) => {
      if (new Date(a.ProcessingTime) > new Date(b.ProcessingTime)) {
        return -1;
      }
      return 1;
    });
    await bindUrls(rosbagVideosList);
    await bindLidarUrls(rosbagLidarList);
    await bindRadarUrls(rosbagRadarList);

    const originals = rosbagJobsList.map((item) => {
      const relatedItem = rosbagVideosList.find((c) => c.JobId === item.JobId);
      return {
        JobId: item.JobId,
        CreationTime: item.JobCreationTime,
        Status: item.Status,
        RosbagFile: relatedItem ? relatedItem.OriginalFile : "",
        RosbagUrl: relatedItem ? relatedItem.OriginalUrl : "",
      };
    });

    setErrorsCount(errorsCountValue);
    setJourneyDetails(sessionData);
    setVehicleDetails(vehicleData);
    setRosbagJobs(rosbagJobsList);
    setRosbagVideos(rosbagVideosList);
    setRosbagOriginals(originals);
    setRosbagLidars(rosbagLidarList);
    setRosbagRadars(rosbagRadarList);

    // Duration
    setDuration(getDuration(sessionData.start, sessionData.end));

    if (!sessionData.end) {
      const temp = setInterval(updateDuration, 1000, sessionData.start);
      window.refreshIntervalId = temp;
      setJourneyStatus("active");
    } else {
      clearInterval(window.refreshIntervalId);
      clearInterval(updateDuration);
      setJourneyStatus("done");
    }

    let tenantId = "unknown";
    if (telemetryLatitudes.length > 0) {
      tenantId = telemetryLatitudes[0].TenantId;
    }
    if (sessionMetricesData.length > 0) {
      const newSesstionMetrices = sessionMetricesData[0];
      setSessionMetrices(newSesstionMetrices);
      if (newSesstionMetrices.Longitude && newSesstionMetrices.Latitude) {
        setCenterPoint({
          lng: newSesstionMetrices.Longitude,
          lat: newSesstionMetrices.Latitude,
        });
        setLastLocation({
          Longitude: newSesstionMetrices.Longitude,
          Latitude: newSesstionMetrices.Latitude,
          EventTimestamp: newSesstionMetrices.EventTimestamp
        });
      }
    }

    setAllLocations(locationsList);
    if(initSocket)
    setupWebsocket(journeyId, vehicleId, tenantId);
  }

  function setupWebsocket(sessionId, vehicleId, tenantId) {
    if (socket) {
      socket.close();
    }
    const websocketEndPoint = `${process.env.REACT_APP_WEBSOCKET_HOST}/${process.env.REACT_APP_SERVERLESS_STAGE}`;
    socket = new WebSocket(
      `wss://${websocketEndPoint}?session=${sessionId}&tenant=${tenantId}&vehicle=${vehicleId}`
    );
    socket.addEventListener('message', function (message) { socketOnMessageEventHandler(message); return })
    //console.log(socket);
  }

  let latestEventTimestamp;
  let latestAggregateTimestamp;
  let lastCoordinateTimestamp;
  // let coordinateMarginTime = 10;
  let i = 0
  const socketOnMessageEventHandler = (message) => {
     //console.log(`New message for channel: ${vehicleId} | ${new Date().toISOString()}`);
     const incomingData = JSON.parse(message.data);
     //console.log('INCOMING > ', incomingData);
    
     let tempMetrices = {};

     if (incomingData.Type === "endsession") {
       console.log("End session received...");
       onRefreshClick();
       return;
     }

     if (incomingData.Type === "startsession") {
       console.log("Start session received...");
       return;
     }

     if (incomingData.Type === "errors") {
       console.log("Errors count updated: " + JSON.stringify(incomingData));
       setErrorsCount(incomingData.Content.errors_count);
       return;
     }
     
     if (incomingData.Type === "telemetry"){
       console.log(i++)
     }
     let coordinatesRecord = { Longitude : '', Latitude: '', EventTimestamp: '' }
     for (let record of incomingData.Content) {
       //console.log('New record: ', record);

       if (incomingData.Type === "telemetry") {
         if (!record.EventTimestamp) continue;
         else { 
           coordinatesRecord.EventTimestamp= record.EventTimestamp;
           setLastTimestamp({EventTimestamp : record.EventTimestamp})
          }
         if (
           latestEventTimestamp &&
           record.EventTimestamp < latestEventTimestamp
         )
           continue;

         if (record.MeasureUnit === "XCU_HighVoltageSoc_Pc") {
            tempMetrices.BatteryLevel = record.MeasureValue;
          } else if (record.MeasureUnit === "XCU_Velocity_Ms") {
            tempMetrices.Velocity = record.MeasureValue;
          } else if (record.MeasureUnit === 'GPSIMU_PosLongitude_D') {
            tempMetrices.Longitude = record.MeasureValue;
            coordinatesRecord.Longitude = record.MeasureValue;
            coordinatesRecord.EventTimestamp = record.EventTimestamp
          } else if (record.MeasureUnit === 'GPSIMU_PosLatitude_D') {
            tempMetrices.Latitude = record.MeasureValue;
            coordinatesRecord.Latitude = record.MeasureValue;
            coordinatesRecord.EventTimestamp = record.EventTimestamp
         } else if (record.MeasureUnit === "ARB_Vehicle_Mode_Zs") {
            tempMetrices.AVMode = record.MeasureValue;
         } else if (record.MeasureUnit === "ARB_Vehicle_Status_Zs") {
            tempMetrices.VehicleStatus = record.MeasureValue;
         } else if (record.MeasureUnit === "ARB_TeleopConnStatus_Zs") {
            tempMetrices.VehicleTeleopStatus = parseInt(record.MeasureValue);
         } else if (record.MeasureUnit === "HUB_WeatherCondition_S") {
            tempMetrices.WeatherCondition = record.MeasureValue;
         } else if (record.MeasureUnit === "HUB_RoadCondition_S") {
            tempMetrices.RoadCondition = record.MeasureValue;
         } else if (record.MeasureUnit === "HUB_IsDay_B") {
            tempMetrices.IsDay = !!record.MeasureValue;
         } else if (record.MeasureUnit === "HUB_Visibility_S") {
            tempMetrices.Visibility = record.MeasureValue;
         } else if (record.MeasureUnit === "HUB_Temperature_Z") {
            tempMetrices.Temperature = parseInt(record.MeasureValue);
         }


         if (record.EventTimestamp) {
           latestEventTimestamp = record.EventTimestamp;
         }
       } else if (incomingData.Type === "aggregate") {
         if (!record.LastTimestamp) continue;
         if (
           latestAggregateTimestamp &&
           record.LastTimestamp < latestAggregateTimestamp
         )
           continue;

         if (
           record.AggregateField === "AverageSpeed" &&
           record.AggregateValue
         ) {
           tempMetrices.AverageSpeed = record.AggregateValue;
           latestAggregateTimestamp = record.LastTimestamp;
         }
       } 
     }
     const lastCoordinatesTimeDIff = lastCoordinateTimestamp ? (Date.parse(coordinatesRecord.EventTimestamp) - Date.parse(lastCoordinateTimestamp)) / 1000 : coordinateMarginTimeUser ; 
     if(coordinatesRecord.Longitude && coordinatesRecord.Latitude && lastCoordinatesTimeDIff >= coordinateMarginTimeUser ) {
       lastCoordinateTimestamp = coordinatesRecord.EventTimestamp
      setLastLocation({
        Longitude: coordinatesRecord.Longitude,
        Latitude: coordinatesRecord.Latitude,
        EventTimestamp: coordinatesRecord.EventTimestamp,
      });
      setAllLocations((oldLocations) => {
          return [...oldLocations, coordinatesRecord];
      });
     }

     console.log(tempMetrices, ', ', Object.keys(tempMetrices).length);
     if (Object.keys(tempMetrices).length > 0) {
       setSessionMetrices((oldValue) => {
         const newValue = { ...oldValue, ...tempMetrices };
         //console.log('NEW VALUE: ', newValue);
         return newValue;
       });
     } else {
       //console.log('No updates !');
     }
  }
  
  function mapLocationData(latitudes, longitudes) {
    let locations = [];
    let newLocations = []
    let previousTimestamp = ''
    longitudes.forEach((telemetry) => {
      let locationItem = {
        Longitude: telemetry.MeasureValue,
        EventTimestamp: telemetry.EventTimestamp,
      };
      
      const alternativeRecord = latitudes.find(
        (tt) => tt.EventTimestamp === telemetry.EventTimestamp
      );
      if (alternativeRecord)
        locationItem.Latitude = alternativeRecord.MeasureValue;
      // const lastCoordinatesTimeDIff = previousTimestamp ? (Date.parse(telemetry.EventTimestamp) - Date.parse(previousTimestamp)) / 1000 : coordinateMarginTimeUser ; 
      // if (lastCoordinatesTimeDIff >= coordinateMarginTimeUser){
        locations.push(locationItem);
      // }
     
    });
    locations.sort((a, b) => {
      if (new Date(a.EventTimestamp) < new Date(b.EventTimestamp)) {
        return -1;
      }
      return 1;
    });
    locations.forEach(location => {
      if( !previousTimestamp || ((Date.parse(location.EventTimestamp) - Date.parse(previousTimestamp)) / 1000) > coordinateMarginTimeUser ) {
        previousTimestamp = location.EventTimestamp
        newLocations.push(location)
      }
      
    })
    return newLocations;
  }

  //#endregion

  //#region Url Generators
  async function bindUrls(rosbagVideos) {
    try {
      if(rosbagVideos.length === 0) return
      const urls = rosbagVideos
        .map((item) => [item.ThumbnailFile, item.OriginalFile, item.VideoFile])
        .flat();
      const mappedUrls = await callGeneratePresigned(urls);
      const results = rosbagVideos.map((item) => {
        item.ThumbnailUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.ThumbnailFile
        ).presignedUrl;
        item.VideoUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.VideoFile
        ).presignedUrl;
        item.OriginalUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.OriginalFile
        ).presignedUrl;
        return item;
      });
      return results;
    } catch (e) {
      document.getElementById("api-result").value = e.message;
      console.log(e);
    }
  }

  async function bindLidarUrls(rosbagLidar) {
    try {
      if(rosbagLidar.length === 0) return
      const urls = rosbagLidar.map((item) => [item.OriginalFile]).flat();
      const mappedUrls = await callGeneratePresigned(urls);
      const results = rosbagLidar.map((item) => {
        item.OriginalUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.OriginalFile
        ).presignedUrl;
        return item;
      });
      return results;
    } catch (e) {
      document.getElementById("api-result").value = e.message;
      console.log(e);
    }
  }

  async function bindRadarUrls(rosbagRadar) {
    try {
      if(rosbagRadar.length === 0) return
      const urls = rosbagRadar.map((item) => [item.OriginalFile]).flat();
      const mappedUrls = await callGeneratePresigned(urls);
      const results = rosbagRadar.map((item) => {
        item.OriginalUrl = mappedUrls.presignedUrls.find(
          (t) => t.fileUrl === item.OriginalFile
        ).presignedUrl;
        return item;
      });
      return results;
    } catch (e) {
      document.getElementById("api-result").value = e.message;
      console.log(e);
    }
  }

  async function callGeneratePresigned(urls) {
    const url = `https://${process.env.REACT_APP_API_GATEWAY_URL}/${process.env.REACT_APP_SERVERLESS_STAGE}/rosbag/generateurl`;
    let config = {
      headers: {
        "x-api-key": process.env.REACT_APP_APIKEY,
        "Content-Type": "application/json",
      },
      statusCode: 200,
    };
    return await axios
      .post(url, { fileUrls: urls }, config)
      .then(async (res) => {
        // console.log('POST results: ', res.data)
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  //#endregion

  //#region UI Helpers

  const getJourneyStatus = () => {
    if (journeyStatus === "active") {
      return (
        <>
          <div style={{ color: "red" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="red"
              viewBox="0 0 16 16"
            >
              <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            </svg>{" "}
            LIVE
          </div>
        </>
      );
    } else if (journeyStatus === "done") {
      return (
        <>
          <div style={{ color: "green" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="green"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
            </svg>{" "}
            COMPLETED
          </div>
        </>
      );
    }
  };

  const drawDisabled = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="gray"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
      </svg>
    );
  };

  function updateDuration(startTime) {
    //console.log(window.refreshIntervalId);
    setDuration(getDuration(startTime, new Date()));
  }
  //#endregion

  return (
    <>
      <Row>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <h1>Journey Details</h1>
      <br />
      <Col lg={3} style={{ display: "flex",'max-height':'40px', 'margin' : '20px' }} >
        <InputGroup className="mb-3"  >
          <InputGroup.Text id="mapRefreshRate">Frequency (s/marker)</InputGroup.Text>
          <FormControl style={{'width':'30px','marign':'10px'}}
            aria-describedby="freq"
            length={2}
            value={coordinateMarginTimeUser}
            onChange={onCoordinateMarginTimeUser}
          />
        </InputGroup>
        <Button  onClick={onSubmitCoordinateMarginTime} >Change</Button>
      </Col>
      <Row>
        <Col lg={4} style={{ display: "flex" }}>
          <MapComponent
            centerPoint={centerPoint}
            mainPoints={[
              {
                coords: lastLocation,
                vehicleInfo: vehicleDetails,
                info: lastLocation
              },
            ]}
            vehicle={vehicleDetails}
            locations={allLocations}
            
          ></MapComponent>
          
        </Col>
        <Col lg={3}>
          <Card>
            <Card.Body>
              <Card.Title>
                VIN: <b>{vehicleDetails.vin}</b>
              </Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Registration# <b>{vehicleDetails.registration_number}</b>
              </ListGroupItem>
              <ListGroupItem>
                Start Time: <b>{formatDateTime(journeyDetails.start)}</b>
              </ListGroupItem>
              <ListGroupItem>
                End Time: <b>{formatDateTime(journeyDetails.end)}</b>
              </ListGroupItem>
              <ListGroupItem>
                Duration: <b>{duration}</b>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <h5>Distance Travelled</h5>
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Total Distance:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.TotalDistance
                    ? sessionMetrices.TotalDistance === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(sessionMetrices.TotalDistance, 2)} Km`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Total Distance (Manual Mode):{" "}
                <b>
                  {sessionMetrices && sessionMetrices.TotalDistanceInManual
                    ? sessionMetrices.TotalDistanceInManual === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(
                          sessionMetrices.TotalDistanceInManual,
                          2
                        )} Km`
                    : "0 Km"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Total Distance (Auto Mode):{" "}
                <b>
                  {sessionMetrices && sessionMetrices.TotalDistanceInAuto
                    ? sessionMetrices.TotalDistanceInAuto === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(
                          sessionMetrices.TotalDistanceInAuto,
                          2
                        )} Km`
                    : "0 Km"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Total Distance (Teleop Mode):{" "}
                <b>
                  {sessionMetrices && sessionMetrices.TotalDistanceInTeleop
                    ? sessionMetrices.TotalDistanceInTeleop === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(
                          sessionMetrices.TotalDistanceInTeleop,
                          2
                        )} Km`
                    : "0 Km"}
                </b>
              </ListGroupItem>
            </ListGroup>
          </Card>

      </Col>
      <Col lg={2}>
          <Card>
            <Card.Body>
              <Card.Title>
                <h5>Weather Condition</h5>
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                Weather:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.WeatherCondition
                    ? sessionMetrices.WeatherCondition === "restricted"
                      ? drawDisabled()
                      : sessionMetrices.WeatherCondition
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Road:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.RoadCondition
                    ? sessionMetrices.RoadCondition === "restricted" ? drawDisabled() : sessionMetrices.RoadCondition 
                    : 'N/A'  }
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Is Day:{" "}
                <b>
                  {sessionMetrices && typeof sessionMetrices.IsDay === "boolean"
                    ? sessionMetrices.IsDay === "restricted"
                      ? drawDisabled()
                      : sessionMetrices.IsDay ? 'Day' : 'Night'
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Visibility:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.Visibility
                    ? sessionMetrices.Visibility === "restricted"
                      ? drawDisabled()
                      : sessionMetrices.Visibility
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Temperature:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.Temperature
                    ? sessionMetrices.Temperature === "restricted"
                      ? drawDisabled()
                      : sessionMetrices.Temperature
                    : "N/A"}
                </b>
              </ListGroupItem>     
            </ListGroup>
          </Card>
        </Col>
        <Col lg={3}>
          <Card>
            <Card.Body>
              <Card.Title>{getJourneyStatus()}</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                AV Mode:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.AVMode >= 0
                    ? sessionMetrices.AVMode === "restricted"
                      ? drawDisabled()
                      : getAVModeFromEnum(sessionMetrices.AVMode)
                    : ""}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Status:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.VehicleStatus >= 0
                    ? sessionMetrices.VehicleStatus === "restricted"
                      ? drawDisabled()
                      : getVehicleStatusFromEnum(sessionMetrices.VehicleStatus)
                    : ""}
                </b>
              </ListGroupItem>

              <ListGroupItem>
                Battery Level:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.BatteryLevel
                    ? sessionMetrices.BatteryLevel === "restricted"
                      ? drawDisabled()
                      : `${sessionMetrices.BatteryLevel} %`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Current Speed:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.Velocity
                    ? sessionMetrices.Velocity === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(sessionMetrices.Velocity, 3)} Km/h`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Average Speed:{" "}
                <b>
                  {sessionMetrices && sessionMetrices.AverageSpeed
                    ? sessionMetrices.AverageSpeed === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(sessionMetrices.AverageSpeed, 3)} Km/h`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                TeleOp Status:{" "}
                <b>
                  {sessionMetrices.VehicleTeleopStatus >= 0
                    ? `${getVehicleTeleopStatusFromEnum(
                        sessionMetrices.VehicleTeleopStatus
                      )}`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Location:{" "}
                <b>
                  {sessionMetrices && lastLocation.Longitude
                    ? lastLocation.Longitude === "restricted"
                      ? drawDisabled()
                      : `Long: ${lastLocation.Longitude}, Lat: ${lastLocation.Latitude}`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Errors: <b>{errorsCount}</b>
              </ListGroupItem>
            </ListGroup>
            <Card.Body>
              <Button
                variant="danger"
                disabled={errorsCount && (errorsCount > 0) ? "" : "disabled"}
                onClick={() =>
                  gotToErrors(journeyDetails.id, journeyDetails.vehicle_id)
                }
              >
                View Errors
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Tabs
          defaultActiveKey="operations"
          id="journey-subdata"
          className="mb-3"
        >
          <Tab eventKey="operations" title="Operations">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Creation Time</th>
                  <th>Operation Id</th>
                  <th>Job Id</th>
                  <th>Status</th>
                  <th>Reason</th>
                  {/* <th style={{ 'minWidth': '185px' }}>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {rosbagJobs.map((rosbagJob, index) => {
                  return (
                    <tr key={"rosbagJob-" + rosbagJob.OperationId}>
                      <td>{formatDateTime(rosbagJob.JobCreationTime)}</td>
                      <td>{rosbagJob.OperationId}</td>
                      <td>{rosbagJob.JobId}</td>
                      <td>{rosbagJob.Status}</td>
                      <td>{rosbagJob.Reason}</td>
                      {/* <td>
                        <Button
                          variant="info"
                          onClick={() =>
                            gotToJourneyDetails(session.id, session.vehicle_id)
                          }
                        >
                          Details
                        </Button>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="originals" title="Rosbags (Original)">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Creation Time</th>
                  <th>Job Id</th>
                  <th>Rosbag File</th>
                  <th>Status</th>
                  <th style={{ minWidth: "185px" }}>Download</th>
                </tr>
              </thead>
              <tbody>
                {rosbagOriginals.map((rosbagOriginal, index) => {
                  return (
                    <tr key={"rosbagOriginal-" + rosbagOriginal.JobId}>
                      <td>{formatDateTime(rosbagOriginal.CreationTime)}</td>
                      <td>{rosbagOriginal.JobId}</td>
                      <td>{rosbagOriginal.RosbagFile}</td>
                      <td>{rosbagOriginal.Status}</td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() =>
                            downloadS3file(rosbagOriginal.RosbagUrl)
                          }
                          disabled={!rosbagOriginal.RosbagUrl}
                        >
                          Rosbag
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="videos" title="Videos">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Processing Time</th>
                  <th>Thumnbail</th>
                  {/* <th>Thumnbail Info</th> */}
                  {/* <th>Video File</th>
                  <th>Original File</th> */}
                  <th>Job Id</th>
                  <th>Duration</th>
                  <th style={{ minWidth: "185px" }}>Download</th>
                </tr>
              </thead>
              <tbody>
                {rosbagVideos.map((rosbagVideo, index) => {
                  return (
                    <tr key={"rosbagVideo-" + rosbagVideo.Uid}>
                      <td>{formatDateTime(rosbagVideo.ProcessingTime)}</td>
                      {/* <td>{rosbagVideo.ThumbnailFile}</td> */}
                      <td>
                        <img
                          src={rosbagVideo.ThumbnailUrl}
                          alt={rosbagVideo.ThumbnailFile}
                          width={150}
                        ></img>
                      </td>
                      {/* <td>{rosbagVideo.ThumbnailInfo}</td> */}
                      {/* <td>{rosbagVideo.VideoFile}</td>
                      <td>{rosbagVideo.OriginalFile}</td> */}
                      <td>{rosbagVideo.JobId}</td>
                      <td>{rosbagVideo.Duration}</td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() => downloadS3file(rosbagVideo.VideoUrl)}
                        >
                          Video
                        </Button>
                        <span> </span>
                        <Button
                          variant="info"
                          onClick={() =>
                            downloadS3file(rosbagVideo.OriginalUrl)
                          }
                        >
                          Original
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="radar" title="Radar">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Processing Time</th>
                  <th>Job Id</th>
                  <th>Processed Radar File</th>
                  <th>Duration</th>
                  <th style={{ minWidth: "185px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rosbagRadars.map((rosbagRadar, index) => {
                  return (
                    <tr key={"rosbagVideo-" + rosbagRadar.Uid}>
                      <td>{formatDateTime(rosbagRadar.ProcessingTime)}</td>
                      <td>{rosbagRadar.JobId}</td>
                      <td>{rosbagRadar.RadarFile}</td>
                      <td>{rosbagRadar.Duration}</td>
                      <td>
                        <Button
                          variant="info"
                          disabled
                          onClick={() => downloadS3file(rosbagRadar.RadarFile)}
                        >
                          Radar
                        </Button>
                        <span> </span>
                        <Button
                          variant="info"
                          onClick={() =>
                            downloadS3file(rosbagRadar.OriginalUrl)
                          }
                        >
                          Original
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="lidar" title="LiDAR">
            <Table striped bordered hover>
              <thead>
                <tr key="header">
                  <th>Processing Time</th>
                  <th>Job Id</th>
                  <th>Processed Lidar File</th>
                  <th>Duration</th>
                  <th style={{ minWidth: "185px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rosbagLidars.map((rosbagLidar, index) => {
                  return (
                    <tr key={"rosbagVideo-" + rosbagLidar.Uid}>
                      <td>{formatDateTime(rosbagLidar.ProcessingTime)}</td>
                      <td>{rosbagLidar.JobId}</td>
                      <td>{rosbagLidar.LidarFile}</td>
                      <td>{rosbagLidar.Duration}</td>
                      <td>
                        <Button
                          disabled
                          variant="info"
                          onClick={() => downloadS3file(rosbagLidar.LidarFile)}
                        >
                          Lidar
                        </Button>
                        <span> </span>
                        <Button
                          variant="info"
                          onClick={() =>
                            downloadS3file(rosbagLidar.OriginalUrl)
                          }
                        >
                          Original
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

const JourneyDetails = () => (
  <Container>
    <JourneyDetailsInner />
  </Container>
);

export default JourneyDetails;
