import React from "react";
import Home from './components/Home';
import Vehicles from './components/vehicles/Vehicles';
import Navigation from './components/Navigation';
import Testing from './components/testing/Testing';
import AllJourneys from './components/journeys/AllJourneys';
import VehicleDetails from './components/vehicles/VehicleDetails';
import EditVehicle from './components/vehicles/EditVehicle';
import JourneyDetails from './components/journeys/JourneyDetails';
import JourneyErrors from './components/journeys/JourneyErrors';
import VehicleJourneys from './components/vehicles/VehicleJourneys';
import RunConfigs from './components/configs/RunConfigs';
import AddVehicle from './components/configs/AddVehicle';
import StartSession from './components/configs/StartSession';
import HardwareCollections from './components/configs/HardwareCollections';
import SoftwareCollections from './components/configs/SoftwareCollections';
import Fleets from './components/fleets/AllFleets';
import FleetDetails from './components/fleets/FleetDetails';
import EditFleet from './components/fleets/EditFleet';
import AddFleet from './components/fleets/AddFleet';
import AddWorkstation from './components/workstations/AddWorkstation';
import EditWorkstation from './components/workstations/EditWorkstation';
import Workstations from './components/workstations/AllWorkstations';
import WorkstationDetails from './components/workstations/WorkstationDetails';

import Container from 'react-bootstrap/Container';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';
import './Custom.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

export default class App extends React.Component {
  
  render() {
    return (
    <Container className="p-3">
      <Router>
        <Navigation />
        <br />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/vehicles">
            <Vehicles />
          </Route>
          <Route path="/journeys">
            <AllJourneys />
          </Route>
          <Route path="/vehicleJourneys">
            <VehicleJourneys  />
          </Route>
          <Route path="/journeyDetails">
            <JourneyDetails />
          </Route>
          <Route path="/vehicleDetails">
            <VehicleDetails />
          </Route>
          <Route path="/journeyErrors">
            <JourneyErrors />
          </Route>
          <Route path="/Testing">
            <Testing />
          </Route>
          <Route exact path="/hardware">
            <HardwareCollections />
          </Route>
          <Route exact path="/software">
            <SoftwareCollections />
          </Route>
          <Route exact path="/runconfigs">
            <RunConfigs />
          </Route>
          <Route exact path="/addvehicle">
            <AddVehicle />
          </Route>
          <Route exact path="/startSession">
            <StartSession />
          </Route>
          <Route exact path="/fleets">
            <Fleets />
          </Route>
          <Route exact path="/fleetDetails">
            <FleetDetails />
          </Route>
          <Route exact path="/editFleet">
            <EditFleet />
          </Route>
          <Route exact path="/editVehicle">
            <EditVehicle />
          </Route>
          <Route exact path="/addFleet">
            <AddFleet />
          </Route>
          <Route exact path="/addWorkstation">
            <AddWorkstation />
          </Route>
          <Route exact path="/editWorkstation">
            <EditWorkstation />
          </Route>
          <Route exact path="/workstations">
            <Workstations />
          </Route>
          <Route exact path="/WorkstationDetails">
            <WorkstationDetails />
          </Route> 
        </Switch>
      </Router>
  </Container >
    );
  }
}