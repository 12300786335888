import React, { useState, useEffect } from "react";
import {
  Alert,
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { getDecisionEngine } from "../../utils/helper";

const AddWorkstationInner = ({ children }) => {
  let history = useHistory();

  const [validated, setValidated] = useState(false);
  const [workstationName, setWorkstationName] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [hasAccessToEdit, setHasAccessToEdit] = useState(null);

  const handleIPAddressChange = (e) => {
    setIpAddress(e.target.value);
  };
  const handleWorkstationNameChange = (e) => {
    setWorkstationName(e.target.value);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    console.log("entering submit");
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      await onAddWorkstationClick();
    }
    setValidated(true);
  };

  const onRefreshClick = () => {
    fetchData();
  };

  async function fetchData() {
    setIpAddress("");
    setWorkstationName("");

    let access = await getDecisionEngine("hub---create-a-workstation").then(
      async (mainResponse) => {
        if (mainResponse.outputValue === "0") {
          return false;
        }
        return true;
      }
    );
    setHasAccessToEdit(access);
    //if (!access) return
  }

  const getAlertMessage = () => {
    const getVariant = () => {
      if (hasAccessToEdit === null) return "secondary";
      if (hasAccessToEdit === true) return "success";
      return "danger";
    };
    return (
      <Alert key="alert-message" variant={getVariant()}>
        {hasAccessToEdit === null && <>Checking permissions...</>}
        {hasAccessToEdit !== null && (
          <>
            This user {hasAccessToEdit ? "have" : "don't have"} access to create
            a workspace
          </>
        )}
      </Alert>
    );
  };
  const onAddWorkstationClick = async () => {
    const addWorkstation = `
        mutation($workstation_name: String!, $ip_address: String!) {
          addWorkstation(workstation_name: $workstation_name, ip_address: $ip_address) {
            id
            workstation_name
            ip_address
            is_active
            updated_at
            created_at
          }
        }
      `;

    API.graphql({
      query: addWorkstation,
      variables: { workstation_name: workstationName, ip_address: ipAddress },
    }).then((e) => {
      console.log(e);
      history.push(`/editWorkstation?workstationId=${e.data.addWorkstation.id}`);
    });
  };

  return (
    <>
      <Row>
        <Col>{getAlertMessage()}</Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Add a new Workstation:</Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="workstationName">
                    Workstation Name:
                  </InputGroup.Text>
                  <FormControl
                    required
                    aria-describedby="workstationName"
                    onChange={handleWorkstationNameChange}
                    value={workstationName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Workstation Name is a required field.
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="ipAddress">IP Address:</InputGroup.Text>
                  <FormControl
                    required
                    pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$"
                    aria-describedby="ipAddress"
                    onChange={handleIPAddressChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid IP Address.
                  </Form.Control.Feedback>
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col md="10"></Col>
                  <Col md="2" style={{textAlign: 'right'}}>
                    <Button variant="primary" type="submit">
                      Add New
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const AddWorkstation = () => (
  <Container>
    <h1>New Workstation</h1>
    <AddWorkstationInner />
  </Container>
);

export default AddWorkstation;
