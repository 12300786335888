import React, { useState } from 'react';

import GoogleMap from 'google-map-react';
import { OverlayTrigger, Popover, CloseButton, Dropdown } from 'react-bootstrap';
import { formatDateTime } from '../../lib/Extensions'

const MapComponentInner = (props) => {

    const [mapState, setMapState] = useState({});
    const [mapsState, setMapsState] = useState({});
    const [showPopover, setShowPopover] = useState(false);
    const [popoverLatittude, setPopoverLatittude] = useState('');
    const [popoverLongitude, setPopoverLongitude] = useState('');
    const [popoverLeft, setPopoverLeft] = useState('');
    const [popoverTop, setPopoverTop] = useState('');
    const [popoverTimestamp, setPopoverTimestamp] = useState(''); 
    
    let newLocations;
    if (props.locations) {
        newLocations = [...props.locations];
        newLocations.pop();
        /// console.log(i)
    }

    const centerPoint = props.centerPoint && props.centerPoint.lng ? props.centerPoint : { lat: 51.747640, lng: -1.272408 };

    const VehicleMarker = (props1) => {

        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">{props1.title}</Popover.Header>
                <Popover.Body>
                    <div>Time: {props1.eventTime}</div>
                    <div>Coord: {props1.lng}, {props1.lat}</div>
                </Popover.Body>
            </Popover>
        );

        return (
            <>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={popover}
                >
                    <div>
                        <img src='/car.png' width='32px' style={{ 'margin': '-16px 0px -0px -16px' }} alt='' />
                    </div>
                </OverlayTrigger>
            </>
        );
    }

   

    const PointMarker = (props1) => {

        // const popover = (
        //     <Popover id="popover-basic">
        //         {/* <Popover.Header as="h3">{props1.title}</Popover.Header> */}
        //         <Popover.Body>
        //             <div>Time: {props1.eventTime}</div>
        //             <div>Coord: {props1.lng}, {props1.lat}</div>
        //         </Popover.Body>
        //     </Popover>
        // );

        return (
            <>
                <div
                    style={{ 'cursor': 'pointer' }}
                    onClick={ (e) => { 
                        setPopoverLatittude(props1.lat); 
                        setPopoverLongitude(props1.lng);
                        setPopoverTimestamp(props1.eventTime); 
                        setPopoverLeft(e.pageX)
                        setPopoverTop(e.pageY)
                        setShowPopover(true)
                        }
                    }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkorange" viewBox="0 0 16 16" style={{ 'margin': '-16px 0px -0px -16px' }}>
                        <path d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                    </svg>
                </div>
            </>
        );
    }

    const renderMainPoint = (props2) => {
        if (props2.lng && props2.lat)
            return <VehicleMarker
                key={props2.id}
                lng={props2.lng}
                lat={props2.lat}
                title={props2.title}
                eventTime={props2.eventTime}
            />;
        return null;
    }
    const renderHistoryPoint = (props2) => {
        if (props2.lng && props2.lat)
            return <PointMarker
                key={props2.id}
                lng={props2.lng}
                lat={props2.lat}
                eventTime={props2.eventTime}
            />;
        return null;
    }

    const handleApiLoaded = (map, maps) => {

        // Example: https://stackoverflow.com/a/58786210
        setMapState(map);

        const trafficLayer = new maps.TrafficLayer();
        trafficLayer.setMap(map)
     
        setMapsState(maps);
    };

    const getMapOptions = (maps) => {

        return {
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_CENTER,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID,
                    maps.MapTypeId.TERRAIN
                ]
            },
        };
    }

    function drawLines(locations) {

        //console.log('locations:\n:',locations, '\nmap:', mapState,'\nmaps:', mapsState);

        if (!mapState) return;
        if (!mapsState) return;
        if (!locations || locations.length === 0) return;

        const map = mapState;
        const maps = mapsState;

        try {
            var path = new maps.MVCArray();

            //Set the Path Stroke Color
            var poly = new maps.Polyline({ map: map, strokeColor: 'green' });

            //console.log('locations:\n', locations);
            let lat_lng = locations.map(l => { return new maps.LatLng(l.Latitude, l.Longitude); });
            //console.log('lat longs :\n', lat_lng);

            //Loop and Draw Path Route between the Points on MAP
            for (var i = 0; i < lat_lng.length; i++) {
                if ((i + 1) < lat_lng.length) {
                    var src = lat_lng[i];
                    var des = lat_lng[i + 1];
                    path.push(src);
                    path.push(des);
                    poly.setPath(path);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    
    
    return (
        <>
        { showPopover && 
                <Popover style={{'left': popoverLeft, 'top': popoverTop, 'display': 'inline', 'position': 'absolute'}}>
                    {/* <header >
                        
                    </header> */}
                    
                    <CloseButton style={{'float':'right', 'padding': '10px'}} onClick={() => setShowPopover(false)} />
                    <Popover.Body>
                        <div>Time: {popoverTimestamp}</div>
                        <div>Coord: {popoverLatittude}, {popoverLongitude}</div>
                    </Popover.Body>
                </Popover>
                }
            <GoogleMap
                bootstrapURLKeys={{ key: 'AIzaSyBrSkUS41MvgruGJavkIgDw_nipLE2nGN8' }}
                center={centerPoint}
                defaultZoom={16}
                options={getMapOptions}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
                
        
                {
                    (newLocations && (newLocations.length > 0)) ? (newLocations.map(p =>
                        renderHistoryPoint(
                            {
                                id: ('history-' + p.EventTimestamp),
                                lng: p.Longitude,
                                lat: p.Latitude,
                                eventTime: formatDateTime(p.EventTimestamp)
                            }
                        )
                    )) : ''}
                {
                    (props.mainPoints && props.mainPoints.length > 0) ? (
                        props.mainPoints.map(mapPoint => {
                            if (!mapPoint.vehicleInfo) return null;
                            return renderMainPoint(
                                {
                                    id: (mapPoint.vehicleInfo.id + '-' + mapPoint.info.EventTimestamp),
                                    lng: mapPoint.coords.Longitude,
                                    lat: mapPoint.coords.Latitude,
                                    vin: mapPoint.vehicleInfo.vin,
                                    title: mapPoint.vehicleInfo.registration_number,
                                    eventTime: formatDateTime(mapPoint.info.EventTimestamp)
                                }
                            )
                        })
                    ) : ''
                }
                {props.locations ? drawLines(props.locations) : ''}

            </GoogleMap>
        </>
    );
};

const MapComponent = (props) => (
    <MapComponentInner {...props}>
    </MapComponentInner>
);

export default MapComponent;
