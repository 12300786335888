/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

let awsmobile;

// Alpha
awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",

    "aws_appsync_graphqlEndpoint": `${process.env.REACT_APP_APPSYNC_ENDPOINT}`,
    "aws_appsync_apiKey": `${process.env.REACT_APP_APIKEY}`,
    "aws_access_key_id": "AKIASNWA3BF547C6J6EY",
    "aws_secret_access_key": "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"
};

export default awsmobile;