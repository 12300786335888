import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { Table, Stack, Spinner, Alert, Button, Container, Col, Row } from "react-bootstrap";
import { formatDateTime } from "../../lib/Extensions";

const JourneyErrorsInner = ({ children }) => {
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);

  const [sessionId, setSessionId] = useState(null);
  const [nextToken, setNextToken] = useState('');
  const [journeyErrors, setJourneyErrors] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [totalErrors, setTotalErrors] = useState(0);

  useEffect(() => {
    fetchJourneyErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchJourneyErrors();
  };

  async function fetchJourneyErrors() {
    setJourneyErrors([]);
    //setVehicleDetails({});

    const journeyId = query.get("journeyId");
    const vehicleId = query.get("vehicleId");

    setSessionId(journeyId);

    const queryS = `
    {
        telemetryErrors (SessionId: "${journeyId}", NextToken: null) 
        {
          items {
            Uid
            VehicleId
            MessageId
            SessionId
            Description
            TelemetryUid
            ErrorCode
            ErrorDetails
            ProcessedAt
          }
          nextToken
          scannedCount
        }
        
        session (id: "${journeyId}") 
        {
          errors_count
        }
        vehicle (id: "${vehicleId}") {
          id
          fleet_id
          vin
          registration_number
        }
    }`;

    const apiData = await API.graphql(graphqlOperation(queryS));
    //console.log(apiData);

    setTotalErrors(apiData.data.session.errors_count);
    setNextToken(apiData.data.telemetryErrors.nextToken)
    setJourneyErrors(apiData.data.telemetryErrors.items);
    setIsLoadingMore(false);
  }

  const loadMoreErrors = async () => {
    setIsLoadingMore(true);

    const queryS = `
    {
        telemetryErrors (SessionId: "${sessionId}", NextToken: "${nextToken}") 
        {
          items {
            Uid
            VehicleId
            MessageId
            SessionId
            Description
            TelemetryUid
            ErrorCode
            ErrorDetails
            ProcessedAt
          }
          nextToken
          scannedCount
        }
    }`;

    const apiData = await API.graphql(graphqlOperation(queryS));

    setNextToken(apiData.data.telemetryErrors.nextToken)
    const errorsData = apiData.data.telemetryErrors.items;
    console.log(errorsData);
    
    setJourneyErrors((oldErrors)=> {
      return [...oldErrors, ...errorsData]
    });
    setIsLoadingMore(false);
  };

  const gotToJourneyDetails = () => {
    const journeyId = query.get("journeyId");
    const vehicleId = query.get("vehicleId");
    history.push(
      `/journeyDetails?journeyId=${journeyId}&vehicleId=${vehicleId}`
    );
  };

  return (
    <>
      <Row>
        <Col>
        <h3>{`Journey Errors (${journeyErrors.length} OF ${totalErrors})`}</h3>
        </Col>
        <Col md="auto"></Col>
        <Col xs lg="2" style={{textAlign: 'right'}}>
          <Button variant="info" onClick={() => gotToJourneyDetails()}>
            Back
          </Button>
          <span> </span>
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Log Time</th>
            <th>Error Code</th>
            <th>Error Message</th>
            <th>Error Id</th>
          </tr>
        </thead>
        <tbody>
          {journeyErrors.map((record, index) => (
            <tr key={"journeyError-" + record.Uid}>
              <td>{formatDateTime(record.ProcessedAt)}</td>
              <td>{record.ErrorCode}</td>
              <td>{record.Description}</td>
              <td>{JSON.parse(JSON.parse(record.ErrorDetails)).errorId}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      
      <Stack gap={8} className="col-md-5 mx-auto">
        <div style={{textAlign: 'center'}}>{isLoadingMore && <Spinner animation="grow" variant="success" />}</div>
        {!isLoadingMore && nextToken && <Button onClick={()=> loadMoreErrors()} variant='outline-success' size="sm">Load More ...</Button>}
        {!isLoadingMore && !nextToken && <Alert variant='warning' style={{textAlign: 'center'}}>{`All errors has been loaded (${journeyErrors.length} OF ${totalErrors})`}</Alert>}
      </Stack>
    </>
  );
};
const JourneyErrors = () => (
  <Container>
    <JourneyErrorsInner />
  </Container>
);

export default JourneyErrors;
