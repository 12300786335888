import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import {
  formatDate,
  getVehicleStatusFromEnum,
  getVehicleTeleopStatusFromEnum,
  roundDecimal,
} from "../../lib/Extensions";
import {
  Button,
  Container,
  Col,
  Row,
  Card,
  ListGroup,
  Placeholder,
  Badge,
  ListGroupItem,
  Table,
  Alert,
} from "react-bootstrap";
import MapComponent from "../maps/MapComponent";
import { getDecisionEngine, getClientProfile } from "../../utils/helper";

const FleetDetailsInner = ({ children }) => {
  //#region Definitions

  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);

  //const [webSockets, setWebSockets] = useState(null);
  const [fleetDetails, setFleetDetails] = useState([]);
  const [activeSessions, setActiveSessions] = useState([]);
  const [sharedClients, setSharedClients] = useState([]);
  const [fleetVehicles, setFleetVehicles] = useState([]);
  const [sessionMetrices, setSessionMetrices] = useState([]);
  const [sessionsLocations, setSessionsLocations] = useState([]);
  const [accessMessage, setAccessMessage] = useState("Checking permissions...");
  const [accessMessageVariant, setAccessMessageVariant] = useState("secondary");

  //const [updateMap, setUpdateMap] = useState(0);

  //#endregion

  //#region Actions
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);

  useEffect(() => {
    fetchDetails(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchDetails(false);
  };

  const gotToDetails = (vehicleId) => {
    window.open(`/vehicleDetails?vehicleId=${vehicleId}`, "_blank");
  };

  const gotToJourneys = (vehicleId) => {
    window.open(`/vehicleJourneys?vehicleId=${vehicleId}`, "_blank");
  };

  const onBackClick = () => {
    history.push("/fleets");
  };

  //#endregion

  //#region Queries

  async function fetchDetails(withSockets) {
    setFleetDetails({});
    setFleetVehicles([]);

    let access = await getDecisionEngine("hub---create-a-fleet").then(
      async (mainResponse) => {
        if (mainResponse.outputValue === "0") {
          return false;
        }
        return true;
      }
    );
    setHasAccessToEdit(access);
    if (!access) return;
    const clientQuery = `
    {
      clients {
        id
        client_name
      }
    }`;
    let tempClientId = "";
    await getDecisionEngine("hub---view-vehicle-data").then(
      async (mainResponse) => {
        if (mainResponse.outputValue === "0") {
          setAccessMessage("User is not allowed to edit/view details fleets");
          setAccessMessageVariant('danger');
          tempClientId = "-1";
        } else if (mainResponse.outputValue === "1") {
          const clients = await API.graphql(graphqlOperation(clientQuery));
          setClients(clients.data.clients);
          setAccessMessage(
            "User has full privilege to access fleets for any client."
          );
          setAccessMessageVariant('success');
        } else if (mainResponse.outputValue === "2") {
          await getClientProfile().then(async (res) => {
            tempClientId = res.client;
            setClient(tempClientId);
          setAccessMessageVariant('success');
          setAccessMessage(
              `User has privilege to access fleets for ${tempClientId} client`
            );
          });
        } else {
          setAccessMessageVariant('danger');
          setAccessMessage("Error >> No User data found !");
          tempClientId = "-1";
        }
      }
    );

    if (tempClientId === "-1") {
      return;
    }

    const fleetId = query.get("fleetId");

    const fleetQuery = `
    {
      fleet(id: "${fleetId}") 
      {
        id
        fleet_name
        client_id
        created_at
        updated_at
        is_active
        nb_vehicles
        active_vehicles
      }
      fleetActiveSessions(fleetId: "${fleetId}") {
        id
        vehicle_id
        run_config_id
        start
        end
      }
    }`;

    const apiData = await API.graphql(graphqlOperation(fleetQuery));
    const fleetData = apiData.data.fleet;
    const activeSessionsData = apiData.data.fleetActiveSessions;
    setActiveSessions(activeSessionsData);

    const mainQuery = `
    {
      fleetVehicles (fleetId: "${fleetId}") {
        id
        vin
        registration_number
        client_id
        fleet_id
        created_at
        updated_at
      }
    }`;

    const vehicleApiData = await API.graphql(graphqlOperation(mainQuery));
    const vehiclesData = vehicleApiData.data.fleetVehicles;

    setFleetVehicles(vehiclesData);
    await fetchSessionMetrices(vehiclesData);

    setFleetDetails(fleetData);

    await fetchSharedClients(fleetId, fleetData.client_id);

    if (withSockets) {
      setupWebsocket(
        fleetId,
        vehiclesData.map((v) => v.id),
        fleetData.client_id
      );
    }
  }

  async function fetchSessionMetrices(vehiclesData) {
    const vehicleIds = vehiclesData.map((v) => v.id);
    if (vehicleIds.length === 0) return;
    let query = "";
    for (let vehicleId of vehicleIds) {
      query += `sessionMetrcies_${vehicleId.replaceAll(
        "-",
        "_"
      )} : sessionMetricesByVehicle (VehicleId: "${vehicleId}") {
          VehicleId
          SessionId
          EventTimestamp
          Velocity
          BatteryLevel
          Longitude
          Latitude
          AVMode
          VehicleStatus
          VehicleTeleopStatus
          AverageSpeed
          LastUpdated
          TotalDistance
          TotalDistanceInAuto
          TotalDistanceInManual
          TotalDistanceInTeleop
      }
    `;
    }
    const response = await API.graphql(graphqlOperation(`{ ${query} }`));
    const allSessionMetrices = response.data;

    let newMetrices = [];
    for (const property in allSessionMetrices) {
      const tempMetrices = allSessionMetrices[property][0];
      if (tempMetrices && tempMetrices.VehicleId) {
        newMetrices.push(tempMetrices);
      }
    }
    setSessionMetrices(newMetrices);

    await fetchSessionsCoordinates(newMetrices.map((x) => x.SessionId));
  }

  async function fetchSessionsCoordinates(sessionIds) {
    let query = "";
    for (let sessionId of sessionIds) {
      query += `   
        telemetryLatitudes_${sessionId.replaceAll(
          "-",
          "_"
        )} : sessionTelemetryByMeasureUnit (SessionId: "${sessionId}", MeasureUnit: "GPSIMU_PosLatitude_D") {
          Uid
          VehicleId
          MeasureUnit
          MeasureValue
          SessionId
          TenantId
          PayloadId
          ProcessedAt
          EventTimestamp
        }
        telemetryLongitudes_${sessionId.replaceAll(
          "-",
          "_"
        )} : sessionTelemetryByMeasureUnit (SessionId: "${sessionId}", MeasureUnit: "GPSIMU_PosLongitude_D") {
          Uid
          VehicleId
          MeasureUnit
          MeasureValue
          SessionId
          TenantId
          PayloadId
          ProcessedAt
          EventTimestamp
        }`;
    }
    if (!query) return;
    const response = await API.graphql(graphqlOperation(`{ ${query} }`));
    const allSessionCoordinates = response.data;

    let allSessionsLocations = [];
    for (let sessionId of sessionIds) {
      const telemetryLatitudes =
        allSessionCoordinates[
          `telemetryLatitudes_${sessionId.replaceAll("-", "_")}`
        ];
      const telemetryLongitudes =
        allSessionCoordinates[
          `telemetryLongitudes_${sessionId.replaceAll("-", "_")}`
        ];
      const locationsList = mapLocationData(
        telemetryLatitudes,
        telemetryLongitudes
      );
      allSessionsLocations.push({ sessionId, locationsList });
    }
    setSessionsLocations(allSessionsLocations);
  }

  async function fetchSharedClients(fleetId, clientId) {
    const sharedFleetsQuery = `
      {
        sharedFleetsByFleet(FleetId : "${fleetId}", OwnedClientId : "${clientId}") {
          id
          fleet_id
          owner_client_id
          shared_client_id
          share_date
          client_name
        }
      }`;
    const response = await API.graphql(graphqlOperation(sharedFleetsQuery));
    setSharedClients(response.data.sharedFleetsByFleet);
  }

  function mapLocationData(latitudes, longitudes) {
    let locations = [];
    longitudes.forEach((telemetry) => {
      let locationItem = {
        Longitude: telemetry.MeasureValue,
        EventTimestamp: telemetry.EventTimestamp,
      };
      const alternativeRecord = latitudes.find(
        (tt) => tt.EventTimestamp === telemetry.EventTimestamp
      );
      if (alternativeRecord)
        locationItem.Latitude = alternativeRecord.MeasureValue;
      locations.push(locationItem);
    });
    locations.sort((a, b) => {
      if (new Date(a.EventTimestamp) < new Date(b.EventTimestamp)) {
        return -1;
      }
      return 1;
    });
    return locations;
  }

  //#endregion

  //#region Websocket

  function setupWebsocket(fleetId, vehicleIds, clientId) {
    if (!vehicleIds || vehicleIds.length === 0) return;
    //let newSockets = [];
    for (let vehicleId of vehicleIds) {
      const websocketEndPoint = `${process.env.REACT_APP_WEBSOCKET_HOST}/${process.env.REACT_APP_SERVERLESS_STAGE}`;
      const socket = new WebSocket(
        `wss://${websocketEndPoint}?vehicle=${vehicleId}&fleet=${fleetId}&tenant=${clientId}`
      );

      socket.onmessage = (message) => {
        //console.log(`New message for channel: ${vehicleId} | ${new Date().toISOString()}`);

        const incomingData = JSON.parse(message.data);
        if (incomingData === null) return;
        //console.log('INCOMING > ', incomingData);

        let tempMetrices = {};

        if (incomingData.Type === "startsession") {
          //console.log("Start session received...\n", incomingData);
          onRefreshClick();
          //fetchDetails();
          return;
        }

        if (incomingData.Type === "endsession") {
          //console.log("End session received...\n", incomingData);
          onRefreshClick();
          //fetchDetails();
          return;
        }

        if (incomingData.Type === "errors") {
          console.log("Errors count updated: " + JSON.stringify(incomingData));
          //setErrorsCount(incomingData.Content.errors_count);
          return;
        }

        let coordinatesRecord = { Longitude : '', Latitude: '', EventTimestamp: '' }
        for (let record of incomingData.Content) {
          //console.log('New record: ', record);

          if (incomingData.Type === "telemetry") {
            // // if (latestEventTimestamp && record.EventTimestamp < latestEventTimestamp) continue;
            setSessionMetrices((oldMetrices) => {
              let currentMetrices = oldMetrices.find(
                (x) => x.SessionId === incomingData.SessionId
              );
              if (currentMetrices != null) {
                if (record.MeasureUnit === "XCU_HighVoltageSoc_Pc") {
                  currentMetrices.BatteryLevel = record.MeasureValue;
                } else if (record.MeasureUnit === "XCU_Velocity_Ms") {
                  currentMetrices.Velocity = record.MeasureValue;
                } else if (record.MeasureUnit === "ARB_Vehicle_Mode_Zs") {
                  currentMetrices.AVMode = record.MeasureValue;
                } else if (record.MeasureUnit === "ARB_Vehicle_Status_Zs") {
                  currentMetrices.VehicleStatus = record.MeasureValue;
                } else if (record.MeasureUnit === "ARB_TeleopConnStatus_Zs") {
                  currentMetrices.VehicleTeleopStatus = record.MeasureValue;
                } else if (record.MeasureUnit === 'GPSIMU_PosLongitude_D') {
                  currentMetrices.Longitude = record.MeasureValue;
                  coordinatesRecord.Longitude = record.MeasureValue;
                  coordinatesRecord.EventTimestamp = record.EventTimestamp
                } else if (record.MeasureUnit === 'GPSIMU_PosLatitude_D') {
                  currentMetrices.Latitude = record.MeasureValue;
                  coordinatesRecord.Latitude = record.MeasureValue;
                  coordinatesRecord.EventTimestamp = record.EventTimestamp
                }
              }
              return [...oldMetrices];
            });
          } else if (incomingData.Type === "aggregate") {
            // if (!record.LastTimestamp) continue;

            if (
              record.AggregateField === "AverageSpeed" &&
              record.AggregateValue
            ) {
              tempMetrices.AverageSpeed = record.AggregateValue;
              setSessionMetrices((oldMetrices) => {
                let currentMetrices = oldMetrices.find(
                  (x) => x.SessionId === incomingData.SessionId
                );
                if (currentMetrices != null) {
                  currentMetrices.AverageSpeed = record.AggregateValue;
                }
                return [...oldMetrices];
              });
            }
          } 
        }
        if(coordinatesRecord.Longitude && coordinatesRecord.Latitude ) {
          setSessionMetrices((oldMetrices) => {
            const currentMetrices = oldMetrices.find(
              (x) => x.SessionId === coordinatesRecord.SessionId
            );
            if (currentMetrices && coordinatesRecord.Longitude && coordinatesRecord.Latitude) {
              currentMetrices.Longitude = coordinatesRecord.Longitude;
              currentMetrices.Latitude = coordinatesRecord.Latitude;
            }
            return [...oldMetrices];
          });

          setSessionsLocations((oldSessionLocations) => {
            if (oldSessionLocations) {
              const currentSessionLocations = oldSessionLocations.find(
                (x) => x.sessionId === coordinatesRecord.SessionId
              );
              if (currentSessionLocations) {
                let oldLocationsList = currentSessionLocations.locationsList;
                if (
                  oldLocationsList &&
                  !oldLocationsList.find(
                    (c) => c.EventTimestamp === coordinatesRecord.EventTimestamp
                  )
                ) {
                  oldLocationsList = [...oldLocationsList, coordinatesRecord];
                  currentSessionLocations.locationsList = oldLocationsList;
                }
              }
            }
            return oldSessionLocations;
          });
        }
      };

      socket.onerror = (error) => {
        console.log("Error in websocket:\n", error);
      };

      // Reconnect 10s later
      //setTimeout(socket.reconnect(), 10e3);
      //newSockets.push(socket);
    }
    //setWebSockets(newSockets);
  }

  //#endregion

  //#region UI Drawers

  const getFleetStatus = () => {
    if (!hasAccessToEdit) return;
    if (fleetDetails.is_active) {
      return (
        <>
          <div style={{ color: "green" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="green"
              viewBox="0 0 16 16"
            >
              <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            </svg>{" "}
            Active
          </div>
        </>
      );
    } else if (fleetDetails.is_active === false) {
      return (
        <>
          <div style={{ color: "red" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="red"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z" />
            </svg>{" "}
            Disabled
          </div>
        </>
      );
    }
  };

  const getFleetDetailsInfo = (fleetDetails) => {
    if (fleetDetails.fleet_name) {
      return (
        <>
          <Card.Title>
            <b>{fleetDetails.fleet_name}</b>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <i>Created At: {formatDate(fleetDetails.created_at)}</i>
          </Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">
            {getFleetStatus()}
          </Card.Subtitle>
        </>
      );
    } else {
      return (
        <>
          <Placeholder as={Card.Title} animation="wave">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder animation="wave">
            <Placeholder xs={5} />
          </Placeholder>
          <br />
          <Placeholder animation="wave">
            <Placeholder xs={4} bg="success" />
          </Placeholder>
        </>
      );
    }
  };

  const getTabTitle = (vehicle) => {
    return (
      <div>
        <img
          src="/car.png"
          width="32px"
          style={{ margin: "-8px 0px -0px -8px" }}
          alt=""
        />{" "}
        {vehicle.registration_number}
      </div>
    );
  };

  const getJourneyStatus = (sessionId) => {
    const isSessionActive = activeSessions.find((ss) => ss.id === sessionId);
    if (isSessionActive) {
      return (
        <>
          <div style={{ color: "green" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="green"
              viewBox="0 0 16 16"
            >
              <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            </svg>{" "}
            Online
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ color: "gray" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="gray"
              viewBox="0 0 16 16"
            >
              <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            </svg>{" "}
            Offline
          </div>
        </>
      );
    }
  };

  const hasActiveJourney = (sessionId) => {
    return activeSessions.filter((ss) => ss.id === sessionId).length > 0;
  };

  const drawVehicleCard = (vehicle, index) => {
    const currentvehicleMetrices = sessionMetrices.find(
      (x) => x.VehicleId === vehicle.id
    );
    const hasActiveSession = hasActiveJourney(
      currentvehicleMetrices ? currentvehicleMetrices.SessionId : ""
    );

    let newSessionMetrices = sessionMetrices.find(
      (x) => x.VehicleId === vehicle.id
    );

    if (!newSessionMetrices) newSessionMetrices = {};
    const centerPoint = {
      lng: newSessionMetrices.Longitude,
      lat: newSessionMetrices.Latitude,
    };
    const lastLocation = {
      Longitude: newSessionMetrices.Longitude,
      Latitude: newSessionMetrices.Latitude,
    };
    const vehicleDetails = vehicle;
    const vehicleSessionMetrices = newSessionMetrices;
    const foundItem =
      sessionsLocations &&
      sessionsLocations.find(
        (ss) => ss.sessionId === newSessionMetrices.SessionId
      );
    const allLocations = foundItem ? foundItem.locationsList : [];

    return (
      <>
        <Card id={"vehicle-card-" + index} border="success">
          <Card.Header>{getTabTitle(vehicle)}</Card.Header>
          <Card.Body>
            <Row>
              <Col md={8}>
                {" "}
                <ListGroup className="list-group-flush">
                  <div className="float-container">
                    <div className="float-child">
                      <ListGroupItem>
                        <div>
                          <Col style={{ float: "left", paddingRight: "5px" }}>
                            Status:
                          </Col>
                          <Col>
                            {getJourneyStatus(
                              currentvehicleMetrices
                                ? currentvehicleMetrices.SessionId
                                : ""
                            )}
                          </Col>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        Client: <b>{vehicle.client_id}</b>
                      </ListGroupItem>
                      <ListGroupItem>
                        VIN: <b>{vehicle.vin}</b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Registration Number:{" "}
                        <b>{vehicle.registration_number}</b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Status:{" "}
                        <b>
                          {currentvehicleMetrices != null
                            ? getVehicleStatusFromEnum(
                                currentvehicleMetrices.VehicleStatus
                              )
                            : ""}
                        </b>
                      </ListGroupItem>
                    </div>
                    <div className="float-child">
                      <ListGroupItem>
                        Connection to Teleop: <b>{"Static Value"}</b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Battery Level:{" "}
                        <b>
                          {currentvehicleMetrices != null
                            ? currentvehicleMetrices.BatteryLevel + " %"
                            : ""}
                        </b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Current Speed:{" "}
                        <b>
                          {currentvehicleMetrices != null
                            ? roundDecimal(currentvehicleMetrices.Velocity, 3) +
                              " Km/h"
                            : ""}
                        </b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Average Speed:{" "}
                        <b>
                          {currentvehicleMetrices != null
                            ? roundDecimal(
                                currentvehicleMetrices.AverageSpeed,
                                3
                              ) + " Km/h"
                            : ""}
                        </b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Teleop Status:{" "}
                        <b>
                          {currentvehicleMetrices != null
                            ? getVehicleTeleopStatusFromEnum(
                                currentvehicleMetrices.VehicleTeleopStatus
                              )
                            : ""}
                        </b>
                      </ListGroupItem>
                    </div>
                  </div>
                </ListGroup>
              </Col>
              <Col md={4}>
                {hasActiveSession ? (
                  <MapComponent
                    centerPoint={centerPoint}
                    locations={allLocations}
                    mainPoints={[
                      {
                        coords: lastLocation,
                        info: vehicleSessionMetrices,
                        vehicleInfo: vehicleDetails,
                      },
                    ]}
                  ></MapComponent>
                ) : (
                  <Alert variant="secondary" style={{ height: "100%" }}>
                    No active journeys !
                  </Alert>
                )}
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            {(clients.length > 0 || vehicle.client_id === client) && (
              <Button variant="info" onClick={() => gotToDetails(vehicle.id)}>
                Details
              </Button>
            )}
            <span> </span>
            {(clients.length > 0 || vehicle.client_id === client) && (
              <Button
                variant="success"
                onClick={() => gotToJourneys(vehicle.id)}
              >
                Journeys
              </Button>
            )}
          </Card.Footer>
        </Card>
        <br />
      </>
    );
  };

  const getAlertMessage = () => {
    return (
      <Alert key="alert-message" variant={accessMessageVariant}>
        {hasAccessToEdit === null && <>Checking permissions...</>}
        {hasAccessToEdit !== null && accessMessage}
      </Alert>
    );
  };
  //#endregion

  return (
    <>
      <Row>
        <h1>Fleet Details</h1>
        <br />
        <Col>{getAlertMessage()}</Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="3" style={{textAlign: 'right'}}>
          <Button variant="primary" onClick={onBackClick}>
            Back to fleet
          </Button>{" "}
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <Card.Body>{getFleetDetailsInfo(fleetDetails)}</Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                Linked Vehicles:{" "}
                <Badge variant="warning" pill>
                  <div>
                    {fleetDetails.nb_vehicles ? fleetDetails.nb_vehicles : "0"}
                  </div>
                </Badge>
              </ListGroup.Item>
              <ListGroup.Item>
                Active Vehicles:{" "}
                <Badge variant="warning" pill>
                  <div>
                    {fleetDetails.active_vehicles
                      ? fleetDetails.active_vehicles
                      : "0"}
                  </div>
                </Badge>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col style={{ minHeight: "300px" }}>
          <MapComponent
            mainPoints={sessionMetrices.map((m) => {
              //console.log('MAP: > ', m);
              return {
                info: m,
                coords: { Longitude: m.Longitude, Latitude: m.Latitude },
                vehicleInfo:
                  fleetVehicles != null
                    ? fleetVehicles.find((c) => c.id === m.VehicleId)
                    : null,
              };
            })}
          ></MapComponent>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <Card.Header className="col-12" as="h5">
              <div>Linked Vehicles:</div>
            </Card.Header>
            <Card.Body>
              {fleetVehicles && fleetVehicles.length > 0 ? (
                <Row id={"card-row"}>
                  <Col>
                    {fleetVehicles.map((vehicle, index) => {
                      return drawVehicleCard(vehicle, index);
                    })}
                  </Col>
                </Row>
              ) : (
                <Alert key="nolinkedvehicles" variant="warning">
                  No linked vehicles !
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md="12">
          <Card>
            <Card.Header className="col-12" as="h5">
              <div>Shared With Clients:</div>
            </Card.Header>
            <Card.Body>
              {sharedClients && sharedClients.length === 0 ? (
                <Alert key="nosharedvehicles" variant="warning">
                  No shared clients !
                </Alert>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr key="header">
                      <th>Client Name</th>
                      <th>Sharing Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sharedClients.map((record) => {
                      return (
                        <tr key={"sharedClient-" + record.id}>
                          <td>{record.client_name}</td>
                          <td>{formatDate(record.share_date)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const FleetDetails = () => (
  <Container>
    <FleetDetailsInner />
  </Container>
);

export default FleetDetails;
