import React from "react";
import { Spinner } from "react-bootstrap";

const getSVGIcon = (status, size) => {
  switch (status.toLocaleUpperCase()) {
    case "OFFLINE":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 52 52"
          fill="grey"
        >
          <g>
            <path
              d="M34.7,36.1c0.5-0.5,0.5-1.3,0-1.8l-1.8-1.8c-0.5-0.5-1.3-0.5-1.8,0l-4.4,4.4c-0.3,0.3-0.9,0.3-1.2,0
                    l-4.4-4.4c-0.5-0.5-1.3-0.5-1.8,0l-1.8,1.8c-0.5,0.5-0.5,1.3,0,1.8l4.4,4.4c0.3,0.3,0.3,0.9,0,1.2l-4.4,4.4c-0.5,0.5-0.5,1.3,0,1.8
                    l1.8,1.8c0.5,0.5,1.3,0.5,1.8,0l4.4-4.4c0.3-0.3,0.9-0.3,1.2,0l4.4,4.4c0.5,0.5,1.3,0.5,1.8,0l1.8-1.8c0.5-0.5,0.5-1.3,0-1.8
                    l-4.4-4.4c-0.3-0.3-0.3-0.9,0-1.2L34.7,36.1z"
            />
            <path
              d="M47.7,11.6c-5.5-6.1-13.3-9.5-21.6-9.5S10,5.5,4.5,11.6C4.1,12,4.2,12.7,4.6,13l3,2.6C8,16,8.6,15.9,9,15.5
                    c4.4-4.7,10.6-7.4,17.1-7.4s12.7,2.7,17.1,7.4c0.4,0.4,1,0.4,1.4,0.1l3-2.6C48,12.6,48.1,12,47.7,11.6z"
            />
            <path
              d="M26.1,16.1c-4.2,0-8.2,1.8-11,5c-0.4,0.4-0.3,1.1,0.1,1.5l3.2,2.4c0.4,0.3,1,0.3,1.3-0.1
                    c1.7-1.8,4-2.8,6.4-2.8s4.7,1,6.3,2.7c0.3,0.4,0.9,0.4,1.3,0.1l3.2-2.4c0.5-0.4,0.5-1,0.1-1.5C34.3,17.9,30.3,16.1,26.1,16.1z"
            />
          </g>
        </svg>
      );
    case "AVAILABLE":
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
          fill="green"
        >
          <path d="M11.8 18.93a.817.817 0 0 1 .07-.128.712.712 0 0 0-.09.2.182.182 0 0 1 .02-.07zm.21-.316c.01-.01.02-.01.03-.02a.643.643 0 0 0-.08.09.178.178 0 0 1 .05-.07z" />
          <path d="M11.8 18.93a.817.817 0 0 1 .07-.128.712.712 0 0 0-.09.2.182.182 0 0 1 .02-.07zm.24-.336a.643.643 0 0 0-.08.09.178.178 0 0 1 .05-.07c.01-.014.02-.014.03-.02zM17 6.954a1 1 0 0 1-.616-.21 11.4 11.4 0 0 0-14.71-.05A1 1 0 0 1 .262 6.63a.988.988 0 0 1 .064-1.4c.074-.075 8.122-7.196 17.29-.05a.988.988 0 0 1 .17 1.394 1 1 0 0 1-.786.38zM11.642 12.674a1.026 1.026 0 0 1-1.41-.12A1.933 1.933 0 0 0 9 11.912a1.936 1.936 0 0 0-1.23.645 1.026 1.026 0 0 1-1.412.12.982.982 0 0 1-.13-1.388A4.058 4.058 0 0 1 9 9.926a4.063 4.063 0 0 1 2.772 1.36.983.983 0 0 1-.13 1.387z" />
          <path d="M14.72 9.622a1 1 0 0 1-1.41.03A6.792 6.792 0 0 0 9 7.956a6.792 6.792 0 0 0-4.31 1.7 1 1 0 0 1-1.41-.03.99.99 0 0 1 .02-1.4A8.54 8.54 0 0 1 9 5.964a8.54 8.54 0 0 1 5.7 2.26.99.99 0 0 1 .02 1.398z" />
          <ellipse cx="9" cy="14.882" rx="1" ry=".991" />
        </svg>
      );
    case "NOT_AVAILABLE":
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="#FF5959"
        >
          <path d="M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z" />
        </svg>
      );
    case "CONNECTED":
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          fill="#8946A6"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.707 2.707a1 1 0 00-1.414-1.414l-1.394 1.394a4.001 4.001 0 00-5.071.485l-1.12 1.12a1 1 0 00-1.415 1.415L3.168 6.832a4.001 4.001 0 00-.485 5.07l-1.39 1.39a1 1 0 101.414 1.415l1.39-1.39a4.001 4.001 0 005.071-.485l1.125-1.125a1 1 0 001.414-1.414l1.12-1.12a4.001 4.001 0 00.486-5.072l1.394-1.394zM10.293 8.88l1.12-1.121a2 2 0 000-2.828l-.343-.344.758-.758-.758.758a2 2 0 00-2.828 0l-1.12 1.121 3.17 3.172zM5.707 7.12L4.582 8.246a2 2 0 000 2.828l-.754.754.754-.754.344.344a2 2 0 002.828 0l1.125-1.125L5.707 7.12z"
            fill="purple"
          />
        </svg>
      );
    case "DISCONNECTED":
      return (
        <svg
          width={size}
          height={size}
          fill="crimson"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          transform="rotate(135,0,0)"
        >
          <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z" />
          <path
            fill-rule="evenodd"
            d="M8 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13A.5.5 0 0 1 8 1z"
          />
        </svg>
      );
    case "DISABLED":
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="grey"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <g>
                <path
                  d="M379.321,101.701c-0.222-2.962-1.724-5.681-4.113-7.444C340.484,68.624,299.263,55.076,256,55.076
                                C145.21,55.076,55.076,145.21,55.076,256c0,43.263,13.549,84.484,39.181,119.207c1.764,2.39,4.483,3.891,7.444,4.113
                                c0.254,0.019,0.508,0.029,0.762,0.029c2.695,0,5.291-1.067,7.212-2.986l266.687-266.688
                                C378.462,107.574,379.542,104.663,379.321,101.701z M103.906,353.283C85.268,324.261,75.474,290.871,75.474,256
                                c0-99.542,80.984-180.526,180.526-180.526c34.871,0,68.261,9.794,97.283,28.432L103.906,353.283z"
                />
                <path
                  d="M417.744,136.792c-1.764-2.39-4.483-3.891-7.444-4.113c-2.959-0.218-5.873,0.859-7.974,2.959L135.638,402.325
                                c-2.1,2.101-3.18,5.012-2.959,7.974c0.222,2.962,1.724,5.681,4.113,7.444c34.723,25.633,75.944,39.181,119.207,39.181
                                c110.79,0,200.924-90.134,200.924-200.924C456.924,212.737,443.376,171.516,417.744,136.792z M256,436.526
                                c-34.871,0-68.261-9.794-97.283-28.432l249.377-249.377c18.638,29.022,28.432,62.412,28.432,97.283
                                C436.526,355.542,355.542,436.526,256,436.526z"
                />
                <path
                  d="M437.02,74.98C388.667,26.628,324.38,0,256,0S123.333,26.628,74.98,74.98C26.628,123.333,0,187.62,0,256
                                s26.628,132.667,74.98,181.02C123.333,485.372,187.62,512,256,512s132.667-26.628,181.02-74.98
                                C485.372,388.667,512,324.38,512,256S485.372,123.333,437.02,74.98z M256,491.602c-129.911,0-235.602-105.69-235.602-235.602
                                S126.089,20.398,256,20.398S491.602,126.089,491.602,256S385.911,491.602,256,491.602z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                d="M354.767,375.436c-3.321-4.549-9.703-5.543-14.251-2.223c-25.632,18.714-56.012,28.606-87.856,28.606
                            c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199c36.197,0,70.737-11.249,99.884-32.529
                            C357.094,386.365,358.088,379.985,354.767,375.436z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M386.612,339.617c-4.516-3.365-10.906-2.434-14.273,2.082c-1.693,2.271-3.471,4.52-5.284,6.685
                            c-3.617,4.317-3.049,10.75,1.269,14.368c1.91,1.599,4.234,2.38,6.545,2.38c2.914,0,5.806-1.241,7.823-3.648
                            c2.059-2.459,4.08-5.014,6.002-7.593C392.06,349.374,391.128,342.984,386.612,339.617z"
              />
            </g>
          </g>
        </svg>
      );
    case "ENDED":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 52 52"
          fill="grey"
        >
          <g>
            <path
              d="M34.7,36.1c0.5-0.5,0.5-1.3,0-1.8l-1.8-1.8c-0.5-0.5-1.3-0.5-1.8,0l-4.4,4.4c-0.3,0.3-0.9,0.3-1.2,0
                      l-4.4-4.4c-0.5-0.5-1.3-0.5-1.8,0l-1.8,1.8c-0.5,0.5-0.5,1.3,0,1.8l4.4,4.4c0.3,0.3,0.3,0.9,0,1.2l-4.4,4.4c-0.5,0.5-0.5,1.3,0,1.8
                      l1.8,1.8c0.5,0.5,1.3,0.5,1.8,0l4.4-4.4c0.3-0.3,0.9-0.3,1.2,0l4.4,4.4c0.5,0.5,1.3,0.5,1.8,0l1.8-1.8c0.5-0.5,0.5-1.3,0-1.8
                      l-4.4-4.4c-0.3-0.3-0.3-0.9,0-1.2L34.7,36.1z"
            />
            <path
              d="M47.7,11.6c-5.5-6.1-13.3-9.5-21.6-9.5S10,5.5,4.5,11.6C4.1,12,4.2,12.7,4.6,13l3,2.6C8,16,8.6,15.9,9,15.5
                      c4.4-4.7,10.6-7.4,17.1-7.4s12.7,2.7,17.1,7.4c0.4,0.4,1,0.4,1.4,0.1l3-2.6C48,12.6,48.1,12,47.7,11.6z"
            />
            <path
              d="M26.1,16.1c-4.2,0-8.2,1.8-11,5c-0.4,0.4-0.3,1.1,0.1,1.5l3.2,2.4c0.4,0.3,1,0.3,1.3-0.1
                      c1.7-1.8,4-2.8,6.4-2.8s4.7,1,6.3,2.7c0.3,0.4,0.9,0.4,1.3,0.1l3.2-2.4c0.5-0.4,0.5-1,0.1-1.5C34.3,17.9,30.3,16.1,26.1,16.1z"
            />
          </g>
        </svg>
      );
    case "STARTED":
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
          fill="green"
        >
          <path d="M11.8 18.93a.817.817 0 0 1 .07-.128.712.712 0 0 0-.09.2.182.182 0 0 1 .02-.07zm.21-.316c.01-.01.02-.01.03-.02a.643.643 0 0 0-.08.09.178.178 0 0 1 .05-.07z" />
          <path d="M11.8 18.93a.817.817 0 0 1 .07-.128.712.712 0 0 0-.09.2.182.182 0 0 1 .02-.07zm.24-.336a.643.643 0 0 0-.08.09.178.178 0 0 1 .05-.07c.01-.014.02-.014.03-.02zM17 6.954a1 1 0 0 1-.616-.21 11.4 11.4 0 0 0-14.71-.05A1 1 0 0 1 .262 6.63a.988.988 0 0 1 .064-1.4c.074-.075 8.122-7.196 17.29-.05a.988.988 0 0 1 .17 1.394 1 1 0 0 1-.786.38zM11.642 12.674a1.026 1.026 0 0 1-1.41-.12A1.933 1.933 0 0 0 9 11.912a1.936 1.936 0 0 0-1.23.645 1.026 1.026 0 0 1-1.412.12.982.982 0 0 1-.13-1.388A4.058 4.058 0 0 1 9 9.926a4.063 4.063 0 0 1 2.772 1.36.983.983 0 0 1-.13 1.387z" />
          <path d="M14.72 9.622a1 1 0 0 1-1.41.03A6.792 6.792 0 0 0 9 7.956a6.792 6.792 0 0 0-4.31 1.7 1 1 0 0 1-1.41-.03.99.99 0 0 1 .02-1.4A8.54 8.54 0 0 1 9 5.964a8.54 8.54 0 0 1 5.7 2.26.99.99 0 0 1 .02 1.398z" />
          <ellipse cx="9" cy="14.882" rx="1" ry=".991" />
        </svg>
      );
    case "BUSY":
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
          fill="darkorange">
          <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z"/>
        </svg>
      );
    case "LOADING":
      return (
        <div title={status}>
          <Spinner animation="border" />
        </div>
      );
    default:
      return <></>;
  }
};
const WorkstationStatusIcon = (props) => {
  if (!props.status) return;
  return (
    <div
      title={props.status}
      style={{ display: props.withText ? "block" : "inline" }}
    >
      {getSVGIcon(props.status, props.size)}
    </div>
  );
};

export default WorkstationStatusIcon;
