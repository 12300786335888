import awsmobile from "../../aws-exports";
const AWS = require('aws-sdk')


const SendMqttMessage = function (host, topic, message) {
    // return 'message sent ! >> ' + new Date().toISOString();
    try {
        //console.log(host, '\n', topic);

        const iotData = new AWS.IotData({
             endpoint: host,
             region: 'eu-west-2',
             accessKeyId: awsmobile.aws_access_key_id,
             secretAccessKey: awsmobile.aws_secret_access_key,  
            });
        
        let params = {
            topic: topic,
            payload: `{"input": [ ${message} ]}`,
            qos: 0
        };

        iotData.publish(params, function(err, data){
            if(err){
                console.log(`Unable to notify IoT of stories update: ${err}`);
            }
            else{
                console.log('Successfully notified IoT of stories update');
            }
        });

        return `HOST: ${host}\nTOPIC: ${topic}\nPAYLOAD:\n${message}`;

    } catch (error) {
        console.log(error);
    }
};

export default SendMqttMessage