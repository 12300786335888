import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl
} from "react-bootstrap";
import { getClientProfile, getDecisionEngine } from '../../utils/helper'
const axios = require('axios').default;

const StartSessionInner = () => {

  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [vehicleId, setVehicleId] = useState('');
  const [runConfigs, setRunConfigs] = useState([]);
  const [runConfigId, setRunConfigId] = useState('');
  const [zephrSessionAccess, setZephrSessionAccess] = useState('')

  const handleClientChange = (e) => { setClientId(e.target.value) }
  const onVehicleChange = (event) => { setVehicleId(event.target.value) }
  const onRunConfigChange = (event) => { setRunConfigId(event.target.value) }

  const clientQuery = `
  {
    clients {
      id
      client_name
    }
  }`;

  useEffect(() => {
    fetchClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSessions([])
    setVehicles([])
    setRunConfigs([])
    getVehicles(clientId)
    getRunConfigs(clientId)
  }, [clientId]);

  useEffect(() => {
    getSessions()
    document.getElementById('api-result').value = ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  const fetchClientData = async () => {

    let client = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('access-result').innerHTML = `<div class='red'>User is not allowed to access software collection.</div>`
        client = "-1"
      } else if (mainResponse.outputValue === '1') {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('access-result').innerHTML = `<div class='green'>User has full privilege to access software collection for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          client = res.client
        })
      } else {
        document.getElementById('access-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        client = "-1"
      }
    });

    if (client === "-1") {
      return
    }

    getDecisionEngine('hub---startend-journey').then(res => {
      if (res.outputValue) {
        setZephrSessionAccess(res.outputValue)
        if (res.outputValue === '0') {
          document.getElementById('access-result').innerHTML = `<div class='red'>User is not allowed to start/end sessions.</div>`
        }
        else if (res.outputValue === '1' && client !== '') {
          document.getElementById('access-result').innerHTML = `<div class="yellow">User has privilege to start/end sessions for ${clientId} client.</div>`
        }
      }
    });

    document.getElementById('api-result').value = ''

    getVehicles(client)
    getRunConfigs(client)
  }

  const onEndSession = async (sessionId) => {
    if (zephrSessionAccess === '0') {
      document.getElementById('access-result').innerHTML = `<div class='red'>User has no access to Start/End sessions</div>`
      return
    }
    let config = {
      headers: {
        'x-api-key': process.env.REACT_APP_APIKEY,
        'Content-Type': 'application/json',
      }
    }
    axios.put(
      `https://${process.env.REACT_APP_API_GATEWAY_URL}/${process.env.REACT_APP_SERVERLESS_STAGE}/vehicle/${vehicleId}/session/${sessionId}/end`,
      {
        run_config_id: runConfigId,
      },
      config
    )
      .then((res) => {
        document.getElementById('api-result').value = JSON.stringify(res.data)
        getSessions()
      })
      .catch(e => {
        console.log(e)
        //document.getElementById('api-result').value = e.message
      })

  }

  const onStartSession = async () => {
    if (zephrSessionAccess === '0') {
      document.getElementById('access-result').innerHTML = `<div class='red'>User has no access to Start/End sessions</div>`
      return
    }
    try {

      const url = `https://${process.env.REACT_APP_API_GATEWAY_URL}/${process.env.REACT_APP_SERVERLESS_STAGE}/vehicle/${vehicleId}/session`
      let config = {
        headers: {
          'x-api-key': process.env.REACT_APP_APIKEY,
          'Content-Type': 'application/json',
        },
        statusCode: 200
      }
      await axios.post(
        url,
        {
          run_config_id: runConfigId,
        },
        config
      )
        .then(async res => {
          document.getElementById('api-result').value = JSON.stringify(res.data)
          getSessions()
        })
        .catch(e => {
          console.log(e)
          document.getElementById('api-result').value = e.message
        })

    } catch (e) {
      document.getElementById('api-result').value = e.message
      console.log(e)
    }
  }

  const getSessions = async () => {
    //return
    console.log('get vehicle sessions:\nVehicleId: ', vehicleId);
    if (!vehicleId) return []
    const getSessions = `
      {
        sessions(vehicleId: "${vehicleId}") {
          id
          vehicle_id
          run_config_id
          start
          end
       }
    }`;
    const apiData = await API.graphql(graphqlOperation(getSessions))
    setSessions(apiData.data.sessions)
  }

  const getVehicles = async (clientId) => {

    const sessionAndVehiculesQuery = `
    {
        vehicles(clientId: "${clientId}") {
        id
        registration_number
        vin
        }
    }`;
    const apiData = await API.graphql(graphqlOperation(sessionAndVehiculesQuery))
    const vehicleData = apiData.data.vehicles;
    setVehicles(vehicleData)
  }

  const getRunConfigs = async (clientId) => {

    const configsQuery = `
    {
        runConfigs(clientId: "${clientId}") {
          hubVehicleConfigurationData
          data {
            id
            details
            display_name
            hardware_collection_id
            software_collection_id
            created_at
            updated_at
          }
        }
    }`;
    const apiData = await API.graphql(graphqlOperation(configsQuery))
    const configData = apiData.data.runConfigs.data;
    setRunConfigs(configData)

  }

  return (
    <>
      <br />
      <Row>
        <Col>
          <div id="access-result"></div>
        </Col>
        <Col></Col>
        <Col md="auto">
          <div Style="display:inline; float:right; padding-right:15px">
            <Button variant="warning" onClick={getSessions}>
              Refresh
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Form>
          <Card>
            <Card.Header className="col-12" as="h5">
              <div Style="display:inline">Start new Session:</div>
            </Card.Header>
            <Card.Body>
              {clients.length > 0 && <InputGroup className="mb-3">
                <InputGroup.Text id="HardwareCollectionId">Client</InputGroup.Text>
                <Form.Select aria-label="" onChange={handleClientChange}>
                  <option defaultValue={true}>[Select a Client]</option>
                  {
                    clients.map(s =>
                      <option value={s.id} key={s.id}>
                        {s.client_name}
                      </option>
                    )
                  }
                </Form.Select>
              </InputGroup>}
              <InputGroup className="mb-3">
                <InputGroup.Text id="vin">Vehicle Registration Num.</InputGroup.Text>
                <Form.Select aria-label="" onChange={onVehicleChange}>
                  <option defaultValue={true}>[Select a Vehicle ID]</option>
                  {
                    vehicles.map(s =>
                      <option value={s.id} key={s.id}>
                        {s.registration_number}
                      </option>
                    )
                  }
                </Form.Select>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="vin">Run Config ID</InputGroup.Text>
                <Form.Select aria-label="" onChange={onRunConfigChange}>
                  <option defaultValue={true}>[Select a Run Config ID]</option>
                  {
                    runConfigs.map(s =>
                      <option value={s.id} key={s.id}>
                        {s.display_name}
                      </option>
                    )
                  }
                </Form.Select>
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Details</InputGroup.Text>
                <FormControl
                  as="textarea"
                  aria-label="{JSON}"
                  id='api-result'
                  rows={3}
                />
              </InputGroup>
            </Card.Body>
            <Card.Footer>
              <div Style="float:right; padding-right:15px">
                <Button variant="primary" onClick={onStartSession}>
                  Start Session
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Form>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Session ID</th>
            <th>Vehicle Reg. Num.</th>
            <th>Run Config</th>
            <th>Started At</th>
            <th>Ended At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sessions.sort((a, b) => (a.end > b.end || !a.end) ? -1 : ((b.end > a.end) ? 1 : 0)).map((session, index) => {
            return (
              <tr key={"session-" + index}>
                <td>{session.id}</td>
                <td>{vehicles.includes(x => x.id === session.vehicle_id) && vehicles.filter(x => x.id === session.vehicle_id)[0].registration_number}</td>
                <td>{runConfigs.includes(x => x.id === session.run_config_id) && runConfigs.filter(x => x.id === session.run_config_id)[0].display_name}</td>
                <td>{session.start}</td>
                <td>{session.end}</td>
                <td Style="min-width:150px; text-align:center" >{!session.end ? <Button variant="primary" onClick={() => { onEndSession(session.id) }} >
                  End Session
                </Button> : ''}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const StartSession = () => (
  <Container>
    <h1>Session Management</h1>
    <StartSessionInner />
  </Container>
);

export default StartSession;
