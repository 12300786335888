import React, { useState, useEffect } from "react";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { formatDateTime, getAVModeFromEnum, getVehicleStatusFromEnum } from "../../lib/Extensions";
import { getDecisionEngine, getClientProfile } from '../../utils/helper'
import axios from "@aws-amplify/storage/node_modules/axios";

const VehiclesInner = ({ children }) => {

  let history = useHistory();
  const [vehicles, setVehiclesValues] = useState([]);
  const [lastSessionMetrices, setlastSessionMetrices] = useState([]);
  const [hasAccess, setHasAccess] = useState(true);

  const sessionMetricesQuery = `
    {
      sessionMetricesBySessionIds (SessionIds: [SESSIONIDS]) {
        hubJourneyData
        data {
          VehicleId
          SessionId
          EventTimestamp
          Velocity
          BatteryLevel
          Longitude
          Latitude
          AVMode
          VehicleStatus
          LastUpdated
        }
      }
    }`;

  useEffect(() => {
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchVehicles();
  };

  async function fetchVehicles() {

    setHasAccess(false);
    let clientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access vehicles.</div>`
        clientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        await getDecisionEngine('hub---create-a-vehicle').then(async mainResponse => {
          if (mainResponse.outputValue === '0') {
            setHasAccess(false)
          } else {
            setHasAccess(true)
          }
        });
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access vehicles for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getDecisionEngine('hub---create-a-vehicle').then(async mainResponse => {
          if (mainResponse.outputValue === '0') {
            setHasAccess(false)
          } else {
            setHasAccess(true)
          }
        });
        await getClientProfile().then(async res => {
          clientId = res.client
          document.getElementById('api-result').innerHTML = `<div class='yellow'>User has privilege to access vehicles for ${clientId} client.</div>`
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        clientId = "-1"
      }
    });

    if (clientId === "-1") {
      return
    }

    setVehiclesValues([]);
    setlastSessionMetrices([]);

    const vehiclesQuery = `
    {
      vehiclesWithSessions (clientId: "${clientId}")  {
        hubVehicleConfigurationData
        data {
          vehicle_id
          vin
          registration_number
          fleet_id
          session_id
          run_config_id
          start
          end
        }
      }
    }`;

    const axiosOptions = {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`,
      },
      withCredentials: (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") ? false : true
    }

    const apiData = await axios.post(`${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`, { query: vehiclesQuery, variables: {} }, axiosOptions);
    console.log(apiData)
    const vehiclesData = apiData.data.data.vehiclesWithSessions.data;

    const sessionIds = vehiclesData
      .filter((s) => s.session_id != null)
      .map((x) => `"${x.session_id}"`);
    const apiData2 = await axios.post(
      `${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`, 
      { query: sessionMetricesQuery.replace("SESSIONIDS", sessionIds.join(",")), variables: {} },
        axiosOptions);

    let metrices = apiData2.data.data.sessionMetricesBySessionIds.data;
    if (metrices.sessionMetricesBySessionIds) {
      metrices = metrices.sessionMetricesBySessionIds
    }

    setVehiclesValues(vehiclesData);
    setlastSessionMetrices(metrices);
  }

  const gotToDetails = (id) => {
    history.push("/vehicleDetails?vehicleId=" + id);
  };
  const gotToJourneys = (id) => {
    history.push("/vehicleJourneys?vehicleId=" + id);
  };

  const drawDisabled = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
      </svg>);
  }

  const goToEdit = (id) => {
    if (!hasAccess) {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to edit a vehicle.</div>`
      return;
   }
  history.push("/editVehicle?VehicleId=" + id);
};

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Vin</th>
            <th>Registration</th>
            <th>Location</th>
            <th>Status</th>
            <th>AV mode</th>
            <th>Battery</th>
            <th>Last seen</th>
            <th style={{ 'minWidth': '245px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map((vehicle, index) => {
            let sessionMetrices = lastSessionMetrices.find(
              (x) => x.VehicleId === vehicle.vehicle_id || x.VehicleId === 'restricted'
            );

            if (sessionMetrices && sessionMetrices.Longitude === 'restricted') {

              return (
                <tr key={"vehicle-" + vehicle.vehicle_id}>
                  <td>{vehicle.vin}</td>
                  <td>{vehicle.registration_number}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() => gotToDetails(vehicle.vehicle_id)}
                    >
                      Details
                    </Button>
                    <span> </span>
                    <Button
                      variant="success"
                      onClick={() => gotToJourneys(vehicle.vehicle_id)}
                    >
                      Journeys
                    </Button>
                  </td>
                </tr>
              );
            } else {

              return (
                <tr key={"vehicle-" + vehicle.vehicle_id}>
                  <td>{vehicle.vin}</td>
                  <td>{vehicle.registration_number}</td>
                  <td>
                    {sessionMetrices && sessionMetrices.Longitude ? `${sessionMetrices.Longitude}, ${sessionMetrices.Latitude}` : 'N/A'}
                  </td>
                  <td>
                    {sessionMetrices && sessionMetrices.VehicleStatus >= 0 ? getVehicleStatusFromEnum(sessionMetrices.VehicleStatus) : 'N/A'}
                  </td>
                  <td>
                    {sessionMetrices && sessionMetrices.AVMode >= 0 ? getAVModeFromEnum(sessionMetrices.AVMode) : 'N/A'}
                  </td>
                  <td>
                    {sessionMetrices && sessionMetrices.BatteryLevel ? `${sessionMetrices.BatteryLevel}%` : 'N/A'}
                  </td>
                  <td>{sessionMetrices && sessionMetrices.LastUpdated ? `${formatDateTime(sessionMetrices.LastUpdated)}` : ''}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() => gotToDetails(vehicle.vehicle_id)}
                    >
                      Details
                    </Button>
                    <span> </span>
                    <Button
                      variant="primary"
                      disabled={hasAccess ? '' : 'disabled'}
                      onClick={() => goToEdit(vehicle.vehicle_id)}
                      style={{ marginRight: '4px' }}
                    >
                      Edit
                    </Button>
                    <span> </span>
                    <Button
                      variant="success"
                      onClick={() => gotToJourneys(vehicle.vehicle_id)}
                    >
                      Journeys
                    </Button>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    </>
  );
};

const Vehicles = () => (
  <Container>
    <h1>All Vehicles</h1>
    <VehiclesInner />
  </Container>
);

export default Vehicles;
