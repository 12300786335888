import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";
import { getClientProfile, getDecisionEngine } from '../../utils/helper'

const RunConfigsInner = ({ children }) => {

  const [runConfigs, setRunConfigs] = useState([]);
  const [softwareCollections, setSoftwareCollections] = useState([]);
  const [hardwareCollections, setHardwareCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClientId] = useState('');
  const [outputValue, setOutputValue] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [detailsField, setDetailsField] = useState('');
  const [selectedSoftwareCollection, setSelectedSoftwareCollection] = useState('');
  const [selectedHardwareCollection, setSelectedHardwareCollection] = useState('');


  const handleDetailsChange = (e) => { setDetailsField(JSON.parse(e.target.value)) };
  const handleDisplayNameChange = (e) => { setDisplayName(e.target.value) };
  const handleSoftwareCollectionChange = (e) => { setSelectedSoftwareCollection(e.target.value) };
  const handleHardwareCollectionChange = (e) => { setSelectedHardwareCollection(e.target.value) };
  const handleClientChange = (e) => { setClientId(e.target.value) } 
  
  const clientQuery = `
    {
      clients {
        id
        client_name
      }
    }`;
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const onRefreshClick = () => {
    fetchData();
  };

  async function fetchData() {
    let clientId = client
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0'){
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access run config.</div>`
        clientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access run config for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          clientId = res.client
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        clientId = "-1"
      }
    });

    if (clientId === "-1"){
      return
    }
    await fetchDataPerClient(clientId)
  }

  async function fetchDataPerClient(clientId) {
    setDisplayName("New RunConfig");
    setDetailsField({ "route": "millbrook1" });
    setSoftwareCollections([]);
    setHardwareCollections([]);

    const mainQuery = `
    {
      runConfigs (clientId: "${clientId}") {
        hubVehicleConfigurationData
        data {
          id
          details
          display_name
          hardware_collection_id
          software_collection_id
          created_at
          updated_at
        }
      }
      softwareCollections (clientId: "${clientId}"){
        id
        details
        display_name
        created_at
        updated_at
      }
      hardwareCollections (clientId: "${clientId}"){
        id
        details
        display_name
        created_at
        updated_at
      }
    }`;

    const apiData = await API.graphql(graphqlOperation(mainQuery));
    const runConfigsData = apiData.data.runConfigs.data;
    const softwareCollectionsData = apiData.data.softwareCollections;
    const hardwareCollectionsData = apiData.data.hardwareCollections;

    setRunConfigs(runConfigsData);
    setSoftwareCollections(softwareCollectionsData);
    setHardwareCollections(hardwareCollectionsData);

    await getDecisionEngine('hub---create-config-file').then(
      async results => {
      setOutputValue(results.outputValue);
      console.log(JSON.stringify(results), 'config access rule')
      if (results.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a config file.</div>`
      }
      else if((results.outputValue === '1' && client !== '' && clients.length === 0)) {
        document.getElementById('api-result').innerHTML = `<div class="yellow">User has privilege to create a config file only under ${clientId} client.</div>`
      }
  })
  }

  const onAddNewClick = () => {
    if (outputValue === '0'){
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a config file.</div>`
      return
    }
    console.log('Sending api call:\n', JSON.stringify(detailsField));

    const addRunConfig = `
      mutation ($details: AWSJSON!) {
        addRunConfig(
            hardware_collection_id: "${selectedHardwareCollection}",
            software_collection_id: "${selectedSoftwareCollection}",
            details: $details,
            display_name: "${displayName}",
            client_id: "${client}"
            )
            {
                id
                details
                display_name
                hardware_collection_id
                software_collection_id
            }
    }
    `;
    console.log(addRunConfig);
    API.graphql({ query: addRunConfig, variables: { details: JSON.stringify(detailsField)} })
      .then((e) => {
        console.log(e);
        fetchData();
      });

  }

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Id</th>
            <th>Display Name</th>
            <th>Details</th>
            <th>Software Collection</th>
            <th>Hardware Collection</th>
            <th>Created At</th>
            <th>Updated At</th>
            {/* <th style={{ 'minWidth': '185px' }}>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {runConfigs.map((runConfig, index) => {
            const softwareCollection = softwareCollections.find(s=> s.id === runConfig.software_collection_id);
            const hardwareCollection = hardwareCollections.find(s=> s.id === runConfig.hardware_collection_id);
            return (
              <tr key={"runconfig-" + runConfig.id}>
                <td>{runConfig.id}</td>
                <td>{runConfig.display_name}</td>
                <td>{JSON.stringify(JSON.parse(runConfig.details), null, "\t")}</td>
                <td>{softwareCollection?softwareCollection.display_name:''}</td>
                <td>{hardwareCollection?hardwareCollection.display_name:''}</td>
                <td>{runConfig.created_at}</td>
                <td>{runConfig.updated_at}</td>
                {/* <td>
                  <Button
                    variant="info"
                    onClick={() => gotToDetails(vehicle.vehicle_id)}
                  >
                    Details
                  </Button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Form>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Add new RunConfig:</Card.Header>
              <Card.Body>
              { clients.length > 0 && <InputGroup className="mb-3">
                  <InputGroup.Text id="HardwareCollectionId">Client</InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleClientChange}>
                    <option defaultValue={true}>[Select a Client]</option>
                    {
                      clients.map(s =>
                        <option value={s.id} key={s.id}>
                          {s.client_name}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup> }   
                <InputGroup className="mb-3">
                  <InputGroup.Text id="displayName">Display Name</InputGroup.Text>
                  <FormControl
                    aria-describedby="displayName"
                    value={displayName}
                    onChange={handleDisplayNameChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="softwareCollectionId">Software Collection</InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleSoftwareCollectionChange}>
                    <option defaultValue={true}>[Select a Software Collection]</option>
                    {
                      softwareCollections.map(s =>
                        <option value={s.id} key={s.id}>
                          {s.display_name}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="HardwareCollectionId">Hardware Collection</InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleHardwareCollectionChange}>
                    <option defaultValue={true}>[Select a Hardware Collection]</option>
                    {
                      hardwareCollections.map(s =>
                        <option value={s.id} key={s.id}>
                          {s.display_name}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>
               
                 
                <InputGroup>
                  <InputGroup.Text>Details</InputGroup.Text>
                  <FormControl
                    as="textarea"
                    aria-label="{JSON}"
                    value={JSON.stringify(detailsField)}
                    onChange={handleDetailsChange}
                    rows={3}
                  />
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={onAddNewClick}>
                  Add New
                </Button>
              </Card.Footer>
            </Card>

          </Col>
        </Row>
      </Form>

    </>
  );
};

const RunConfigs = () => (
  <Container>
    <h1>Run Configurations</h1>
    <RunConfigsInner />
  </Container>
);

export default RunConfigs;
