import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import {
  formatDate,
  getWorkstationStatusFromEnum,
  getWorkstationActionFromEnum,
  getWorkstationActionDescFromEnum,
  formatDateTime,
  getAVModeFromEnum,
  getVehicleStatusFromEnum,
  roundDecimal,
  getVehicleTeleopStatusFromEnum,
} from "../../lib/Extensions";
import WorkstationStatus from "./workstationStatus";
import WorkstationActionIcon from "./WorkstationActionIcon";
import { getDecisionEngine } from "../../utils/helper";
import {
  Button,
  Container,
  Col,
  Row,
  Card,
  ListGroup,
  Placeholder,
  Badge,
  Tab,
  Tabs,
  Table,
  Alert,
  ListGroupItem,
  Pagination,
  Form
} from "react-bootstrap";
import MapComponent from "../maps/MapComponent";
const axios = require("axios").default;

const WorkstationDetailsInner = ({ children }) => {
  //#region Definitions

  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);

  const [workstationDetails, setWorkstationDetails] = useState([]);
  const [workstationLogs, setWorkstationLogs] = useState([]);
  const [workstationHistory, setWorkstationHistory] = useState([]);
  const [workstationSessions, setWorkstationSessions] = useState([]);
  const [logsTotalCount, setLogsTotalCount] = useState(0);
  const [workstationErrorsOnly, setWorkstationErrorsOnly] = useState(false);
  const [logsPaginationItems, setLogsPaginationItems] = useState([]);

  const [webSocket, setWebSocket] = useState(null);
  const [connectedVehicle, setConnectedVehicle] = useState(null);
  const [sessionMetrices, setSessionMetrices] = useState([]);
  const [sessionErrorCount, setSessionErrorCount] = useState(0);
  //#endregion

  let currentPage = 1;
  const pageLimit = 10;

  //#region Actions

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    // fetchWorkstationLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workstationErrorsOnly]);

  async function onPageItemClick(index) {
    currentPage = Number(index);
    await fetchWorkstationLogs();
  }

  const fetchWorkstationLogs = async (errors_only = false) => {
    setWorkstationErrorsOnly(errors_only)
    const workstationId = query.get("workstationId");
    const workstationQuery = `
    {
      workstationLogs (workstation_id: "${workstationId}", errors_only: ${errors_only}, limit: ${pageLimit}, nextToken: "${currentPage === 1 ? "" : currentPage}",) 
      {     
        items {  
            id
            workstation_id
            log_time
            status
            error_code
        }
        nextToken
        total
      }
    }`;

    const apiData = await API.graphql(graphqlOperation(workstationQuery));
    const workstationLogs = apiData.data.workstationLogs.items;
    const total = apiData.data.workstationLogs.total;
    const nextToken = apiData.data.workstationLogs.nextToken;
    setWorkstationLogs(workstationLogs);
   

    setLogsTotalCount(total);
    currentPage = nextToken;

    let items = [];
    let active = nextToken - 1;
    const totalPages = Math.ceil(total / pageLimit);

    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => onPageItemClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    setLogsPaginationItems(items);
  }

  const onRefreshClick = () => {
    if (webSocket !== null) {
      webSocket.close();
    }
    fetchDetails();
  };

  const onActionClick = async (action) => {
    try {
      const url = `https://${process.env.REACT_APP_API_GATEWAY_URL}/${process.env.REACT_APP_SERVERLESS_STAGE}/workstation/${workstationDetails.id}/${action}`;
      let config = {
        headers: {
          "x-api-key": process.env.REACT_APP_APIKEY,
          "Content-Type": "application/json",
        },
        statusCode: 200,
      };
      await axios
        .post(url, {}, config)
        .then(async (res) => {
          fetchDetails();
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  //#endregion

  //#region Queries

  async function fetchDetails() {
    setConnectedVehicle(null);
    setSessionMetrices([]);

    let hasAccessV = false;
    await getDecisionEngine("hub---view-vehicle-data").then(
      async (mainResponse) => {
        if (mainResponse.outputValue === "0") {
          document.getElementById(
            "api-result"
          ).innerHTML = `<div class='red'>User is not allowed to access the list of workstations.</div>`;
        } else if (mainResponse.outputValue === "1") {
          hasAccessV = true;
          setHasAccessToEdit(hasAccessV);
        } else if (mainResponse.outputValue === "2") {
          document.getElementById(
            "api-result"
          ).innerHTML = `<div class='red'>User is not allowed to access the list of workstations.</div>`;
        } else {
          document.getElementById(
            "api-result"
          ).innerHTML = `<div class='red'>Error, No User data found.</div>`;
        }
      }
    );
    if (!hasAccessV) {
      return;
    }

    const workstationId = query.get("workstationId");
    const workstationQuery = `
    {
      workstation(workstation_id: "${workstationId}") 
      {
        id
        workstation_name
        ip_address
        is_active
        updated_at
        created_at
        nb_vehicles
        nb_fleets
        workstation_status
        workstation_error
      }
      workstationSessionByWorkstation (workstation_id: "${workstationId}")
      {     
        id
        workstation_id
        vehicle_id
        start
        end
        errors_count
        registration_number
        vin
      }
      workstationHistory (workstation_id: "${workstationId}") {     
        id
        workstation_id
        vehicle_id
        fleet_id
        action_time
        history_action
        fleet_name
        vin
        registration_number
      }
    }`;

    const apiData = await API.graphql(graphqlOperation(workstationQuery));
    const workstationData = apiData.data.workstation;
    const workstationSessionByWorkstation = apiData.data.workstationSessionByWorkstation;
    const workstationHistoryData = apiData.data.workstationHistory;

    setWorkstationDetails(workstationData);
    setWorkstationHistory(workstationHistoryData);
    setWorkstationSessions(workstationSessionByWorkstation);
    await fetchWorkstationLogs();

    const activeSessions = workstationSessionByWorkstation.filter(
      (ww) => ww.end === null
    );
    if (activeSessions && activeSessions.length > 0) {
      const connectedVehicleId = activeSessions[0].vehicle_id;

      // GET VEHICLE DATA
      fetchSessionMetrices(connectedVehicleId);
      // Setup websocket
      setupWebsocket(connectedVehicleId);
    }
  }

  async function fetchSessionMetrices(vehicleId) {
    const vehicleQuery = `
    {
      vehicle (id: "${vehicleId}") {
        id
        vin
        registration_number
        client_id
        fleet_id
        created_at
        updated_at
      }
    }`;

    const vehicleApiData = await API.graphql(graphqlOperation(vehicleQuery));
    const vehicleData = vehicleApiData.data.vehicle;

    //console.log("Connected Vehicle: ", vehicleData);
    setConnectedVehicle(vehicleData);

    const query = `sessionMetricesByVehicle (VehicleId: "${vehicleId}") {
          VehicleId
          SessionId
          EventTimestamp
          Velocity
          BatteryLevel
          Longitude
          Latitude
          AVMode
          VehicleStatus
          VehicleTeleopStatus
          AverageSpeed
          LastUpdated
          TotalDistance
          TotalDistanceInAuto
          TotalDistanceInManual
      }
     
    `;
    const response = await API.graphql(graphqlOperation(`{ ${query} }`));
    const allSessionMetrices = response.data;

    let newMetrices = [];
    for (const property in allSessionMetrices) {
      const tempMetrices = allSessionMetrices[property][0];
      if (tempMetrices && tempMetrices.VehicleId) {
        newMetrices.push(tempMetrices);
      }
    }
    //console.log("Metrices: ", newMetrices);
    setSessionMetrices(newMetrices);
    const appsyncErrorQuery = `
    {
        session (id: "${newMetrices[0].SessionId}") 
        {
            id,
            vehicle_id,
            run_config_id,
            start,
            end,
            errors_count
        }
    }`;

    const sessionErrorsApiData = await API.graphql(
      graphqlOperation(appsyncErrorQuery)
    );
    const sessionErrorCount = sessionErrorsApiData.data.session.errors_count;
    setSessionErrorCount(sessionErrorCount);
  }

  const onBackClick = () => {
    history.push("/workstations");
  };

  //#endregion

  //#region Websocket

  function setupWebsocket(vehicleId) {
    
      const websocketEndPoint = `${process.env.REACT_APP_WEBSOCKET_HOST}/${process.env.REACT_APP_SERVERLESS_STAGE}`;
      const socket = new WebSocket(`wss://${websocketEndPoint}?vehicle=${vehicleId}`);

      // let latestEventTimestamp;
      // let latestAggregateTimestamp;

      socket.onmessage = (message) => {
        //console.log(`New message for channel: ${vehicleId} | ${new Date().toISOString()}`);

        const incomingData = JSON.parse(message.data);
        if (incomingData === null) return;

        //console.log('INCOMING > ', incomingData);

        let tempMetrices = {};

        if (incomingData.Type === 'startsession') {
          console.log('Start session received...');
          onRefreshClick();
          //fetchDetails();
          return;
        }

        if (incomingData.Type === 'endsession') {
          console.log('End session received...');
          //fetchDetails();
          onRefreshClick();
          return;
        }

        if (incomingData.Type === 'errors') {
          console.log('Errors count updated: ' + JSON.stringify(incomingData));
          setSessionErrorCount(incomingData.Content.errors_count);
          return;
        }

        let coordinatesRecord = { Longitude : '', Latitude: '', EventTimestamp: '' }
        for (let record of incomingData.Content) {

          //console.log('New record: ', record);

          if (incomingData.Type === 'telemetry') {

            // // if (latestEventTimestamp && record.EventTimestamp < latestEventTimestamp) continue;
            setSessionMetrices(oldMetrices => {
              let currentMetrices = oldMetrices.find(x => x.SessionId === incomingData.SessionId);
              if (currentMetrices != null) {
                if (record.MeasureUnit === 'XCU_HighVoltageSoc_Pc') {
                  currentMetrices.BatteryLevel = record.MeasureValue;
                } else if (record.MeasureUnit === 'XCU_Velocity_Ms') {
                  currentMetrices.Velocity = record.MeasureValue;
                } else if (record.MeasureUnit === 'ARB_Vehicle_Mode_Zs') {
                  currentMetrices.AVMode = record.MeasureValue;
                } else if (record.MeasureUnit === 'ARB_Vehicle_Status_Zs') {
                  currentMetrices.VehicleStatus = record.MeasureValue;
                } else if (record.MeasureUnit === 'ARB_TeleopConnStatus_Zs') {
                  currentMetrices.VehicleTeleopStatus = parseInt(record.MeasureValue);
                } else if (record.MeasureUnit === 'GPSIMU_PosLongitude_D') {
                  currentMetrices.Longitude = record.MeasureValue;
                  coordinatesRecord.Longitude = record.MeasureValue;
                  coordinatesRecord.EventTimestamp = record.EventTimestamp
                } else if (record.MeasureUnit === 'GPSIMU_PosLatitude_D') {
                  currentMetrices.Latitude = record.MeasureValue;
                  coordinatesRecord.Latitude = record.MeasureValue;
                  coordinatesRecord.EventTimestamp = record.EventTimestamp
                 }
              }
              return [...oldMetrices];
            });
          } else if (incomingData.Type === 'aggregate') {

            // if (!record.LastTimestamp) continue;

            if (record.AggregateField === 'AverageSpeed' && record.AggregateValue) {
              tempMetrices.AverageSpeed = record.AggregateValue;
              setSessionMetrices(oldMetrices => {
                let currentMetrices = oldMetrices.find(x => x.SessionId === record.SessionId);
                if (currentMetrices != null) {
                  currentMetrices.AverageSpeed = record.AggregateValue;
                }
                return [...oldMetrices];
              });
            }
          } else if (incomingData.Type === 'coordinates') {
            
          }
        }
        if(coordinatesRecord.Longitude && coordinatesRecord.Latitude) {
          setSessionMetrices(oldMetrices => {
            const currentMetrices = oldMetrices.find(x => x.SessionId === incomingData.SessionId);
            console.log(currentMetrices)
            if (currentMetrices && coordinatesRecord.Longitude && coordinatesRecord.Latitude) {
              currentMetrices.Longitude = coordinatesRecord.Longitude;
              currentMetrices.Latitude = coordinatesRecord.Latitude;
            }
            return [...oldMetrices];
          });
        }
      }
    setWebSocket(socket);
  }

  //#endregion

  //#region UI Drawers

  const drawDisabled = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="gray"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
      </svg>
    );
  };

  // const getJourneyStatus = (journeyStatus) => {
  //   if (journeyStatus === "active") {
  //     return (
  //       <>
  //         <div style={{ color: "red" }}>
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="red"
  //             viewBox="0 0 16 16"
  //           >
  //             <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
  //           </svg>{" "}
  //           LIVE
  //         </div>
  //       </>
  //     );
  //   } else if (journeyStatus === "done") {
  //     return (
  //       <>
  //         <div style={{ color: "green" }}>
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="green"
  //             viewBox="0 0 16 16"
  //           >
  //             <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
  //             <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
  //           </svg>{" "}
  //           COMPLETED
  //         </div>
  //       </>
  //     );
  //   }
  // };

  const getActionButton = () => {
    if (workstationDetails.workstation_status === 0) {
      return (
        <>
          <Button variant="success" onClick={() => onActionClick("start")}>
            Start
          </Button>
        </>
      );
    } else if (workstationDetails.workstation_status === 1) {
      return (
        <>
          <Button
            variant="primary"
            disabled={workstationDetails.workstation_status > 1}
            onClick={() => onActionClick("stop")}
          >
            Stop
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button variant="secondary" disabled={true}>
            N\A
          </Button>
        </>
      );
    }
  };
  const getAlertMessage = () => {
    const getVariant = () => {
      if (hasAccessToEdit === null) return "secondary";
      if (hasAccessToEdit === true) return "success";
      return "danger";
    };
    return (
      <Alert key="alert-message" variant={getVariant()}>
        {hasAccessToEdit === null && <>Checking permissions...</>}
        {hasAccessToEdit !== null && (
          <>
            This user {hasAccessToEdit ? "have" : "don't have"} access to
            view\edit worksation's details
          </>
        )}
      </Alert>
    );
  };
  //#endregion

  //#region redirects
  const gotToErrors = (sessionId, vehicleId) => {
    window.open( `/journeyErrors?journeyId=${sessionId}&vehicleId=${vehicleId}`, "_blank")
  };

  const gotToDetails = (vehicleId) => {
    window.open( `/vehicleDetails?vehicleId=${vehicleId}`, "_blank")
  };

  const gotToJourneys = (vehicleId) => {
    window.open( `/vehicleJourneys?vehicleId=${vehicleId}`, "_blank")
  };

  //#endregion

  return (
    <>
      <Row>
        <h1>Workstation Details</h1>
        <br />
        <Col>{getAlertMessage()}</Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="3">
          <Button variant="primary" onClick={onBackClick}>
            Back to Workstations
          </Button>{" "}
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          {!workstationDetails.workstation_name ? (
            <Card>
              <Card.Title>
                <Placeholder as={Card.Title} animation="wave">
                  <Placeholder xs={6} bg="success" />
                </Placeholder>
              </Card.Title>
              <Card.Body>
                <Placeholder animation="wave">
                  <Placeholder xs={5} />
                </Placeholder>
                <br />
                <Placeholder animation="wave">
                  <Placeholder xs={4} bg="success" />
                </Placeholder>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <Placeholder animation="wave">
                    <Placeholder xs={2} bg="primary" />
                  </Placeholder>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Placeholder animation="wave">
                    <Placeholder xs={2} bg="primary" />
                  </Placeholder>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Placeholder animation="wave">
                    <Placeholder xs={2} bg="warning" />
                  </Placeholder>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          ) : (
            <Card>
              <Card.Header>
                <h3>{workstationDetails.workstation_name}</h3>
              </Card.Header>
              <Card.Body>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "500px" }}>
                    <Card.Subtitle>
                      IP Address: <b>{workstationDetails.ip_address}</b>
                    </Card.Subtitle>
                    <br />
                    <Card.Subtitle className="mb-2 text-muted">
                      <i>
                        Created At: {formatDate(workstationDetails.created_at)}
                      </i>
                    </Card.Subtitle>
                  </div>
                </div>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  No. Fleets:{" "}
                  <Badge bg="primary" pill>
                    <div>
                      {workstationDetails.nb_fleets
                        ? workstationDetails.nb_fleets
                        : "0"}
                    </div>
                  </Badge>
                </ListGroup.Item>
                <ListGroup.Item>
                  No. Vehicles:{" "}
                  <Badge bg="primary" pill>
                    <div>
                      {workstationDetails.nb_vehicles
                        ? workstationDetails.nb_vehicles
                        : "0"}
                    </div>
                  </Badge>
                </ListGroup.Item>
                <ListGroup.Item>
                  Error Code:{" "}
                  {workstationDetails.workstation_error > 0 ? (
                    <Badge bg="danger" pill>
                      {/* <div>{workstationDetails.workstation_error}</div> */}
                      ERROR
                    </Badge>
                  ) : (
                    ""
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          )}
        </Col>
        <Col md="2">
          <Card>
            <Card.Header>
              <h3 style={{ color: "gray" }}>Status</h3>
            </Card.Header>
            <Card.Body>
              <div>
                <WorkstationStatus
                  status={workstationDetails.workstation_status}
                  withText={true}
                ></WorkstationStatus>
              </div>
            </Card.Body>
            <Card.Footer>
              <div style={{ textAlign: "center" }}>{getActionButton()}</div>
            </Card.Footer>
          </Card>
        </Col>
        <Col md="4">
          {connectedVehicle === null ? (
          <Card border="secondary">
              <Card.Header>
              <h4>
                No Connected Vehicle
              </h4>
            </Card.Header>
            <Card.Body>
              <Alert variant='success'>
                No data to display !
              </Alert>
            </Card.Body>
          </Card>) : 
          (<Card border="success" style={{ minHeight: "300px" }}>
            <Card.Header>
              <h4>
                {connectedVehicle.registration_number}
              </h4>
            </Card.Header>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                AV Mode:{" "}
                <b>
                  {sessionMetrices[0] && sessionMetrices[0].AVMode >= 0
                    ? sessionMetrices[0].AVMode === "restricted"
                      ? drawDisabled()
                      : getAVModeFromEnum(sessionMetrices[0].AVMode)
                    : ""}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Status:{" "}
                <b>
                  {sessionMetrices[0] && sessionMetrices[0].VehicleStatus >= 0
                    ? sessionMetrices[0].VehicleStatus === "restricted"
                      ? drawDisabled()
                      : getVehicleStatusFromEnum(
                          sessionMetrices[0].VehicleStatus
                        )
                    : ""}
                </b>
              </ListGroupItem>

              <ListGroupItem>
                Battery Level:{" "}
                <b>
                  {sessionMetrices[0] && sessionMetrices[0].BatteryLevel
                    ? sessionMetrices[0].BatteryLevel === "restricted"
                      ? drawDisabled()
                      : `${sessionMetrices[0].BatteryLevel} %`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Current Speed:{" "}
                <b>
                  {sessionMetrices[0] && sessionMetrices[0].Velocity
                    ? sessionMetrices[0].Velocity === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(sessionMetrices[0].Velocity, 3)} Km/h`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Average Speed:{" "}
                <b>
                  {sessionMetrices[0] && sessionMetrices[0].AverageSpeed
                    ? sessionMetrices[0].AverageSpeed === "restricted"
                      ? drawDisabled()
                      : `${roundDecimal(
                          sessionMetrices[0].AverageSpeed,
                          3
                        )} Km/h`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                TeleOp Status:{" "}
                <b>
                  {sessionMetrices[0] &&
                  sessionMetrices[0].VehicleTeleopStatus >= 0
                    ? `${getVehicleTeleopStatusFromEnum(
                        sessionMetrices[0].VehicleTeleopStatus
                      )}`
                    : "N/A"}
                </b>
              </ListGroupItem>
              <ListGroupItem>
                Errors: <b>{sessionErrorCount}</b>
              </ListGroupItem>
            </ListGroup>
            <Card.Footer>
              <Button
                variant="danger"
                disabled={sessionErrorCount === 0}
                onClick={() =>
                  gotToErrors(sessionMetrices[0].SessionId, connectedVehicle.id)
                }
              >
                View Errors
              </Button>
              <span>&nbsp; </span>
              <Button
                variant="info"
                onClick={() => gotToDetails(connectedVehicle.id)}
              >
                Details
              </Button>
              <span>&nbsp; </span>
              <Button
                variant="success"
                onClick={() => gotToJourneys(connectedVehicle.id)}
              >
                Journeys
              </Button>
            </Card.Footer>
          </Card>)}
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={8}>
          <Tabs
            defaultActiveKey="sessions"
            id="workstation-tabs"
            className="mb-3"
          >
            <Tab eventKey="sessions" title="Vehicle Sessions">
              <Table striped bordered hover>
                <thead>
                  <tr key="header">
                    <th></th>
                    <th>Vehicle</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Error Count</th>
                  </tr>
                </thead>
                <tbody>
                  {workstationSessions.map((session, index) => {
                    return (
                      <tr key={"session-" + session.id}>
                        <td align="center" valign="middle">
                          {" "}
                          <WorkstationStatus
                            status={session.end === null ? 11 : 10}
                            size="24"
                            withText={false}
                          ></WorkstationStatus>
                        </td>
                        <td>{session.vin}</td>
                        <td>{formatDateTime(session.start)}</td>
                        <td>{formatDateTime(session.end)}</td>
                        <td>
                          {session.errors_count === 0 ? (
                            ""
                          ) : (
                            <Badge bg="danger" pill>
                              {session.errors_count}
                            </Badge>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="history" title="History">
              <Table striped bordered hover>
                <thead>
                  <tr key="header">
                    <th></th>
                    <th>Action</th>
                    <th>Fleet Name</th>
                    <th>Vehicle</th>
                    <th>Log Time</th>
                  </tr>
                </thead>
                <tbody>
                  {workstationHistory.map((history, index) => {
                    return (
                      <tr key={"history-" + history.id}>
                        <td align="center" valign="middle">
                          {" "}
                          <WorkstationActionIcon
                            status={getWorkstationActionDescFromEnum(
                              history.history_action
                            )}
                            size="24"
                            withText={false}
                          ></WorkstationActionIcon>
                        </td>
                        <td>
                          {getWorkstationActionFromEnum(history.history_action)}
                        </td>
                        <td>{history.fleet_name}</td>
                        <td>{history.registration_number}</td>
                        <td>{formatDateTime(history.action_time)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="logs" title={`Logs (${logsTotalCount})`}>
            <Form.Check 
              type="switch"
              id="custom-switch"
              label="Only Errors"
              className="error-only-checkbox"
              onChange={ () => { 
                fetchWorkstationLogs(!workstationErrorsOnly)
              }
             
            }
            />
              <Table striped bordered hover>
                <thead>
                  <tr key="header">
                    <th></th>
                    <th>Status</th>
                    <th>Log Time</th>
                    <th>Error Code</th>
                  </tr>
                </thead>
                <tbody>
                  {workstationLogs.map((log, index) => {
                    return (
                      <tr key={"log-" + log.id}>
                        <td align="center" valign="middle">
                          {" "}
                          <WorkstationStatus
                            status={log.status}
                            size="24"
                            withText={false}
                          ></WorkstationStatus>
                        </td>
                        <td>{getWorkstationStatusFromEnum(log.status)}</td>
                        <td>{formatDateTime(log.log_time)}</td>
                        <td>
                          {log.error_code === 0 ? (
                            ""
                          ) : (
                            <Badge bg="warning" pill>
                              {log.error_code}
                            </Badge>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination>{logsPaginationItems}</Pagination>
            </Tab>
          </Tabs>
        </Col>
        <Col md={4}>
          <Tabs id="map-tabs" defaultActiveKey="map-tab" className="mb-3">
            <Tab title="Map" eventKey="map-tab">
              <Card border="success" style={{ height: "300px" }}>
                <MapComponent
                  mainPoints={sessionMetrices.map((m) => {
                    //console.log('MAP: > ', m);
                    return {
                      info: m,
                      coords: { Longitude: m.Longitude, Latitude: m.Latitude },
                      vehicleInfo: connectedVehicle,
                    };
                  })}
                ></MapComponent>
              </Card>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

const WorkstationDetails = () => (
  <Container>
    <WorkstationDetailsInner />
  </Container>
);

export default WorkstationDetails;
