const { DateTime } = require("luxon");
const { AVModeEnum, VehicleStatusEnum, VehicleTeleopStatusEnum, WorkstationStatusEnum, WorkstationActionEnum } = require('./Enumerations')

const getDuration = function (start, end) {
    if (!start || !end) return '';

    const startTime = new Date(start);
    const endTime = new Date(end);
    let diff = endTime - startTime;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const mins = Math.floor(diff / (1000 * 60));
    diff -= mins * (1000 * 60);

    const seconds = Math.floor(diff / (1000));
    diff -= seconds * (1000);

    return (days > 0 ? days + " d, " : "") +
        (hours > 0 ? hours + " h, " : "") +
        (mins > 0 ? mins + " min, " : "") +
        (seconds > 0 ? seconds + " sec" : "");
    // console.log(diff);
    // if (!diff || diff < 0) return "";
    // const duration = Duration.fromMillis(diff * 1000);
    // return duration.normalize().toObject();
}

const formatDateTime = function (dateTime) {
    const dateObject = DateTime.fromISO(dateTime);
    if (!dateTime) return "";
    return dateObject.toFormat("dd/MM/yyyy HH:mm:ss");
}

const formatDate = function (dateTime) {
    const dateObject = DateTime.fromISO(dateTime);
    if (!dateTime) return "";
    return dateObject.toFormat("dd/MM/yyyy");
}

const getAVModeFromEnum = function (avModeInt) {
    const matching = AVModeEnum.find(a => a.value === avModeInt);
    if (matching)
        return matching.displayName;
    else
        return "N/A";
}

const getVehicleStatusFromEnum = function (statusInt) {
    const matching = VehicleStatusEnum.find(a => a.value === statusInt);
    if (matching)
        return matching.displayName;
    else
        return "N/A";
}

const getVehicleTeleopStatusFromEnum = function (statusInt) {
    const matching = VehicleTeleopStatusEnum.find(a => a.value === statusInt);
    if (matching)
        return matching.displayName;
    else
        return "N/A";
}

const getWorkstationStatusFromEnum = function (statusInt) {
    const matching = WorkstationStatusEnum.find(a => a.value === statusInt);
    if (matching)
        return matching.displayName;
    else
        return "N/A";
}

const getWorkstationStatusEnum = function (statusInt) {
    const matching = WorkstationStatusEnum.find(a => a.value === statusInt);
    if (matching)
        return matching.enum;
    else
        return "N/A";
}

const getWorkstationActionFromEnum = function (statusInt) {
    const matching = WorkstationActionEnum.find(a => a.value === statusInt);
    if (matching)
        return matching.displayName;
    else
        return "N/A";
}

const getWorkstationActionDescFromEnum = function (statusInt) {
    const matching = WorkstationActionEnum.find(a => a.value === statusInt);
    if (matching)
        return matching.enum;
    else
        return "N/A";
}

const roundDecimal = function (num, places) {
    num = parseFloat(num);
    places = (places ? parseInt(places, 10) : 0)
    if (places > 0) {
        let length = places;
        places = "1";
        for (let i = 0; i < length; i++) {
            places += "0";
            places = parseInt(places, 10);
        }
    } else {
        places = 1;
    }
    return Math.round((num + Number.EPSILON) * (1 * places)) / (1 * places)
}

module.exports = {
    getDuration,
    roundDecimal,
    formatDate,
    formatDateTime,
    getAVModeFromEnum,
    getVehicleStatusFromEnum,
    getVehicleTeleopStatusFromEnum,
    getWorkstationStatusEnum,
    getWorkstationStatusFromEnum,
    getWorkstationActionFromEnum,
    getWorkstationActionDescFromEnum
};