export const payload = {
    
    "XCU_HighVoltageSoc_Pc":100,
    "XCU_Velocity_Ms":15.2,
    "ARB_Vehicle_Mode_Zs":0,
    "ARB_Vehicle_Status_Zs":0,
    "ARB_TeleopConnStatus_Zs": 1,
    "GPSIMU_PosLongitude_D":-1.272408,
    "GPSIMU_PosLatitude_D":51.747640,

    "ARB_ErrorCode_Final_Z": 0,

    "HUB_WeatherCondition_S": null,
    "HUB_RoadCondition_S": null,
    "HUB_IsDay_B": true,
    "HUB_Visibility_S": null,
    "HUB_Temperature_Z": null,
     
    "XCU_AutoGranted_B":1,
    "XCU_AutoAvail_B":1,
    "XCU_PRNDL_Pos_Zs":255,
    "XCU_Prndl_Req_Zs":255,
    "XCU_IndRight_Status_B":1,
    "XCU_IndLeft_Status_B":1,
    "XCU_Horn_Status_B":1,
    "XCU_HeadlightSide_Status_B":1,
    "XCU_HeadlightMain_Status_B":1,
    "XCU_HeadlightDipped_Status_B":1,
    "XCU_IndRight_Req_B":1,
    "XCU_IndLeft_Req_B":1,
    "XCU_Horn_Req_B":1,
    "XCU_HeadlightSide_Req_B":1,
    "XCU_HeadlightMain_Req_B":1,
    "XCU_HeadlightDipped_Req_B":1,
    "XCU_HazardLight_Req_B":1,
    "XCU_BrakeLight_Status_B":1,
    "XCU_ReverseLight_Status_B":1,
    "TELEOP_MSM_Req_B":1,
    "TELEOP_ClearError_Req_B":1,
    "TELEOP_SteerSetpoint_Req_Pc":100,
    "TELEOP_TorqueSetpoint_Req_Pc":100,
    "TELEOP_GO_Req_B":1,
    "TELEOP_GO_Confirm_B":1,
    "TELEOP_ModeSwitch_B":1,
    "TELEOP_Prndl_Req_Zs":255,
    "TELEOP_HazardLights_Cmd_B":1,
    "TELEOP_MainHeadlight_Req_B":1,
    "TELEOP_DippedHeadlight_Req_B":1,
    "TELEOP_SideHeadlight_Req_B":1,
    "TELEOP_IndLeft_Req_B":1,
    "TELEOP_IndRight_Req_B":1,
    "TELEOP_Horn_Req_B":1,
    "XCU_Error_Code_Z":2,
    "TELEOP_ErrorCode_Z":65535,
    "ARB_InternalErrorCode_Z":65535,
    "ASLAN_ErrorCode_Z":65535,
    "XCU_EndToEndLatency_Z":65535,
    "ARB_TeleopConnected_B":1,
    "ARB_NwkLatency_MillSec":65535,
    "XCU_TeleopCommsOk_B":1,
    "ARB_NwkLatAboveThresh_B":1,
    "ASLAN_IndLeft_Req_B":1,
    "ASLAN_IndRight_Req_B":1,
    "ASLAN_Velocity_Req_Ms":655.35,
    "ASLAN_Yaw_Req_Rads":327.67,
    "ASLAN_OprApproval_Req_B":1,
    "ASLAN_ObjectDetected_B":1,
    "ARB_NoGo_Flag_B":1,
    "ARB_Go_Confirmed_B":1,
    "ARB_TeleopMode_B":1,
    "ARB_AutoMode_B":1,
    "ARB_GoNoGoHalted_B":1,
    "ARB_Velocity_Req_Ms":655.35,
    "ARB_Yaw_Req_Rads":327.67,
    "ARB_VelocityMax_Lim_Ms":655.35,
    "ARB_Control_Req_B":1,
    "ARB_Steer_Req_Pc":100,
    "ARB_Torque_Req_Pc":100,
    "ARB_Control_Type_Zs":255,
    "ARB_GoReqReceived_B":1,
    "ARB_ArmedTeleop_B":1,
    "ARB_Prndl_Req_Zs":255,
    "ARB_HazardLights_Req_B":1,
    "ARB_IndRight_Req_B":1,
    "ARB_IndLeft_Req_B":1,
    "ARB_Horn_Req_B":1,
    "ARB_HeadlightSide_Req_B":1,
    "ARB_HeadlightMain_Req_B":1,
    "ARB_HeadlightDipped_Req_B":1,
    "GPSIMU_Heading_D":655.35,
    "GPSIMU_Accel_X":327.67,
    "GPSIMU_Accel_Y":327.67,
    "GPSIMU_Accel_Z":327.67,
    "GPSIMU_Velocity_Ms":655.35,
    "GPSIMU_AngularAccel_X":3276.7,
    "GPSIMU_AngularAccel_Y":3276.7,
    "GPSIMU_AngularAccel_Z":3276.7,
    "GPSIMU_TimeYear_Z":100,
    "GPSIMU_TimeMonth_Z":12,
    "GPSIMU_TimeDay_Z":31,
    "GPSIMU_TimeHour_Z":24,
    "GPSIMU_TimeMinute_Z":60,
    "GPSIMU_TimeSecond_Z":60,
    "GPSIMU_TimeHSecond_Z":100,
    "ARB_TimeYear_Z":100,
    "ARB_TimeMonth_Z":12,
    "ARB_TimeDay_Z":31,
    "ARB_TimeHour_Z":24,
    "ARB_TimeMinute_Z":60,
    "ARB_TimeSecond_Z":60,
    "ARB_TimeHSecond_Z":100,
    "XCU_BootOpen_B":1,
    "XCU_FLDoorOpen_B":1,
    "XCU_FRDoorOpen_B":1,
    "XCU_RLDoorOpen_B":1,
    "XCU_RRDoorOpen_B":1,
 };