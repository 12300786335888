import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl
} from "react-bootstrap";
import { getDecisionEngine, getClientProfile } from '../../utils/helper'
import { formatDate } from "../../lib/Extensions";
import axios from "@aws-amplify/storage/node_modules/axios";

const EditVehicleInner = () => {

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let vehicleId = params.get('VehicleId');

  const [clients, setClients] = useState([]);
  const [fleets, setFleets] = useState([]);
  
  const [fleetId, setFleetId] = useState('');
  const [clientId, setClientId] = useState('');
  const [vehicleVin, setVehicleVin] = useState('');
  const [vehicleReg, setVehicleReg] = useState('');
  const [vehicleFleet, setVehicleFleet] = useState('');
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [hasAccessToEditFleet, setHasAccessToEditFleet] = useState(false);

  const handleVehicleVinChange = (e) => { setVehicleVin(e.target.value) }
  const handleVehicleRegChange = (e) => { setVehicleReg(e.target.value) }
  const handleClientChange = (e) => { setClientId(e.target.value) } 
  const handleFleetChange = (e) => { setFleetId(e.target.value) } 
  const onEditClick = (e) => { saveVehicle() }

  const onRefreshClick = () => { fetchData(); };

  const getVehicleData = async () => {
    const access = await getDecisionEngine('hub---create-a-vehicle').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to edit vehicles.</div>`
        return false;
      }
      return true
    });
    setHasAccessToEdit(access)
    if (!access) return

    await getDecisionEngine("hub---create-a-fleet").then(
      async (mainResponse) => {
        if (mainResponse.outputValue === "0") {
          setHasAccessToEditFleet(false);
        } else {
          setHasAccessToEditFleet(true);
        }
      }
    );

    const clientQuery = `
        {
          clients {
            id
            client_name
          }
        }`;
    let tempClientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to edit/view details vehicles.</div>`
        tempClientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access vehicles for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          tempClientId = res.client
          document.getElementById('api-result').innerHTML = `<div class='yellow'>User has privilege to access vehicles for ${tempClientId} client.</div>`
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        tempClientId = "-1"
      }
    });

    if (tempClientId === "-1") {
      return
    }

    //tempClientId = 'nissan-001'
    //console.log(tempClientId);

    setClientId(tempClientId);
   
    const vehicleQuery = `{
            vehicle (id: "${vehicleId}") {
                id
                vin
                fleet_id
                client_id
                registration_number
                created_at
                updated_at
              }
          }`
    const fleet = await API.graphql(graphqlOperation(vehicleQuery));
    setVehicleVin(fleet.data.vehicle.vin)
    setVehicleReg(fleet.data.vehicle.registration_number)
    setVehicleFleet(fleet.data.vehicle.fleet_id)
    setClientId(fleet.data.vehicle.client_id)
    await fetchFleetData(fleet.data.vehicle.client_id, access)

    return fleet.data.vehicle
  }
  
  async function saveVehicle() {
    document.getElementById('api-result').innerHTML = ""
    const vehiclesQuery = `
    {
      listSessions (vehicleId: "${vehicleId}", limit: 5, nextToken: "NEXTTOKENVALUE") {
        items {
          id
          vehicle_id
          run_config_id
          start
          end
          errors_count
        }
        nextToken
        total
      }
      
      workstationForVehicle (vehicle_id: "${vehicleId}") {
        id
        workstation_name
        ip_address
        is_active
        updated_at
        created_at
        workstation_status
      }
    }`;

    const vehicleData = await API.graphql(
      graphqlOperation(
        vehiclesQuery
      )
    );
    const haveActiveSession = vehicleData.data.listSessions.items && vehicleData.data.listSessions.items.some(x => x.end === null);
    const isConnectedToWorkstation = vehicleData.data.workstationForVehicle;
    if(haveActiveSession){
      document.getElementById('api-result').innerHTML = `<div class='red'>Cannot edit Vehicle when it has an active journey.</div>`
      return
    } 
    if(isConnectedToWorkstation){
      document.getElementById('api-result').innerHTML = `<div class='red'>Cannot edit Vehicle when it is connected to workstation.</div>`
      return
    }

    const addVehicle = `
            mutation {
                editVehicle(vehicle_id : "${vehicleId}", vin : "${vehicleVin}", registration_number : "${vehicleReg}" , client_id : "${clientId}") 
                {
                  id
                  vin
                  fleet_id
                  client_id
                  registration_number
                }
            }`;
    API.graphql({ query: addVehicle })
      .then(async (e) => {
        await editVehicleFleet().then(res => {
          document.getElementById('api-result').innerHTML = `<div class='green'>Vehicle successfully changed.</div>`
          getVehicleData()
        })
      });

     
  }

  async function fetchData() {
    await getVehicleData();
  }

  async function fetchClientData() {
    if(!hasAccessToEdit) return
    const clientQuery = `
        {
            clients {
                id
                client_name
            }
        }`;
    let clientId = ''
    await getClientProfile().then(async res => {
      clientId = res.client
      setClientId(clientId)
      if (!clientId) {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
      }
    });
  }

  async function fetchFleetData(VehcileClientId, hasAccessToEdit) {
    setFleets([])
    if(!hasAccessToEdit) return
      
      const axiosOptions = {
        headers: {
          'x-api-key': `${process.env.REACT_APP_APIKEY}`,
        },
        withCredentials: (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") ? false : true
      }

      const fleetQuery = `
      {
        fleets (clientId: "${VehcileClientId}") {
          hubFleetData
          data {
              id
              fleet_name
              client_id
              created_at
              updated_at
              is_active
              nb_vehicles
              active_vehicles
          }  
        }
      }`;

      const apiData2 = await axios.post(
        `${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`,
        { query: fleetQuery, variables: {} },
        axiosOptions);
        if (apiData2.data && apiData2.data.data && apiData2.data.data.fleets && apiData2.data.data.fleets.data){
          setFleets(apiData2.data.data.fleets.data)
        } else {
          setFleets([])
        }
  }

  const editVehicleFleet = async () => {
    let vehicleFleetId = fleetId
    if(!vehicleFleetId) vehicleFleetId = '00000000-0000-0000-0000-000000000000'
    const addFleetVehicle = `
      mutation {
        addFleetVehicle(fleet_id: "${vehicleFleetId}", vehicle_id : "${vehicleId}") 
        {
          id
          vin
          registration_number
          fleet_id
          created_at
          updated_at
        }
    }`;
    API.graphql({ query: addFleetVehicle })
      
  }
  useEffect(() => {

    fetchClientData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchFleetData(clientId, hasAccessToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto">
          <div Style="display:inline; float:right; padding-right:15px">
            <Button variant="warning" onClick={onRefreshClick}>
              Refresh
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <Form>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5" className="col-12">
                <div Style="display:inline">Vehicle Information:</div>
              </Card.Header>
              <Card.Body>
              {clients.length > 0 && <InputGroup className="mb-3">
                  <InputGroup.Text >Client</InputGroup.Text>
                  <Form.Select aria-label="" id="client_id" value={clientId} key={clientId} onChange={handleClientChange}>
                    <option defaultValue={true}>[Select a Client]</option>
                    {
                      clients.map(s =>
                        <option value={s.id} key={s.id} >
                          {s.client_name}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>}
                <InputGroup className="mb-3">
                  <InputGroup.Text >Fleet</InputGroup.Text>
                  { hasAccessToEditFleet && <Form.Select aria-label="" id="fleet_id" value={fleetId} key={fleetId} onChange={handleFleetChange} >
                    <option defaultValue={true} value="" key="" >[Select a Fleet]</option>
                    {
                      fleets.map(s =>
                        <option value={s.id} key={s.id} >
                          {s.fleet_name}
                        </option>
                      )
                    }
                  </Form.Select> }
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="fleettext">VIN</InputGroup.Text>
                  <FormControl
                    aria-describedby="fleetName"
                    value={vehicleVin}
                    onChange={handleVehicleVinChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="fleettext">Registration Number</InputGroup.Text>
                  <FormControl
                    aria-describedby="fleetName"
                    value={vehicleReg}
                    onChange={handleVehicleRegChange}
                  />
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <div Style="display:inline; float:right; padding-right:15px">
                  { hasAccessToEdit && <Button variant="primary" onClick={onEditClick}>
                    Save
                  </Button> }
                </div>
              </Card.Footer>
            </Card>

          </Col>
        </Row>
      </Form>
      <br />
    </>
  );
};

const EditVehicle = () => (
  <Container>
    <h1>Edit Vehicle</h1>
    <EditVehicleInner />
  </Container>
);

export default EditVehicle;
