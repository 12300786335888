import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";
import { getDecisionEngine, getClientProfile } from '../../utils/helper'
import axios from "@aws-amplify/storage/node_modules/axios";

const AddVehicleInner = () => {

  const [vinField, setVinField] = useState('');
  const [fleetId, setFleetId] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [clientId, setClientId] = useState('');
  const [clients, setClients] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [vehicleAccess, setVehicleAccess] = useState('');
  
  const handleVinChange = (e) => { setVinField(e.target.value) }
  const handleFleetIdChange = (e) => { setFleetId(e.target.value) }
  const handleClientChange = (e) => { setClientId(e.target.value) }
  const handleRegChange = (e) => { setRegistrationNumber(e.target.value) }

  const clientQuery = `
    {
      clients {
        id
        client_name
      }
    }`;
   

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchDataPerClient(clientId);
  };

  useEffect(() => {
    fetchDataPerClient(clientId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  

  async function fetchData() {
    setVinField('');
    setRegistrationNumber('');
    setVehicles([]);

    let clientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access vehicles data.</div>`
        clientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access vehicles data for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          clientId = res.client
          setClientId(clientId)
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        clientId = "-1"
      }
    });

    if (clientId === "-1") {
      return
    }

    await fetchDataPerClient(clientId)

    await getDecisionEngine('hub---create-a-vehicle').then(
      results => {
        setVehicleAccess(results.outputValue);
        console.log(JSON.stringify(results), 'vehicle access rule')
        if (results.outputValue === '0') {
          document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a vehicle.</div>`
        }
        else if (results.outputValue === '1' && clientId !== '') {
          document.getElementById('api-result').innerHTML = `<div class="yellow">User has partial privilege to create a vehicle only under ${clientId} client.</div>`
        }
      })

  }

  async function fetchDataPerClient(fetchedClient) {
        const mainQuery = `
        {
          vehicles (clientId: "${fetchedClient}") {
            id
            vin
            registration_number
            client_id
            fleet_id
            created_at
            updated_at
          }
        }`;
      const apiData = await API.graphql(graphqlOperation(mainQuery));
      const vehiclesData = apiData.data.vehicles;
      setVehicles(vehiclesData);
      
      const axiosOptions = {
        headers: {
          'x-api-key': `${process.env.REACT_APP_APIKEY}`,
        },
        withCredentials: (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") ? false : true
      }

      const fleetQuery = `
      {
        fleets (clientId: "${fetchedClient}") {
          hubFleetData
          data {
              id
              fleet_name
              client_id
              created_at
              updated_at
              is_active
              nb_vehicles
              active_vehicles
          }  
        }
      }`;

      const apiData2 = await axios.post(
        `${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`,
        { query: fleetQuery, variables: {} },
        axiosOptions);
       
        if (apiData2.data && apiData2.data.data && apiData2.data.data.fleets && apiData2.data.data.fleets.data){
          setFleets(apiData2.data.data.fleets.data.filter(x => x.is_active === true))
        }
  }
  const onAddNewClick = () => {
    if (vehicleAccess === '0') {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a new vehicle.</div>`
      return;
    }
    console.log('Sending api call:\nVIN: ', vinField, '\nRegistration NUmber: ', registrationNumber);
    if (fleets.length === 0){
      setFleetId('00000000-0000-0000-0000-000000000000')
    }
    const addVehicle = `
      mutation {
        addVehicle(input: {fleet_id: "${fleetId}", registration_number: "${registrationNumber}", vin: "${vinField}", client_id: "${clientId}"}) 
        {
            id
            fleet_id
            registration_number
            vin
            client_id
        }
    }  
    `;
    API.graphql({ query: addVehicle })
      .then((e) => {
        //console.log(e);
        fetchDataPerClient(clientId);
      });

  }

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Id</th>
            <th>VIN</th>
            <th>Registration Number</th>
            { clients.length > 0 && <th>Client Id</th> }
            <th>Fleet Id</th>
            <th>Created At</th>
            <th>Updated At</th>
            {/* <th style={{ 'minWidth': '185px' }}>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {vehicles.map((vehicle, index) => {
            return (
              <tr key={"vehicle-" + vehicle.id}>
                <td>{vehicle.id}</td>
                <td>{vehicle.vin}</td>
                <td>{vehicle.registration_number}</td>
                { clients.length > 0 && <td>{vehicle.client_id}</td> }
                <td>{vehicle.fleet_id}</td>
                <td>{vehicle.created_at}</td>
                <td>{vehicle.updated_at}</td>
                {/* <td>
                  <Button
                    variant="info"
                    onClick={() => gotToDetails(vehicle.vehicle_id)}
                  >
                    Details
                  </Button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Form>
        <Row>
          <Col>
            <Card>

              <Card.Header as="h5">Add new Vehicle:</Card.Header>
              <Card.Body>
                {
                  clients.length > 0 &&
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="ClientId">Client</InputGroup.Text>
                    <Form.Select aria-label="" onChange={handleClientChange}>
                      <option defaultValue={true}>[Select a Client]</option>
                      {
                        clients.map(s =>
                          <option value={s.id} key={s.id}>
                            {s.client_name}
                          </option>
                        )
                      }
                    </Form.Select>
                  </InputGroup>
                }
                <InputGroup className="mb-3">
                  <InputGroup.Text id="vin">VIN</InputGroup.Text>
                  <FormControl
                    aria-describedby="vin"
                    value={vinField}
                    onChange={handleVinChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="regNumber">Registration Number</InputGroup.Text>
                  <FormControl
                    aria-describedby="regNumber"
                    value={registrationNumber}
                    onChange={handleRegChange}
                  />
                </InputGroup>
                {fleets.length > 0 &&
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="fleetId">Fleet ID</InputGroup.Text>
                    <Form.Select aria-label="" onChange={handleFleetIdChange}>
                        <option defaultValue={true}>[Select a Fleet]</option>
                        {
                          fleets.map(s =>
                            <option value={s.id} key={s.id}>
                              {s.fleet_name}
                            </option>
                          )
                        }
                      </Form.Select>
                  </InputGroup>
                }
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={onAddNewClick}>
                  Add New
                </Button>
              </Card.Footer>
            </Card>

          </Col>
        </Row>
      </Form>

    </>
  );
};

const AddVehicle = () => (
  <Container>
    <h1>Add new vehicle</h1>
    <AddVehicleInner />
  </Container>
);

export default AddVehicle;
