export const sampleData = [
    { BatteryLevel: 100.0, VehicleStatus: 0, AVMode: 0, Velocity: 15.2, Latitude: 51.747640, Longitude: -1.272408, TeleopStatus: 1 },
    { BatteryLevel: 99.90, VehicleStatus: 0, AVMode: 1, Velocity: 15.3, Latitude: 51.747863, Longitude: -1.273297, TeleopStatus: 1 },
    { BatteryLevel: 99.50, VehicleStatus: 1, AVMode: 1, Velocity: 15.45, Latitude: 51.748098, Longitude: -1.274247, TeleopStatus: 0 },
    { BatteryLevel: 99.40, VehicleStatus: 1, AVMode: 2, Velocity: 16.9, Latitude: 51.748209, Longitude: -1.274646, TeleopStatus: 1 },
    { BatteryLevel: 99.10, VehicleStatus: 1, AVMode: 3, Velocity: 16.5, Latitude: 51.748305, Longitude: -1.275011, TeleopStatus: 5 },
    { BatteryLevel: 89.90, VehicleStatus: 1, AVMode: 3, Velocity: 16.4, Latitude: 51.748388, Longitude: -1.275306, TeleopStatus: 3 },
    { BatteryLevel: 89.70, VehicleStatus: 1, AVMode: 3, Velocity: 16.1, Latitude: 51.748552, Longitude: -1.275815, TeleopStatus: 1 },
    { BatteryLevel: 89.65, VehicleStatus: 1, AVMode: 4, Velocity: 15.8, Latitude: 51.748657, Longitude: -1.276253, TeleopStatus: 1 },
    { BatteryLevel: 89.43, VehicleStatus: 1, AVMode: 4, Velocity: 15.7, Latitude: 51.748817, Longitude: -1.276909, TeleopStatus: 3 },
    { BatteryLevel: 89.13, VehicleStatus: 1, AVMode: 4, Velocity: 15.4, Latitude: 51.748927, Longitude: -1.277333, TeleopStatus: 1 },
    { BatteryLevel: 88.83, VehicleStatus: 1, AVMode: 4, Velocity: 15.43, Latitude: 51.749041, Longitude: -1.277782, TeleopStatus: 1 },
    { BatteryLevel: 88.79, VehicleStatus: 1, AVMode: 4, Velocity: 15.3, Latitude: 51.749124, Longitude: -1.277876, TeleopStatus: 1 },
    { BatteryLevel: 88.79, VehicleStatus: 1, AVMode: 4, Velocity: 15.2, Latitude: 51.749286, Longitude: -1.277832, TeleopStatus: 3 },
    { BatteryLevel: 88.79, VehicleStatus: 1, AVMode: 4, Velocity: 15.09, Latitude: 51.749456, Longitude: -1.277710, TeleopStatus: 1 },
    { BatteryLevel: 88.62, VehicleStatus: 1, AVMode: 5, Velocity: 14.99, Latitude: 51.749586, Longitude: -1.277630, TeleopStatus: 1 },
    { BatteryLevel: 88.46, VehicleStatus: 1, AVMode: 5, Velocity: 14.85, Latitude: 51.749721, Longitude: -1.277537, TeleopStatus: 1 },
    { BatteryLevel: 88.38, VehicleStatus: 1, AVMode: 5, Velocity: 14.61, Latitude: 51.749983, Longitude: -1.277333, TeleopStatus: 0 },
    { BatteryLevel: 88.29, VehicleStatus: 1, AVMode: 6, Velocity: 14.33, Latitude: 51.750146, Longitude: -1.277172, TeleopStatus: 1 },
    { BatteryLevel: 88.17, VehicleStatus: 1, AVMode: 6, Velocity: 14.13, Latitude: 51.750385, Longitude: -1.276952, TeleopStatus: 0 },
    { BatteryLevel: 87.89, VehicleStatus: 1, AVMode: 6, Velocity: 13.88, Latitude: 51.750528, Longitude: -1.276822, TeleopStatus: 0 },
    { BatteryLevel: 87.69, VehicleStatus: 1, AVMode: 7, Velocity: 13.79, Latitude: 51.750735, Longitude: -1.276617, TeleopStatus: 1 },
    { BatteryLevel: 87.56, VehicleStatus: 1, AVMode: 6, Velocity: 13.44, Latitude: 51.750874, Longitude: -1.276487, TeleopStatus: 1 },
    { BatteryLevel: 87.36, VehicleStatus: 1, AVMode: 6, Velocity: 12.97, Latitude: 51.751075, Longitude: -1.276299, TeleopStatus: 1 },
    { BatteryLevel: 87.14, VehicleStatus: 2, AVMode: 6, Velocity: 12.40, Latitude: 51.751200, Longitude: -1.276220, TeleopStatus: 1 },
    { BatteryLevel: 86.56, VehicleStatus: 2, AVMode: 5, Velocity: 12.33, Latitude: 51.751273, Longitude: -1.276189, TeleopStatus: 1 },
    { BatteryLevel: 85.60, VehicleStatus: 2, AVMode: 5, Velocity: 11.77, Latitude: 51.751366, Longitude: -1.276152, TeleopStatus: 1 },
    { BatteryLevel: 81.50, VehicleStatus: 2, AVMode: 5, Velocity: 11.55, Latitude: 51.751519, Longitude: -1.276115, TeleopStatus: 1 },
    { BatteryLevel: 80.69, VehicleStatus: 2, AVMode: 5, Velocity: 11.22, Latitude: 51.751700, Longitude: -1.276057, TeleopStatus: 1 },
    { BatteryLevel: 77.30, VehicleStatus: 1, AVMode: 5, Velocity: 10.14, Latitude: 51.751796, Longitude: -1.276024, TeleopStatus: 1 },
    { BatteryLevel: 77.30, VehicleStatus: 2, AVMode: 5, Velocity: 9.77, Latitude: 51.752037, Longitude: -1.275974, TeleopStatus: 1 },
    { BatteryLevel: 77.20, VehicleStatus: 2, AVMode: 4, Velocity: 8.66, Latitude: 51.752266, Longitude: -1.275928, TeleopStatus: 1 },
    { BatteryLevel: 75.44, VehicleStatus: 2, AVMode: 4, Velocity: 7.35, Latitude: 51.752407, Longitude: -1.275897, TeleopStatus: 1 },
    { BatteryLevel: 70.60, VehicleStatus: 1, AVMode: 4, Velocity: 6.11, Latitude: 51.752389, Longitude: -1.276317, TeleopStatus: 1 },
    { BatteryLevel: 68.10, VehicleStatus: 1, AVMode: 4, Velocity: 6.99, Latitude: 51.752377, Longitude: -1.276725, TeleopStatus: 1 },
    { BatteryLevel: 63.10, VehicleStatus: 1, AVMode: 4, Velocity: 7.50, Latitude: 51.752373, Longitude: -1.276881, TeleopStatus: 1 },
    { BatteryLevel: 62.30, VehicleStatus: 1, AVMode: 4, Velocity: 7.88, Latitude: 51.752510, Longitude: -1.276881, TeleopStatus: 1 },
    { BatteryLevel: 61.33, VehicleStatus: 0, AVMode: 4, Velocity: 8.13, Latitude: 51.752722, Longitude: -1.276886, TeleopStatus: 1 },
    { BatteryLevel: 55.55, VehicleStatus: 1, AVMode: 4, Velocity: 9.78, Latitude: 51.753110, Longitude: -1.277156, TeleopStatus: 1 },
    { BatteryLevel: 55.43, VehicleStatus: 0, AVMode: 4, Velocity: 10.77, Latitude: 51.753325, Longitude: -1.277311, TeleopStatus: 1 },
    { BatteryLevel: 52.60, VehicleStatus: 0, AVMode: 4, Velocity: 10.98, Latitude: 51.753466, Longitude: -1.277398, TeleopStatus: 1 },
    { BatteryLevel: 50.25, VehicleStatus: 2, AVMode: 4, Velocity: 11.13, Latitude: 51.753597, Longitude: -1.277484, TeleopStatus: 1 },
    { BatteryLevel: 50.25, VehicleStatus: 1, AVMode: 3, Velocity: 11.28, Latitude: 51.753597, Longitude: -1.277584, TeleopStatus: 1 },
    { BatteryLevel: 50.25, VehicleStatus: 1, AVMode: 3, Velocity: 11.95, Latitude: 51.753597, Longitude: -1.277584, TeleopStatus: 1 },
    { BatteryLevel: 49.25, VehicleStatus: 1, AVMode: 2, Velocity: 12.0, Latitude: 51.753597, Longitude: -1.277584, TeleopStatus: 1 }
];