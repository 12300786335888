import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { formatDateTime } from "../../lib/Extensions";
import { getDecisionEngine, getClientProfile } from '../../utils/helper'
import axios from "@aws-amplify/storage/node_modules/axios";

const FleetsInner = ({ children }) => {

  let history = useHistory();
  const [fleets, setFleetValues] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const [fleetName, setFleetName] = useState('');
  const [hasAccess, setHasAccess] = useState(true);


  const handleClientChange = (e) => { setClientId(e.target.value) }
  const handleFleetNameChange = (e) => { setFleetName(e.target.value) }

  const clientQuery = `
    {
      clients {
        id
        client_name
      }
    }`;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchData();
  };

  async function fetchData() {

    setHasAccess(false);

    let clientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to edit/view details fleets.</div>`
        clientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        await getDecisionEngine('hub---create-a-fleet').then(async mainResponse => {
          if (mainResponse.outputValue === '0') {
            setHasAccess(false)
          } else {
            setHasAccess(true)
          }
        });
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access fleets for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getDecisionEngine('hub---create-a-fleet').then(async mainResponse => {
          if (mainResponse.outputValue === '0') {
            setHasAccess(false)
          } else {
            setHasAccess(true)
          }
        });
        await getClientProfile().then(async res => {
          clientId = res.client
          document.getElementById('api-result').innerHTML = `<div class='yellow'>User has privilege to access fleets for ${clientId} client.</div>`
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        clientId = "-1"
      }
    });

    if (clientId === "-1") {
      return
    }

    setClientId(clientId);
    setFleetValues([]);

    const fleetsQuery = `
    {
      fleets (clientId: "${clientId}")  {
        hubFleetData
        data {
            id
            fleet_name
            client_id
            created_at
            updated_at
            is_active
            nb_vehicles
            active_vehicles
        }  
      }
    }`;

    const axiosOptions = {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`,
      },
      withCredentials: (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") ? false : true
    }

    const apiData = await axios.post(`${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`, { query: fleetsQuery, variables: {} }, axiosOptions);
    const fleetsData = apiData.data.data.fleets.data;
    if (fleetsData === 'none') {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to view fleet List.</div>`
      return;
    }
    setFleetValues(fleetsData);
  }

  const gotToAddNew = (id) => {
    history.push("/addFleet");
  };

  const gotToDetails = (id) => {
    if (!hasAccess) {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to view fleet details.</div>`
      return;
    }
    history.push("/fleetDetails?fleetId=" + id);
  };

  const goToEdit = (id) => {
      if (!hasAccess) {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to edit a fleet.</div>`
        return;
     }
    history.push("/editFleet?fleetId=" + id);
  };
  const deleteFleet = async (id, name, client_id) => {
    if (!hasAccess) {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to disable a fleet.</div>`
      return;
    }
    if (window.confirm('Are you sure you want to delete this fleet ?\n' + name)) {

      console.log('deleting fleet...');

      const disableFleet = `
        mutation($id: ID!, $client_id: String!) {
          disableFleet(id: $id, client_id: $client_id) {
            id
            fleet_name
            client_id
            is_active
          }
        }
      `;

      API.graphql({ query: disableFleet, variables: { id: id, client_id: client_id } })
        .then((e) => {
          //console.log(e);
          onRefreshClick();
        });

    }
  };

  const onAddNewClick = () => {
    if (!hasAccess) {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a new fleet.</div>`
      return;
    }
    if (!fleetName) {
      document.getElementById('api-result').innerHTML = `<div class='red'>Fleet Name is required.</div>`
      return;
    }
    console.log('Sending api call:\nFleet Name: ', fleetName);

    const addFleet = `
      mutation($fleet_name: String!, $client_id: String!) {
        addFleet(fleet_name: $fleet_name, client_id: $client_id) 
        {
            id
            fleet_name
            is_active
            client_id
            created_at
            updated_at
        }
    }  
    `;
    API.graphql({ query: addFleet, variables: { fleet_name: fleetName, client_id: clientId } })
      .then((e) => {
        fetchData();
      });

  }

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="2">
          <Button variant="primary" onClick={gotToAddNew}>
            Add New
          </Button><span>{' '}</span>
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Fleet Id</th>
            <th>Fleet Name</th>
            <th>No. of Vehicles</th>
            <th>Created At</th>
            <th style={{ 'minWidth': '185px', 'maxWidth': '250px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {fleets.map((fleet, index) => {
            return (
              <tr key={"fleet-" + fleet.id} style={{ textDecoration: fleet.is_active ? "" : "line-through" }}>
                <td>{fleet.id}</td>
                <td>{fleet.fleet_name}</td>
                <td>{fleet.nb_vehicles}</td>
                <td>{formatDateTime(fleet.created_at)}</td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => gotToDetails(fleet.id)}
                    style={{ marginRight: '4px' }}
                  >
                    Details
                  </Button>
                  <Button
                    variant="success"
                    disabled={fleet.is_active && (fleet.client_id === clientId || clients.length > 0) ? '' : 'disabled'}
                    onClick={() => goToEdit(fleet.id)}
                    style={{ marginRight: '4px' }}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="danger"
                    disabled={fleet.is_active ? '' : 'disabled'}
                    onClick={() => deleteFleet(fleet.id, fleet.fleet_name, fleet.client_id)}
                  >
                    Disable
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Form>
        <Row>
          <Col>
          {hasAccess?
            <Card>
              <Card.Header as="h5">Add new Fleet:</Card.Header>
              <Card.Body>
                {
                  clients.length > 0 &&
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="ClientId">Client</InputGroup.Text>
                    <Form.Select aria-label="" onChange={handleClientChange}>
                      <option defaultValue={true}>[Select a Client]</option>
                      {
                        clients.map(s =>
                          <option value={s.id} key={s.id}>
                            {s.client_name}
                          </option>
                        )
                      }
                    </Form.Select>
                  </InputGroup>
                }
                <InputGroup className="mb-3">
                  <InputGroup.Text id="fleetName">Fleet Name:</InputGroup.Text>
                  <FormControl
                    aria-describedby="fleetName"
                    value={fleetName}
                    onChange={handleFleetNameChange}
                  />
                </InputGroup>

              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={onAddNewClick}>
                  Add New
                </Button>
              </Card.Footer>
            </Card>
            :''
          }
          </Col>
        </Row>
      </Form>
    </>
  );
};

const Fleets = () => (
  <Container>
    <h1>All Fleets</h1>
    <FleetsInner />
  </Container>
);

export default Fleets;
