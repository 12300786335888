import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Alert,
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";
import { getDecisionEngine } from "../../utils/helper";

const EditWorkstationInner = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let workstationId = params.get("workstationId");

  const [validated, setValidated] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);

  const [workstationName, setWorkstationName] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const [workstationIsActive, setWorksationIsActive] = useState(true);

  const [fleetIdToLink, setFleetIdToLink] = useState(null);
  const [worksationFleets, setWorksationFleets] = useState([]);
  const [allAvailableFleets, setAllAvailableFleets] = useState([]);

  const handleWorkstationNameChange = (e) => {
    setWorkstationName(e.target.value);
  };
  const handleIPAddressChange = (e) => {
    setIPAddress(e.target.value);
  };
  const handleSelectedFleetChange = (e) => {
    setFleetIdToLink(e.target.value);
  };

  const handleSubmit = async (event) => {
    setShowSuccess(false);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      await saveWorkstation();
    }
    setValidated(true);
  };

  const onRefreshClick = () => {
    fetchData();
  };

  const getWorksationData = async () => {
    const access = await getDecisionEngine("hub---create-a-workstation").then(
      async (mainResponse) => {
        if (mainResponse.outputValue === "0") {
          return false;
        }
        return true;
      }
    );
    
    setHasAccessToEdit(access);
    if (!access) return;

    const workstationQuery = `{
        workstation(workstation_id: "${workstationId}") {
          id
          workstation_name
          ip_address
          is_active
          updated_at
          created_at
          nb_vehicles
          nb_fleets
      }
    }`;

    const fleet = await API.graphql(graphqlOperation(workstationQuery));
    setWorkstationName(fleet.data.workstation.workstation_name);
    setIPAddress(fleet.data.workstation.ip_address);
    setWorksationIsActive(fleet.data.workstation.is_active);

    return fleet.data.fleet;
  };

  async function saveWorkstation() {
    const editQuery = `
      mutation {
        editWorkstation(
          workstation_id: "${workstationId}",
          workstation_name : "${workstationName}",
          ip_address: "${ipAddress}"
      ) 
      {
          id
          workstation_name
          ip_address
          is_active
          created_at
          updated_at
      }
    }`;
    API.graphql({ query: editQuery }).then(async (e) => {
      setShowSuccess(true);
      await getWorksationData();
    });
  }

  async function fetchData() {
    await getWorksationData();
  }

  const getWorkstationFleets = async () => {
    //if (!workstationId || !hasAccessToEdit) return;
    const workstationFleets = `
    {
      workstationFleets(workstation_id: "${workstationId}") {
        id
        fleet_name
        client_id
        is_active
        created_at
        updated_at
      }
    }`;
    const apiData = await API.graphql(graphqlOperation(workstationFleets));
    const workstationFleetsData = apiData.data.workstationFleets;
    setWorksationFleets(workstationFleetsData);

    const fleetsQuery = `
    {
      fleets {
        hubFleetData
        data {
            id
            fleet_name
            client_id
            created_at
            updated_at
            is_active
            nb_vehicles
            workstation_id
        }  
      }
    }`;
    const apiData2 = await API.graphql(graphqlOperation(fleetsQuery));
    const fleetsData = apiData2.data.fleets.data;
    const filteredFleets = fleetsData.filter((v) => v.workstation_id === null);

    setAllAvailableFleets(filteredFleets);
  };

  const onLinkFleet = async () => {
    const addWorkstationFleet = `
      mutation {
        addWorkstationFleet(workstation_id: "${workstationId}", fleet_id : "${fleetIdToLink}") 
        {
            id
            fleet_name
            client_id
            is_active
            created_at
            updated_at
        }
    
    }`;
    API.graphql({ query: addWorkstationFleet }).then(async (e) => {
      await getWorkstationFleets();
    });
  };

  const onUnlinkFleet = async (fleetId) => {
    const deleteWorkstationFleet = `
      mutation {
        deleteWorkstationFleet(workstation_id: "${workstationId}", fleet_id : "${fleetId}") 
        {
          id
          fleet_name
          client_id
          is_active
          created_at
          updated_at
        }
    }`;
    API.graphql({ query: deleteWorkstationFleet }).then(async (e) => {
      await getWorkstationFleets();
    });
  };

  useEffect(() => {
    fetchData();
    getWorkstationFleets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAlertMessage = () => {
    const getVariant = () => {
      if (hasAccessToEdit === null) return "secondary";
      if (hasAccessToEdit === true) return "success";
      return "danger";
    };
    return (
      <Alert key="alert-message" variant={getVariant()}>
        {hasAccessToEdit === null && <>Checking permissions...</>}
        {hasAccessToEdit !== null && (
          <>
            This user {hasAccessToEdit ? "have" : "don't have"} access to edit a
            workspace
          </>
        )}
      </Alert>
    );
  };

  const getSavedAlert = () => {
    return (
      <Alert
        show={showSuccess}
        key="success-message"
        variant="warning"
        onClose={() => setShowSuccess(false)}
        dismissible
      >
        Workstation saved successfully !
      </Alert>
    );
  };

  return (
    <>
      <Row>
        <Col>{getAlertMessage()}</Col>
        <Col md="auto">
          <div
            style={{ display: "inline", float: "right", paddingRight: "15px" }}
          >
            <Button variant="warning" onClick={onRefreshClick}>
              Refresh
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5" className="col-12">
                <div Style="display:inline">Fleet Information:</div>
              </Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="workstationId">
                    Workstation Id
                  </InputGroup.Text>
                  <FormControl
                    readOnly
                    aria-describedby="workstationId"
                    value={workstationId}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="workstationName">
                    Workstation Name
                  </InputGroup.Text>
                  <FormControl
                    aria-describedby="workstationName"
                    value={workstationName}
                    required
                    onChange={handleWorkstationNameChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="ipAddress">IP Address</InputGroup.Text>
                  <FormControl
                    aria-describedby="ipAddress"
                    value={ipAddress}
                    required
                    pattern="^([0-9]{1,3}\.){3}[0-9]{1,3}$"
                    onChange={handleIPAddressChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="workstationIsActive">
                    Is Active
                  </InputGroup.Text>
                  <FormControl
                    readOnly
                    aria-describedby="workstationIsActive"
                    value={workstationIsActive}
                  />
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col md="4">{getSavedAlert()}</Col>
                  <Col md="6"></Col>
                  <Col md="2" style={{textAlign: 'right'}}>
                      <Button variant="primary" type="submit" disabled={!hasAccessToEdit}>
                        Save
                      </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      <br />
      <Row>
        <Col md="6">
          <Card>
            <Card.Header className="col-12" as="h5">
              <div>Linked Fleets:</div>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr key="header">
                    <th>Fleet Id</th>
                    <th>Fleet Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {worksationFleets.map((fleet) => {
                    return (
                      <tr key={"fleet-" + fleet.id}>
                        <td>{fleet.id}</td>
                        <td>{fleet.fleet_name}</td>
                        <td Style="max-width:50px; text-align:center">
                          <Button
                            variant="danger"
                            disabled={!hasAccessToEdit}
                            onClick={() => onUnlinkFleet(fleet.id)}
                          >
                            Unlink
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <Card.Header className="col-12" as="h5">
              Link another fleet:
            </Card.Header>
            <Card.Body>
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="vin">Fleet :</InputGroup.Text>
                  <Form.Select
                    aria-label=""
                    onChange={handleSelectedFleetChange}
                  >
                    <option defaultValue={true}>[Select a Fleet]</option>
                    {allAvailableFleets.map((f) => (
                      <option value={f.id} key={f.id}>
                        {f.fleet_name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form>
            </Card.Body>
            <Card.Footer>
              <div Style="display:inline; float:right; padding-right:15px">
                <Button variant="info" onClick={() => onLinkFleet()} disabled={!hasAccessToEdit}>
                  Link Fleet
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const EditWorkstation = () => (
  <Container>
    <h1>Edit Workstation</h1>
    <EditWorkstationInner />
  </Container>
);

export default EditWorkstation;
