const VehicleStatusEnum = [
    {
        value: 0,
        enum: 'AVAILABLE',
        displayName: 'Available',
        description: 'The vehicle status is Available'
    },
    { 
        value: 1, 
        enum: 'DELOYED', 
        displayName: 'Deployed',
        description: 'The vehicle status is Deployed'
    },
    { 
        value: 2,
        enum: 'NOT_AVAILABLE',
        displayName: 'Not Available',
        description: 'The vehicle status is Not Available'
    }
]

const VehicleTeleopStatusEnum = [
    {
        value: 0,
        enum: 'DISCONNECTED',
        displayName: 'Disconnected',
        description: 'The vehicle teleop is disconnected from workstation'
    },
    { 
        value: 1, 
        enum: 'CONNECTING', 
        displayName: 'Connecting',
        description: 'The vehicle teleop is Connecting to workstation'
    },
    { 
        value: 2,
        enum: 'CONNECTED',
        displayName: 'Connected',
        description: 'The vehicle teleop is connected to workstation'
    },
    { 
        value: 3,
        enum: 'DISCONNECTING',
        displayName: 'Disconnecting',
        description: 'The vehicle teleop is disconnecting from workstation'
    }
]

const AVModeEnum = [
    {
        value: 0,
        enum: 'ARB_MODE_MANUAL',
        displayName: 'Manual',
        description: 'The vehicle is under manual control'
    },
    { 
        value: 1, 
        enum: 'ARB_MODE_CHECK_TELEOP', 
        displayName: 'Check TELEOP',
        description: 'The vehicle is checking conditions for TELEOP control'
    },
    { 
        value: 2,
        enum: 'ARB_MODE_CHECK_AUTO',
        displayName: 'Check Autonomous',
        description: 'The vehicle is checking conditions for Autonomous Control'
    },
    { 
        value: 3,
        enum: 'ARB_MODE_TELEOP',
        displayName: 'TELEOP',
        description: 'The Vehicle is under TELEOP Control'
    },
    { 
        value: 4,
        enum: 'ARB_MODE_AUTO',
        displayName: 'Autonomous',
        description: 'The Vehicle is under Autonomous Control'
    },
    { 
        value: 5,
        enum: 'ARB_MODE_EX_MSM',
        displayName: 'Execute Fault Response',
        description: 'The Vehicle is executing it’s fault response procedure'
    },
    { 
        value: 6,
        enum: 'ARB_MODE_MRC',
        displayName: 'Minimum Risk Condition',
        description: 'The Vehicle has executed it’s fault response and is sitting in the minimum risk condition'
    }
]

const WorkstationStatusEnum = [
    {
        value: 0,
        enum: 'OFFLINE',
        displayName: 'Offline'
    },
    { 
        value: 1, 
        enum: 'AVAILABLE', 
        displayName: 'Available'
    },
    { 
        value: 2,
        enum: 'NOT_AVAILABLE',
        displayName: 'Not Available'
    },
    { 
        value: 3,
        enum: 'CONNECTED',
        displayName: 'Connected to Vehicle'
    },
    { 
        value: 4,
        enum: 'DISABLED',
        displayName: 'Disabled'
    },
    { 
        value: 5,
        enum: 'DISCONNECTED',
        displayName: 'Disconnected'
    },
    { 
        value: 10,
        enum: 'ENDED',
        displayName: 'Ended'
    },
    { 
        value: 11,
        enum: 'STARTED',
        displayName: 'Started'
    },
    { 
        value: 20,
        enum: 'BUSY',
        displayName: 'Busy'
    }
]

const WorkstationActionEnum = [
    {
        value: 0,
        enum: 'UNKNOWN',
        displayName: 'Unknown'
    },
    { 
        value: 1, 
        enum: 'LINK_FLEET', 
        displayName: 'Fleet Linked'
    },
    { 
        value: 2,
        enum: 'UNLINK_FLEET',
        displayName: 'Fleet Unlinked'
    },
    { 
        value: 3,
        enum: 'LINK_VEHICLE',
        displayName: 'Vehicle Linked'
    },
    { 
        value: 4,
        enum: 'UNLINK_VEHICLE',
        displayName: 'Vehicle Unlinked'
    }
]

export {
    AVModeEnum,
    VehicleStatusEnum,
    VehicleTeleopStatusEnum,
    WorkstationStatusEnum,
    WorkstationActionEnum
}