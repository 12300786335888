import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";

import { payload } from "./payload1";
import { sampleData } from "./sampleData";
import SendMqttMessage from "./awsHelper";
import { formatDateTime } from "../../lib/Extensions";
import { API, graphqlOperation } from "aws-amplify";
import { getClientProfile, getDecisionEngine } from '../../utils/helper'
import axios from "@aws-amplify/storage/node_modules/axios";

const { v4: uuidv4 } = require("uuid");

function useStateCallback(initialState) {


  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [state, setStateCallback];
}

const TestingInner = ({ children }) => {

  const [clientId, setClientId] = useState('');
  const [clients, setClients] = useState([]);
  const [zephrSessionAccess, setZephrSessionAccess]= useState('')
  useEffect(() => {
    
    fetchClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const fetchClientData = async () => {
    const clientQuery = `
    {
      clients {
        id
        client_name
      }
    }`;
    let decisionENgineAccess = ''
    
    let client = clientId
    if(!client) {
      await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
        if (mainResponse.outputValue === '0'){
          document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access testing area.</div>`
          client = "-1"
        } else if (mainResponse.outputValue === '1') {
          const clients = await API.graphql(graphqlOperation(clientQuery));
          setClients(clients.data.clients)
          document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access testing area for any client.</div>`
        } else if (mainResponse.outputValue === '2') {
          await getClientProfile().then(async res => {
            client = res.client
          })
        } else {
          document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
          client = "-1"
        }
      });
  
      if (client === "-1"){
        return
      }
  
    }
      await getDecisionEngine('hub---access-testing-area').then(async res => {
         setZephrSessionAccess(res.outputValue)
         decisionENgineAccess = res.outputValue
         if (res.outputValue === '0') {
          document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access testing area.</div>`
        }
        else if( res.outputValue === '1') {
          document.getElementById('api-result').innerHTML = `<div class="yellow">User has privilege to access testing area for ${client} client.</div>`
        }
      });

    if(decisionENgineAccess !== '0') {
      fetchInitials(client);
      fetchData();
    } else {
      fetchMockData()
    }
  }

  //#region Variables

  const [sampleCounter, setSampleCounter] = useState(0);

  const [iotHost, setIOTHost] = useState({});
  const [topic, setTopic] = useState('');
  const [endPoint, setEndpoint] = useState({});

  const [groupName, setGroupName] = useStateCallback('');
  const [sessionId, setSessionId] = useStateCallback('');
  const [vehicleId, setVehicleId] = useStateCallback('');

  const [payloadData, setPayloadData] = useState({});
  const [outputMessage, setOutputMessage] = useState('');
  const [incLatt, setIncLatt] = useState('0.001');
  const [incLgt, setIncLgt] = useState('0.000');
  const [submitFreq, setSubmitFreq] = useState('1');

  const [withBattery, setWithBattery] = useStateCallback(true);
  const [withVelocity, setWithVelocity] = useStateCallback(true);
  const [withAVMode, setWithAVMode] = useStateCallback(true);
  const [withStatus, setWithStatus] = useStateCallback(true);
  const [withTeleopStatus, setWithTeleopStatus] = useStateCallback(true);
  const [withLocation, setWithLocation] = useStateCallback(true);
  const [withErrors, setWithErrors] = useStateCallback(false);

  const [withWeather, setWithWeather] = useStateCallback(true);
  const [withRoadCondition, setWithRoadCondition] = useStateCallback(true);
  const [withIsDay, setWithIsDay] = useStateCallback(true);
  const [withVisibility, setWithVisibility] = useStateCallback(true);
  const [withTemp, setWithTemp] = useStateCallback(true);

  const handlePayloadChange = (e) => setPayloadData(e.target.value);

  const handleVehicleIdChange = (e) => { setVehicleId(e.target.value, (newValue) => { updateTopic(newValue, ''); fetchSessions(newValue) }); }
  const handleSessionIdChange = (e) => { setSessionId(e.target.value, updateTopic(vehicleId, e.target.value)); }
  const handleGroupNameChange = (e) => { setGroupName(e.target.value, updateTopic(vehicleId, sessionId)); }
  const handleTenanatIdChange = (e) => { setClientId(e.target.value, updateTopic(vehicleId, sessionId)); }

  const handleBatteryChange = (e) => setPayloadValue('XCU_HighVoltageSoc_Pc', e.target.value);
  const handleSpeedChange = (e) => setPayloadValue('XCU_Velocity_Ms', e.target.value);
  const handleLongitudeChange = (e) => setPayloadValue('GPSIMU_PosLongitude_D', e.target.value);
  const handleLatitudeChange = (e) => setPayloadValue('GPSIMU_PosLatitude_D', e.target.value);
  const handleAVModeChange = (e) => setPayloadValue('ARB_Vehicle_Mode_Zs', e.target.value);
  const handleStatusChange = (e) => setPayloadValue('ARB_Vehicle_Status_Zs', e.target.value);
  const handleTeleopStatusChange = (e) => setPayloadValue('ARB_TeleopConnStatus_Zs', e.target.value);
  const handleErrorChange = (e) => setPayloadValue('ARB_ErrorCode_Final_Z', (Math.pow(2, e.target.value)));

  //weather conditions
  const handleWeatherChange = (e) => setPayloadValue('HUB_WeatherCondition_S', e.target.value);
  const handleRoadConditionChange = (e) => setPayloadValue('HUB_RoadCondition_S', e.target.value);
  const handleIsDayChange = (e) => { setPayloadValue('HUB_IsDay_B', e.target.value === 'true' ? true :  e.target.value === 'false' ? false : null ); }
  const handleVisibilityChange = (e) => setPayloadValue('HUB_Visibility_S', e.target.value);
  const handleTempChange = (e) => setPayloadValue('HUB_Temperature_Z', e.target.value);

  const handleWithBatteryChange = (e) => { setWithBattery(e.target.checked, updatePayload()); }
  const handleWithVelocityChange = (e) => { setWithVelocity(e.target.checked, updatePayload()); }
  const handleWithAVModeChange = (e) => { setWithAVMode(e.target.checked, updatePayload()); }
  const handleWithStatusChange = (e) => { setWithStatus(e.target.checked, updatePayload()); }
  const handleWithTeleopStatusChange = (e) => { setWithTeleopStatus(e.target.checked, updatePayload()); }
  const handleWithLocationChange = (e) => { setWithLocation(e.target.checked, updatePayload()); }
  const handleWithErrorsChange = (e) => { setWithErrors(e.target.checked, updatePayload()); }

  const handleWithWeatherChange = (e) => { setWithWeather(e.target.checked, updatePayload()); }
  const handleWithRoadConditionChange = (e) => { setWithRoadCondition(e.target.checked, updatePayload()); }
  const handleWithIsDayChange = (e) => { setWithIsDay(e.target.checked, updatePayload()); }
  const handleWithVisibilityChange = (e) => { setWithVisibility(e.target.checked, updatePayload()); }
  const handleWithTempChange = (e) => { setWithTemp(e.target.checked, updatePayload()); }

  const handleSybmitFreqChange = (e) => { setSubmitFreq(e.target.value); }
  const handleIncLattChange = (e) => { setIncLatt(e.target.value); }
  const handleIncLgtChange = (e) => { setIncLgt(e.target.value); }
  


  //#endregion

  //#region Setup

  const [availableVehicles, setAvailableVehicles] = useState([]);
  const [availableSessions, setAvailableSessions] = useState([]);

 

  const vehicleJourneysQuery = `
  {
    sessions (vehicleId: "VEHICLEIDVALUE") {
      id
      vehicle_id
      run_config_id
      start
      end
      errors_count
    }
  }`;

  async function fetchInitials(clientId) {
    setAvailableVehicles([]);
    setAvailableSessions([]);
    const vehiclesQuery = `
    {
        vehiclesWithSessions(clientId: "${clientId}") {
          hubVehicleConfigurationData
          data {
            vehicle_id
            vin
            registration_number
            fleet_id
            session_id
            run_config_id
            start
            end
          }
        }
    }`;
    const axiosOptions = {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`,
      },
      withCredentials: (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") ? false : true
    }

    const apiData = await axios.post(`${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`, { query: vehiclesQuery, variables: {} }, axiosOptions);
    const availableVehiclesData = apiData.data.data.vehiclesWithSessions.data;

    setAvailableVehicles(availableVehiclesData);
    setAvailableSessions([]);
  }

  async function fetchSessions(vehicleId) {
    setSessionId('');
    setAvailableSessions([]);

    const apiData = await API.graphql(graphqlOperation(vehicleJourneysQuery.replace('VEHICLEIDVALUE', vehicleId)));
    const sessionsData = apiData.data.sessions;
    console.log(sessionsData);

    setAvailableSessions(sessionsData);
  }

  //#endregion

  //#region Functions

  const onRefreshClick = () => {

    setSampleCounter(0);
    updateSampleData(0)

    
    if (zephrSessionAccess !== '0') {
      updateTopic(vehicleId, sessionId);
      fetchInitials(clientId);
      fetchData();
    } else {
      fetchMockData()
    }


    //console.log(process.env);
  };

  function incrementSampleCounter() {
    var newcounter = sampleCounter + 1;
    if (newcounter === 44) newcounter = 0;
    setSampleCounter(newcounter);
    updateSampleData(newcounter)
  }
  function updateSampleData(counter) {
    const currentData = sampleData[counter];
    setPayloadValue('XCU_HighVoltageSoc_Pc', currentData.BatteryLevel);
    setPayloadValue('XCU_Velocity_Ms', currentData.Velocity);
    setPayloadValue('GPSIMU_PosLongitude_D', currentData.Longitude);
    setPayloadValue('GPSIMU_PosLatitude_D', currentData.Latitude);
    setPayloadValue('Timestamp', new Date().toISOString());
  }

  function setPayloadValue(field, value) {
    if (field === 'ARB_Vehicle_Mode_Zs')
      value = Number(value);
    if (field === 'ARB_Vehicle_Status_Zs')
      value = Number(value);
    if (value === null) value = '';
    payload[field] = value;
    updatePayload();
  }

  function updatePayload() {
    try {
      let tempPayload = JSON.parse(JSON.stringify(payload)); // clone original
      if (!withBattery) delete tempPayload.XCU_HighVoltageSoc_Pc;
      if (!withVelocity) delete tempPayload.XCU_Velocity_Ms;
      if (!withAVMode) delete tempPayload.ARB_Vehicle_Mode_Zs;
      if (!withErrors) delete tempPayload.ARB_ErrorCode_Final_Z;

      if (!withWeather) delete tempPayload.HUB_WeatherCondition_S;
      if (!withRoadCondition) delete tempPayload.HUB_RoadCondition_S ;
      if (!withVisibility) delete tempPayload.HUB_Visibility_S;
      if (!withTemp) delete tempPayload.HUB_Temperature_Z;
      if (!withIsDay) delete tempPayload.HUB_IsDay_B;

      if (!withLocation) { delete tempPayload.GPSIMU_PosLongitude_D; delete tempPayload.GPSIMU_PosLatitude_D; }
      setPayloadData(JSON.stringify(tempPayload, null, "\t"));
    } catch (error) {
      console.log(error);
    }
  }

  function updateTopic(v, s) {
    if (v === '[Select a Vehicle]') v = '';
    if (s === '[Select a Session]') s = '';
    setTopic(`dt/${clientId}/${v}/${s}/telematics-${process.env.REACT_APP_SERVERLESS_STAGE}/${groupName}`);
  }

  function fetchData() {

    setGroupName('test-group');
    setVehicleId('');
    setSessionId('');

    setIOTHost(`${process.env.REACT_APP_IOT_HOST}`);
    setEndpoint(`${process.env.REACT_APP_APPSYNC_ENDPOINT}`);

    payload.Timestamp = new Date().toISOString();
    payload.PayloadId = uuidv4();
    updatePayload();
  }

  function fetchMockData() {
    const wallValue = 'Restricted'
    setGroupName(wallValue);
    setVehicleId('');
    setSessionId('');

    setIOTHost(wallValue);
    setEndpoint(wallValue);
    setTopic(wallValue);
    setPayloadData({});
    payload.PayloadId = wallValue
    payload.Timestamp = new Date().toISOString();
  }

  const onValidate = () => {
    updateTopic(vehicleId, sessionId);
    updatePayload();
  }

  let latt = 51
  const onSubmit = () => {
    // console.log('Sending Mqtt Message:\n', payloadData);
    const payload2 = JSON.parse(payloadData);
    payload2.Timestamp = new Date().toISOString();
    const output = SendMqttMessage(iotHost, topic, JSON.stringify(payload2));
    setOutputMessage(output); 
   

  }

  const onRecurrentSubmit = () => {
    let i = 675
    const payload2 = JSON.parse(payloadData);
    setInterval(function () {
      i++;
      latt = '51.74' + ('00' + i).slice(-3)
      payload2.Timestamp = new Date().toISOString();
      payload2.GPSIMU_PosLatitude_D = latt
       SendMqttMessage(iotHost, topic, JSON.stringify(payload2));
    }, 1000)
  }

  //#endregion

  //#region HTML

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col md="auto"></Col>
        <Col xs lg="4">
          <Button variant="primary" onClick={onSubmit}>Submit</Button><span> </span>
          
          <Button variant="secondary" onClick={onValidate}>Validate</Button><span> </span>
          <Button variant="warning" onClick={onRefreshClick}>Reset</Button>
          <br></br>
          
        </Col>
      </Row>
      <br />
      <Form>
        <Row>
          <Col>
         
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">AWS IoT Settings</Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="txtIOTHost">IoT Host</InputGroup.Text>
                  <FormControl
                    aria-describedby="txtIOTHost"
                    value={iotHost}
                    readOnly
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="txtAppSync">AppSync</InputGroup.Text>
                  <FormControl
                    aria-describedby="txtAppSync"
                    value={endPoint}
                    readOnly
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="txtTopic">Topic</InputGroup.Text>
                  <FormControl
                    aria-describedby="txtTopic"
                    value={topic}
                    readOnly
                  />
                </InputGroup>
              </Card.Body>
             
            </Card>
          </Col>
          <Col>
          <Card>
              <Card.Header as="h5">Recurrent Submit</Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                <InputGroup.Text>Latt Increment</InputGroup.Text>
                  <FormControl
                    id="lattInc"
                    placeholder="{Increment Lattitue By}"
                    aria-label="Increment Lattitue"
                    aria-describedby="lattInc"
                    disabled={true}
                    value={incLatt}
                    style={{'width': '50px'}}
                    onChange={handleIncLattChange}
                      />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Long Increment</InputGroup.Text>
                  <FormControl
                    id="lattInc"
                    placeholder="{Increment Longitude By}"
                    aria-label="Increment Longitude"
                    aria-describedby="lgtInc"
                    disabled={true}
                    value={incLgt}
                    onChange={handleIncLgtChange}
                    style={{'width': '50px'}}
                      />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Frequency</InputGroup.Text>
                  <FormControl
                    id="lattInc"
                    placeholder="{Submit Frequency}"
                    aria-label="Submit Frequency"
                    aria-describedby="lgtInc"
                    disabled={true}
                    value={submitFreq}
                    style={{'width': '50px'}}
                    onChange={handleSybmitFreqChange}
                      />
                </InputGroup>
              </Card.Body>
              <Card.Footer style={{ textAlign: 'right' }}>
                <Button variant="primary" onClick={onRecurrentSubmit}>Recurrent Submit</Button><span> </span>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Main</Card.Header>
              <Card.Body>
              { clients.length > 0 && <InputGroup className="mb-3">
                  <InputGroup.Text id="HardwareCollectionId">Client</InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleTenanatIdChange}>
                    <option defaultValue={true}>[Select a Client]</option>
                    {
                      clients.map(s =>
                        <option value={s.id} key={s.id}>
                          {s.client_name}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup> }
                <InputGroup className="mb-3">
                  <InputGroup.Text id="vehicleId"><a href={`/vehicleDetails?vehicleId=${vehicleId}`} target='_blank' rel="noreferrer" >Vehicle Id</a></InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleVehicleIdChange}>
                    <option defaultValue={vehicleId}>[Select a Vehicle]</option>
                    {
                      availableVehicles.map(v =>
                        <option value={v.vehicle_id} key={v.vehicle_id}>
                          {v.registration_number}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="sessionId"><a href={`/journeyDetails?journeyId=${sessionId}&vehicleId=${vehicleId}`} target='_blank' rel="noreferrer" >Journey Id</a></InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleSessionIdChange}>
                    <option defaultValue={sessionId}>[Select a Session]</option>
                    {
                      availableSessions.map(s =>
                        <option value={s.id} key={s.id}>
                          {`${formatDateTime(s.start)} - ${formatDateTime(s.end)} `}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="groupName">Group Name</InputGroup.Text>
                  <FormControl
                    aria-describedby="groupName"
                    value={groupName}
                    onChange={handleGroupNameChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="payloadId">PayloadId</InputGroup.Text>
                  <FormControl
                    placeholder="{GUID}"
                    aria-label="GUID"
                    aria-describedby="payloadId"
                    value={`${payload.PayloadId}`}
                    readOnly
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="timeStamp">Timestamp</InputGroup.Text>
                  <FormControl
                    placeholder=""
                    aria-describedby="timeStamp"
                    value={`${payload.Timestamp}`}
                    readOnly
                  />
                </InputGroup>
              </Card.Body>
            </Card>

          </Col>
          <Col>
            <Card>
              <Card.Header as="h5">
                Settings
              </Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox id="chkWithBattery" aria-label="Checkbox for following text input" checked={withBattery} onChange={handleWithBatteryChange} />
                  <InputGroup.Text>With Battery Level</InputGroup.Text>
                  <FormControl
                    id="BatteryLevel"
                    placeholder="{Battery Level, %}"
                    aria-label="Battery Level"
                    aria-describedby="BatteryLevel"
                    value={payload.XCU_HighVoltageSoc_Pc}
                    onChange={handleBatteryChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withVelocity} onChange={handleWithVelocityChange} />
                  <InputGroup.Text>With Speed (m/s)</InputGroup.Text>
                  <FormControl
                    placeholder="{Speed, m/s}"
                    aria-label="Speed"
                    aria-describedby="basic-addon1"
                    value={payload.XCU_Velocity_Ms}
                    onChange={handleSpeedChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withLocation} onChange={handleWithLocationChange} />
                  <InputGroup.Text>With Location</InputGroup.Text>
                  <FormControl
                    placeholder="{Longitude}"
                    value={payload.GPSIMU_PosLongitude_D}
                    onChange={handleLongitudeChange}
                  />
                  <FormControl
                    placeholder="{Latitude}"
                    value={payload.GPSIMU_PosLatitude_D}
                    onChange={handleLatitudeChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withAVMode} onChange={handleWithAVModeChange} />
                  <InputGroup.Text>With AV Mode</InputGroup.Text>
                  <Form.Select aria-label="Default select example" onChange={handleAVModeChange}>
                    <option>Select an AV Mode</option>
                    <option value="0">ARB_MODE_MANUAL</option>
                    <option value="1">ARB_MODE_CHECK_TELEOP</option>
                    <option value="2">ARB_MODE_CHECK_AUTO</option>
                    <option value="3">ARB_MODE_TELEOP</option>
                    <option value="4">ARB_MODE_AUTO</option>
                    <option value="5">ARB_MODE_EX_MSM</option>
                    <option value="6">ARB_MODE_MRC</option>
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withStatus} onChange={handleWithStatusChange} />
                  <InputGroup.Text>With Status</InputGroup.Text>
                  <Form.Select aria-label="Default select example" onChange={handleStatusChange}>
                    <option>Select a Vehicle Status</option>
                    <option value="0">Available</option>
                    <option value="1">Deployed</option>
                    <option value="2">Not Available</option>
                  </Form.Select>
                </InputGroup> 
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withTeleopStatus} onChange={handleWithTeleopStatusChange} />
                  <InputGroup.Text>Teleop Status</InputGroup.Text>
                  <Form.Select aria-label="Default select example" onChange={handleTeleopStatusChange}>
                    <option>Select a Vehicle Teleop Status</option>
                    <option value="0">Disconnected</option>
                    <option value="1">Connecting to WorkStation</option>
                    <option value="2">Connected</option>
                    <option value="3">Disconnected</option>
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withErrors} onChange={handleWithErrorsChange} />
                  <InputGroup.Text>With Errors</InputGroup.Text>
                  <Form.Select aria-label="Default select example" onChange={handleErrorChange}>
                    <option>Select an error code</option>
                    <option value="16">RECEIVER_FAULT_ARB_LOST</option>
                    <option value="17">RECEIVER_FAULT_CAMERA_LOST</option>
                    <option value="18">RECEIVER_FAULT_HIGH_LATENCY</option>
                    <option value="19">RECEIVER_FAULT_NO_CONTROL_MESSAGE</option>
                    <option value="20">RECEIVER_FAULT_STREAMER_SLOW</option>
                    <option value="21">SENDER_FAULT_HARDWARE_ERROR</option>
                    <option value="22">SENDER_FAULT_NO_VIDEO</option>
                    <option value="23">SENDER_FAULT_NO_GUI_MESSAGE</option>
                    <option value="24">SENDER_FAULT_SOFT_EMERGENCY_STOP</option>
                    <option value="32">ARB_HIGH_LATENCY</option>
                    <option value="33">ARB_NON_INITIALISED_TELEOP_CONNECTION</option>
                    <option value="34">ARB_NON_INITIALISED_ROS_CONNECTION</option>
                    <option value="35">ARB_STALE_ROS_COMMS</option>
                    <option value="36">ARB_STALE_TELEOP_COMMS</option>
                    <option value="37">ARB_SOFTWARE_ERROR</option>
                    <option value="38">ARB_NON_INITIALISED_CAN_CONNECTION</option>
                    <option value="39">ARB_STALE_CAN_COMMS</option>
                  </Form.Select>
                </InputGroup>
                </Card.Body>

              <Card.Footer style={{ textAlign: 'right' }}>
                <Button variant="primary" onClick={incrementSampleCounter}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                  </svg>
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header as="h5">
                Weather Settings
              </Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withWeather} onChange={handleWithWeatherChange} />
                  <InputGroup.Text>With Weather Conditions</InputGroup.Text>
                  <FormControl
                    placeholder="{i.e Sunny}"
                    value={payload.HUB_WeatherCondition_S}
                    onChange={handleWeatherChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withRoadCondition} onChange={handleWithRoadConditionChange} />
                  <InputGroup.Text>With Road Conditions</InputGroup.Text>
                  <FormControl
                    placeholder="{i.e wet, dry...}"
                    value={payload.HUB_RoadCondition_S}
                    onChange={handleRoadConditionChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withIsDay} onChange={handleWithIsDayChange} />
                  <InputGroup.Text>With Day Status</InputGroup.Text>
                  <Form.Select aria-label="Default select example" onChange={handleIsDayChange}>
                    <option>Select a Day Status</option>
                    <option value="true">Day</option>
                    <option value="false">Night</option>
                  </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withVisibility} onChange={handleWithVisibilityChange} />
                  <InputGroup.Text>With Visibility</InputGroup.Text>
                  <FormControl
                    placeholder="{i.e clear, fog...}"
                    value={payload.HUB_Visibility_S}
                    onChange={handleVisibilityChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={withTemp} onChange={handleWithTempChange} />
                  <InputGroup.Text>With Temperature</InputGroup.Text>
                  <FormControl
                    placeholder="{in Degree Celcius}"
                    value={payload.HUB_Temperature_Z}
                    onChange={handleTempChange}
                  /> <div> °C</div>
                </InputGroup>


              </Card.Body>

              {/* <Card.Footer style={{ textAlign: 'right' }}>
                <Button variant="primary" onClick={incrementSampleCounter}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                  </svg>
                </Button>
              </Card.Footer> */}
            </Card>

          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Input</Card.Header>
              <Card.Body>
                <InputGroup>
                  <InputGroup.Text></InputGroup.Text>
                  <FormControl
                    as="textarea"
                    aria-label="{JSON}"
                    value={payloadData}
                    onChange={handlePayloadChange}
                    rows={20}
                  />
                </InputGroup>
              </Card.Body>
            </Card>

          </Col>
          <Col>
            <Card>
              <Card.Header as="h5">Output</Card.Header>
              <Card.Body>
                <InputGroup>
                  <InputGroup.Text></InputGroup.Text>
                  <FormControl
                    as="textarea"
                    aria-label="{JSON}"
                    value={outputMessage}
                    readOnly
                    rows={20}
                  />
                </InputGroup>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Form>

    </>
  );

  //#endregion

};

const Testing = () => (
  <Container>
    <h1>Testing Tools</h1>
    <TestingInner />
  </Container>
);

export default Testing;
