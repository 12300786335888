import React from 'react';

import {
    Container,
    Navbar,
    Nav,
    NavDropdown
} from 'react-bootstrap';

const Navigation = () => (
    <Navbar bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="/">
                <img
                    alt=""
                    src="/streetdrone-logo.png"
                    width="250"

                    className="d-inline-block align-top"
                />{' '}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/vehicles">Vehicles</Nav.Link>
                    <Nav.Link href="/journeys">Journeys</Nav.Link>
                    <Nav.Link href="/fleets">Fleets</Nav.Link>
                    <Nav.Link href="/workstations">Workstations</Nav.Link>
                    <NavDropdown title="Configs" id="basic-nav-dropdown">
                        <NavDropdown.Item href="software">Software Collections</NavDropdown.Item>
                        <NavDropdown.Item href="hardware">Hardware Collections</NavDropdown.Item>
                        <NavDropdown.Item href="runconfigs">Run Configs</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="startSession">Sessions Management</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="addvehicle">Add Vehicle</NavDropdown.Item>
                        <NavDropdown.Item href="addFleet">Add Fleet</NavDropdown.Item>
                        <NavDropdown.Item href="addWorkstation">Add Workstation</NavDropdown.Item>
                        {/* <NavDropdown.Divider />
                        <NavDropdown.Item href="users">Users</NavDropdown.Item> */}
                    </NavDropdown>
                    <Nav.Link href="/testing">Testing</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                    env: <b>{process.env.REACT_APP_ENVNAME}</b>
                </Navbar.Text>
            </Navbar.Collapse>
        </Container>
    </Navbar>
);

export default Navigation;
