import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl
} from "react-bootstrap";
import { getDecisionEngine, getClientProfile } from '../../utils/helper'
import { formatDate } from "../../lib/Extensions";

const EditFleetInner = () => {

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let fleetId = params.get('fleetId');

  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [fleetName, setFleetName] = useState('');
  const [clientIdForShare, setClientIdForShare] = useState('');
  const [clientsForShare, setClientsForShare] = useState([]);
  const [fleetVehicles, setFleetVehicles] = useState([]);
  const [sharedClients, setSharedClients] = useState([]);
  const [fleetIsActive, setFleetIsActive] = useState(true);
  const [selectedVehicleId, setSelectedVehicleId] = useState('');
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);

  const handleClientIdChangeForShare = (e) => { setClientIdForShare(e.target.value) }
  const handleFleetNameChange = (e) => { setFleetName(e.target.value) }
  const onSelectedVehicleChange = (event) => { setSelectedVehicleId(event.target.value) }
  const onEditClick = (e) => { saveFleet() }

  const onRefreshClick = () => { fetchData(); };

  const getFleetData = async () => {

    const access = await getDecisionEngine('hub---create-a-fleet').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to edit fleets.</div>`
        return false;
      }
      return true
    });
    setHasAccessToEdit(access)
    if (!access) return
    const clientQuery = `
        {
          clients {
            id
            client_name
          }
        }`;
    let tempClientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to edit/view details fleets.</div>`
        tempClientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access fleets for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          tempClientId = res.client
          document.getElementById('api-result').innerHTML = `<div class='yellow'>User has privilege to access fleets for ${tempClientId} client.</div>`
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        tempClientId = "-1"
      }
    });

    if (tempClientId === "-1") {
      return
    }

    //tempClientId = 'nissan-001'
    //console.log(tempClientId);

    setClientId(tempClientId);
   
    const fleetQuery = `{
            fleet(id: "${fleetId}") {
                id
                fleet_name
                client_id
                is_active
                created_at
                updated_at
              }
          }`
    const fleet = await API.graphql(graphqlOperation(fleetQuery));
    setFleetName(fleet.data.fleet.fleet_name)
    setFleetIsActive(fleet.data.fleet.is_active)
    setClientId(fleet.data.fleet.client_id)
    return fleet.data.fleet
  }

  async function saveFleet() {
    const addFleet = `
            mutation {
                editFleet(fleet_id: "${fleetId}", fleet_name : "${fleetName}", client_id : "${clientId}", is_active : ${fleetIsActive}) 
                {
                    id
                    fleet_name
                    client_id
                    is_active
                }
            }`;
    API.graphql({ query: addFleet })
      .then(async (e) => {
        document.getElementById('api-result').innerHTML = `<div class='green'>Fleet successfully changed.</div>`
        await getFleetData();
      });
  }

  async function fetchData() {
    await getFleetData();
  }

  async function fetchClientData() {
    if(!hasAccessToEdit) return
    const clientQuery = `
        {
            clients {
                id
                client_name
            }
        }`;
    let clientId = ''
    await getClientProfile().then(async res => {
      clientId = res.client
      setClientId(clientId)
      if (!clientId) {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
      }
    });
  }

  async function fetchSharedClients() {
    if(!hasAccessToEdit) return
    const clientQuery = `
      {
        clients {
          id
          client_name
        }
      }`;
    const clientsResponse = await API.graphql(graphqlOperation(clientQuery));
    const clientsToUseForSharing = clientsResponse.data.clients;
      
    const sharedFleetsQuery = `
      {
        sharedFleetsByFleet(FleetId : "${fleetId}", OwnedClientId : "${clientId}") {
          id
          fleet_id
          owner_client_id
          shared_client_id
          share_date
          client_name
        }
      }`;
    const response = await API.graphql(graphqlOperation(sharedFleetsQuery));
    const sharedClientsList = response.data.sharedFleetsByFleet;
    setSharedClients(sharedClientsList);

    setClientsForShare(clientsToUseForSharing.filter(c => !sharedClientsList.find(cc => cc.shared_client_id === c.id)).filter(c => c.id !== clientId));
  }

  const getFleetVehicles = async () => {
    if (!fleetId || !hasAccessToEdit) return;
    const fleetVehicles = `
    {
      fleetVehicles(fleetId: "${fleetId}") {
        id
        vin
        registration_number
        fleet_id
        created_at
        updated_at
      }
    }`;
    const apiData = await API.graphql(graphqlOperation(fleetVehicles))
    const fleetVehiclesData = apiData.data.fleetVehicles;
    setFleetVehicles(fleetVehiclesData)

    const sessionAndVehiclesQuery = `
    {
        vehicles(clientId: "${clientId}") {
          id
          registration_number
          vin
          fleet_id
        }
    }`;
    const apiData2 = await API.graphql(graphqlOperation(sessionAndVehiclesQuery))
    const vehicleData = apiData2.data.vehicles;
    const filteredVehicles = vehicleData.filter(v => v.fleet_id === '00000000-0000-0000-0000-000000000000');

    setFleetVehicles(fleetVehiclesData)
    setVehicles(filteredVehicles)
  }

  const onLinkVehicle = async () => {

    const addFleetVehicle = `
      mutation {
        addFleetVehicle(fleet_id: "${fleetId}", vehicle_id : "${selectedVehicleId}") 
        {
          id
          vin
          registration_number
          fleet_id
          created_at
          updated_at
        }
    }`;
    API.graphql({ query: addFleetVehicle })
      .then(async (e) => {
        await getFleetVehicles();
      });
  }
  const onUnlinkVehicle = async (vehicleId) => {

    const deleteFleetVehicle = `
      mutation {
        deleteFleetVehicle(fleet_id: "${fleetId}", vehicle_id : "${vehicleId}") 
        {
          id
          vin
          registration_number
          fleet_id
          created_at
          updated_at
        }
    }`;
    API.graphql({ query: deleteFleetVehicle })
      .then(async (e) => {
        await getFleetVehicles();
      });
  }

  const onShareFleet = async () => {

    const shareFleet = `
      mutation {
        shareFleetWithClient(FleetId: "${fleetId}", OwnerClientId: "${clientId}", SharedClientId : "${clientIdForShare}")
        {
          id
          fleet_id
          owner_client_id
          shared_client_id
          share_date
        }
      }`;
    API.graphql({ query: shareFleet })
      .then(async (e) => {
        await fetchSharedClients();
      });
    console.log('Share with client: ', clientIdForShare);
  }

  const onUnShareFleet = async (clientIdtoUnshare) => {

    const shareFleet = `
      mutation {
        unShareFleetWithClient(FleetId: "${fleetId}", OwnerClientId: "${clientId}", SharedClientId : "${clientIdtoUnshare}")
        {
          id
          fleet_id
          owner_client_id
          shared_client_id
          share_date
        }
      }`;
    API.graphql({ query: shareFleet })
      .then(async (e) => {
        await fetchSharedClients();
      });
    console.log('Unshare with client: ', clientId);
  }

  useEffect(() => {

    fetchClientData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSharedClients();
    getFleetVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto">
          <div Style="display:inline; float:right; padding-right:15px">
            <Button variant="warning" onClick={onRefreshClick}>
              Refresh
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <Form>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5" className="col-12">
                <div Style="display:inline">Fleet Information:</div>
              </Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="fleetId">Fleet Id</InputGroup.Text>
                  <FormControl
                    readOnly
                    aria-describedby="fleetId"
                    value={fleetId}
                  />
                </InputGroup>
                {clients.length > 0 && <InputGroup className="mb-3">
                  <InputGroup.Text >Client</InputGroup.Text>
                  <Form.Select aria-label="" id="client_id" value={clientId} key={clientId} disabled="disabled">
                    <option defaultValue={true}>[Select a Client]</option>
                    {
                      clients.map(s =>
                        <option value={s.id} key={s.id} >
                          {s.client_name}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>}
                <InputGroup className="mb-3">
                  <InputGroup.Text id="fleettext">Fleet Name</InputGroup.Text>
                  <FormControl
                    aria-describedby="fleetName"
                    value={fleetName}
                    onChange={handleFleetNameChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="fleetActivetext">Is Active</InputGroup.Text>
                  <FormControl
                    readOnly
                    aria-describedby="fleetIsActive"
                    value={fleetIsActive}
                  />
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <div Style="display:inline; float:right; padding-right:15px">
                  { hasAccessToEdit && <Button variant="primary" onClick={onEditClick}>
                    Save
                  </Button> }
                </div>
              </Card.Footer>
            </Card>

          </Col>
        </Row>
      </Form>
      <br />
      <Row>
        <Col md="6">
          <Card>
            <Card.Header className="col-12" as="h5">
              <div>Linked Vehicles:</div>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr key="header">
                    <th>VIN</th>
                    <th>Vehicle Reg. Num.</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {fleetVehicles.map((vehicle) => {
                    return (
                      <tr key={"vehicle-" + vehicle.id}>
                        <td>{vehicle.vin}</td>
                        <td>{vehicle.registration_number}</td>
                        <td Style="max-width:50px; text-align:center" >
                          <Button variant="danger" onClick={() => onUnlinkVehicle(vehicle.id)}>
                            Unlink
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">

          <Card>
            <Card.Header className="col-12" as="h5">
              Link another vehicle:
            </Card.Header>
            <Card.Body>
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="vin">Vehicle :</InputGroup.Text>
                  <Form.Select aria-label="" onChange={onSelectedVehicleChange}>
                    <option defaultValue={true}>[Select a Vehicle ID]</option>
                    {
                      vehicles.map(s =>
                        <option value={s.id} key={s.id}>
                          {s.registration_number}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>
              </Form>
            </Card.Body>
            <Card.Footer>
              <div Style="display:inline; float:right; padding-right:15px">
                <Button variant="info" onClick={() => onLinkVehicle()}>
                  Link Vehicle
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <br />

      <Row>
        <Col md="6">
          <Card>
            <Card.Header className="col-12" as="h5">
              <div>Shared With Clients:</div>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr key="header">
                    <th>Client Name</th>
                    <th>Sharing Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sharedClients.map((record) => {
                    return (
                      <tr key={"sharedClient-" + record.id}>
                        <td>{record.client_name}</td>
                        <td>{formatDate(record.share_date)}</td>
                        <td Style="max-width:50px; text-align:center" >
                          <Button variant="danger" onClick={() => onUnShareFleet(record.shared_client_id)}>
                            Unshare
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">

          <Card>
            <Card.Header className="col-12" as="h5">
              Share with another client:
            </Card.Header>
            <Card.Body>
              <Form>
                <InputGroup className="mb-3">
                  <InputGroup.Text >Client</InputGroup.Text>
                  <Form.Select aria-label="" id="client_id2" value={clientIdForShare} key={clientIdForShare} onChange={handleClientIdChangeForShare}>
                    <option defaultValue={true}>[Select a Client]</option>
                    {
                      clientsForShare.map(s =>
                        <option value={s.id} key={s.id} >
                          {s.client_name}
                        </option>
                      )
                    }
                  </Form.Select>
                </InputGroup>
              </Form>
            </Card.Body>
            <Card.Footer>
              <div Style="display:inline; float:right; padding-right:15px">
                <Button variant="info" onClick={() => onShareFleet()}>
                  Share
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const EditFleet = () => (
  <Container>
    <h1>Edit Fleet</h1>
    <EditFleetInner />
  </Container>
);

export default EditFleet;
